import { mobileDashboardConstants } from './constants';

const initialState = {
  summary: {
    list: []
  }
};

export default function mobileDashboard(state = initialState, action) {
  switch (action.type) {
    // --- Get Recommendations
    case mobileDashboardConstants.GET_QUERY_DATA_REQUEST:
      return {
        ...state,
        summary: { list: [], loading: true, error: false }
      };
    case mobileDashboardConstants.GET_QUERY_DATA_SUCCESS:
      return {
        ...state,
        summary: {
          list: [...action.payload],
          loading: false,
          error: false
        }
      };
    case mobileDashboardConstants.GET_QUERY_DATA_ERROR:
      return {
        ...state,
        summary: {
          list: [],
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
}
