import actions from './actions';

const initialState = {
  metrics: null,
  metrics_groups_mapping: null,
  loading: false,
  dimensions: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
}
