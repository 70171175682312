const actions = {
  AUTO_LOGIN: 'auth/AUTO_LOGIN',
  LOGIN: 'auth/LOGIN',
  LOGIN_SUCCESS: 'auth/LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT: 'auth/LOGOUT',
  SET_DATA: 'auth/SET_DATA',
  COLLECT_DATA: 'auth/COLLECT_DATA',
  REFRESH: 'auth/REFRESH',
  GET_ORGS: 'auth/GET_ORGS',
  SWITCH_ORG: 'auth/SWITCH_ORG',
  TOGGLE_LOADING: 'auth/TOGGLE_LOADING',
  FORGOT_PASSWORD: '/auth/FORGOT_PASSWORD',
  FORGOT_PASSWORD_REQUEST: '/auth/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: '/auth/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: '/auth/FORGOT_PASSWORD_FAILURE'
};

export default actions;
