import { recommendationsConstants } from './constants';

const initialState = {
  recommendation_list: {},
  selected_recommendation: {},
  selected_recommendation_comments: {},
  mapped_recommendation: {},
  mapped_insights: {},
  assigning_users: {},
  metric_list: {},
  chart_queries: {},
  applied_filters: {}
};

export default function recommendations(state = initialState, action) {
  switch (action.type) {
    // --- Get Recommendations
    case recommendationsConstants.GET_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        recommendation_list: { ...state.recommendation_list, recommendation_fetching_status: 'fetching' }
      };
    case recommendationsConstants.GET_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendation_list: {
          ...state.recommendation_list,
          recommendation_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        recommendation_list: {
          ...state.recommendation_list,
          recommendation_fetching_status: 'fail'
        }
      };

    // --- Get Recommendations by ID
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_REQUEST:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recommendation_by_id_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_SUCCESS:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recommendation_by_id_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_BY_ID_ERROR:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recommendation_by_id_fetching_status: 'fail'
        }
      };

    // Assigning users
    case recommendationsConstants.GET_ASSIGNING_USERS_REQUEST:
      return {
        ...state,
        assigning_users: {
          ...state.assigning_users,
          recommendation_assigning_user_req_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_ASSIGNING_USERS_SUCCESS:
      return {
        ...state,
        assigning_users: {
          ...state.assigning_users,
          recommendation_assigning_user_req_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_ASSIGNING_USERS_ERROR:
      return {
        ...state,
        assigning_users: {
          ...state.assigning_users,
          recommendation_assigning_user_req_status: 'fail'
        }
      };

    // ---Change Recommendation Status
    case recommendationsConstants.HANDLE_RECOMMENDATION_STATUS_REQUEST:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recom_status_update: 'fetching'
        }
      };
    case recommendationsConstants.HANDLE_RECOMMENDATION_STATUS_SUCCESS:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recom_status_update: 'success',
          ...action.payload.data
        }
      };
    case recommendationsConstants.HANDLE_RECOMMENDATION_STATUS_ERROR:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          recom_status_update: 'fail'
        }
      };

    // --- Update Assigning User
    case recommendationsConstants.UPDATE_ASSIGNING_USERS_REQUEST:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          assigning_user_status: 'fetching'
        }
      };
    case recommendationsConstants.UPDATE_ASSIGNING_USERS_SUCCESS:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          assigning_user_status: 'success',
          ...action.payload.data
        }
      };
    case recommendationsConstants.UPDATE_ASSIGNING_USERS_ERROR:
      return {
        ...state,
        selected_recommendation: {
          ...state.selected_recommendation,
          assigning_user_status: 'fail'
        }
      };

    // --- Get Recommendation comments
    case recommendationsConstants.GET_RECOMMENDATION_COMMENTS_REQUEST:
      return {
        ...state,
        selected_recommendation_comments: {
          ...state.selected_recommendation_comments,
          comment_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_COMMENTS_SUCCESS:
      return {
        ...state,
        selected_recommendation_comments: {
          ...state.selected_recommendation_comments,
          comment_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_RECOMMENDATION_COMMENTS_ERROR:
      return {
        ...state,
        selected_recommendation_comments: {
          ...state.selected_recommendation_comments,
          comment_fetching_status: 'fail'
        }
      };

    // --- Post Recommendation comment
    case recommendationsConstants.POST_RECOMMENDATION_COMMENTS_SUCCESS:
      return {
        ...state,
        selected_recommendation_comments: {
          ...state.selected_recommendation_comments,
          comment_posting_status: 'success',
          comments: [action.payload.data, ...state.selected_recommendation_comments.comments]
        }
      };

    // --- Get mapped  Insights
    case recommendationsConstants.GET_MAPPED_INSIGHTS_REQUEST:
      return {
        ...state,
        mapped_insights: {
          ...state.mapped_insights,
          mapped_insights_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_MAPPED_INSIGHTS_SUCCESS:
      return {
        ...state,
        mapped_insights: {
          ...state.mapped_insights,
          mapped_insights_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_MAPPED_INSIGHTS_ERROR:
      return {
        ...state,
        mapped_insights: {
          ...state.mapped_insights,
          mapped_insights_fetching_status: 'fail'
        }
      };

    // --- Get Metric List
    case recommendationsConstants.GET_METRIC_LIST_REQUEST:
      return {
        ...state,
        metric_list: { ...state.metric_list, data_fetching_status: 'fetching' }
      };
    case recommendationsConstants.GET_METRIC_LIST_SUCCESS:
      return {
        ...state,
        metric_list: {
          ...state.metric_list,
          data_fetching_status: 'success',
          list: [...action.payload]
        }
      };
    case recommendationsConstants.GET_METRIC_LIST_ERROR:
      return {
        ...state,
        metric_list: {
          ...state.metric_list,
          data_fetching_status: 'fail'
        }
      };

    // --- Get chart by recommendation ID
    case recommendationsConstants.GET_CHART_MAPPING_DATA_REQUEST:
      return {
        ...state,
        chart_queries: {
          ...state.chart_queries,
          data_fetching_status: 'fetching'
        }
      };
    case recommendationsConstants.GET_CHART_MAPPING_DATA_SUCCESS:
      return {
        ...state,
        chart_queries: {
          ...state.chart_queries,
          data_fetching_status: 'success',
          ...action.payload
        }
      };
    case recommendationsConstants.GET_CHART_MAPPING_DATA_ERROR:
      return {
        ...state,
        chart_queries: {
          ...state.chart_queries,
          data_fetching_status: 'fail'
        }
      };

    // --- Applied Filters
    case recommendationsConstants.SET_APPLIED_FILTERS:
      return {
        ...state,
        applied_filters: action.payload
      };

    // --- Clear Recommendation details on unload component
    case recommendationsConstants.CLEAR_SELECTED_RECOMMENDATION:
      return {
        ...state,
        recommendation_list: {},
        selected_recommendation: {},
        selected_recommendation_comments: {},
        mapped_recommendation: {},
        assigning_users: {},
        mapped_insights: {}
      };
    default:
      return state;
  }
}
