import React from 'react';
import { notification, Button } from 'antd';

export const actions = {
  POPUP_CLOSED: 'integration/POPUP_CLOSED',
  ERROR: 'integration/ERROR',
  HANDLE_GENERIC_ERROR: 'integration/HANDLE_GENERIC_ERROR',

  ValidationError: 'integration/ValidationError'
};

export class ValidationError extends Error {
  constructor(message = 'Something went wrong', description = 'Our team is looking into it') {
    super(message);
    this.name = actions.ValidationError;
    this.message = message;
    this.description = description;
  }
}

const openNotificationWithIcon = ({ type = 'error', message, description }) => {
  notification[type]({
    key: 'custom_error',
    message,
    description,
    placement: 'topLeft',
    btn: (
      <Button type='primary' onClick={() => notification.close('custom_error')}>
        OK
      </Button>
    ),
    duration: 0,
    // !IMP: Custom styling - this overwrites antd's default topLeft position.
    style: {
      marginLeft: '-190px',
      width: '380px',
      left: '50%'
    }
  });
};

export const handleIntegrationError = action => {
  const {
    payload: { event, error }
  } = action;

  switch (event) {
    case actions.POPUP_CLOSED:
      openNotificationWithIcon({
        message: 'Login failed',
        description:
          'It seems there was a problem with your login attempt. Please verify your credentials and try again!'
      });
      break;
    // Handle custom error messages from Back-end response
    case actions.ERROR:
      const { message = 'Error', description = 'Something went wrong!!' } = error;
      openNotificationWithIcon({
        message,
        description
      });
      break;
    default:
      // Handle unknown error thrown from browser due to Ad blocker, Not handled from back-end etc
      if (error?.response?.status === 404) {
        openNotificationWithIcon({
          message: 'Something went wrong',
          description:
            'Oops! We are so sorry for this. Please try again after some time. If issue persists then please send us the screenshot on support@shoptimize.in.'
        });
        console.log({ error });
        break;
      }
      if (error?.response?.status === 500) {
        openNotificationWithIcon({
          message: 'Something went wrong',
          description:
            'Oops! We are so sorry for this. Please try again after some time. If issue persists then please send us the screenshot on support@shoptimize.in. '
        });
        console.log({ error });
        break;
      }
      openNotificationWithIcon({
        message: 'Something went wrong',
        description:
          'Oops! We are so sorry for this. Please try again after some time. If issue persists then please send us the screenshot on support@shoptimize.in.'
      });
  }
};
