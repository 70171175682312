import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const aarya = new Api('aarya', 50000);

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getMetrics() {
  try {
    var { data } = yield call(aarya.getMetrics);
    yield put({
      type: actions.SET_STATE,
      payload: { metrics: data }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getDimensions() {
  try {
    var { data } = yield call(aarya.getDimensions);
    yield put({
      type: actions.SET_STATE,
      payload: { dimensions: data }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getMetricsGroupsMapping() {
  try {
    var { data } = yield call(aarya.getMetricsGroupsMapping);
    yield put({
      type: actions.SET_STATE,
      payload: { metrics_groups_mapping: data }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_METRICS, getMetrics),
    takeEvery(actions.GET_METRICS_GROUPS_MAPPING, getMetricsGroupsMapping),
    takeEvery(actions.GET_DIMENSIONS, getDimensions)
  ]);
}
