const actions = {
  GET_TEMPLATES: 'dashboard/GET_TEMPLATES',
  GET_DASHBOARD: 'dashboard/GET_DASHBOARD',
  GET_SCHEMA_DETAILS: 'dashboard/GET_SCHEMA_DETAILS',
  ADD_DASHBOARD: 'dashboard/ADD_DASHBOARD',
  DELETE_DASHBOARD: 'dashboard/DELETE_DASHBOARD',
  UPDATE_DASHBOARD: 'dashboard/UPDATE_DASHBOARD',
  ADD_WIDGETS: 'dashboard/ADD_WIDGETS',
  DELETE_WIDGETS: 'dashboard/DELETE_WIDGETS',
  UPDATE_WIDGETS: 'dashboard/UPDATE_WIDGETS',
  SET_DATE_RANGE: 'dashboard/SET_DATE_RANGE',
  SET_MODE: 'dashboard/SET_MODE',
  SET_STATE: 'dashboard/SET_STATE',
  TOGGLE_LOADING: 'dashboard/TOGGLE_LOADING',
  SUBMIT_DISCREPANCY: 'dashboard/SUBMIT_DISCREPANCY',
  PUBLISH_CHART: 'dashboard/PUBLISH_CHART',
  // TRACKER ACTIONS
  TRACK_DASHBOARD: 'dashboard/TRACK_DASHBOARD',
  TRACK_WIDGET: 'dashboard/TRACK_WIDGET',
  UPDATE_DASHBOARD_IN_REDUX: 'dashboard/UPDATE_DASHBOARD_IN_REDUX',
  UPDATE_DASHBOARD_CHART_STATE: 'dashboard/UPDATE_DASHBOARD_CHART_STATE',
  CREATE_DASHBOARD_NEW: 'dashboard/CREATE_DASHBOARD_NEW'
};

export default actions;
