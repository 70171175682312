import React from 'react';
import { Select, Avatar } from 'antd';
import { Menu } from 'antd';

import { connect } from 'react-redux';

import UserActions from 'redux/User/actions';

import actions from 'redux/SideBar/actions';

var mapStateToProps = ({ User, TopBar }) => ({
  organisations: User.organisations,
  currentOrgId: User.userData.organisationId
});

var mapDispatchToProps = dispatch => ({
  switchOrg: id =>
    dispatch({
      type: UserActions.SWITCH_ORG,
      payload: {
        id
      }
    }),
  toggleCollapse: () =>
    dispatch({
      type: actions.TOGGLE_DETAILBAR
    })
});

const backgroundColors = ['#3498db', '#f1c40f', '#c0392b', '#2c3e50', '#2ecc71', '#e67e22'];

@connect(mapStateToProps, mapDispatchToProps)
class Solutions extends React.Component {
  onSwitchOrg = id => {
    // ---- to close sidebar on click of store change from user menu
    this.props.toggleCollapse();
    this.props.switchOrg(id);
  };

  render() {
    const { organisations, currentOrgId } = this.props;
    if (organisations) {
      return (
        <Menu
          mode='inline'
          inlineIndent={40}
          selectedKeys={[currentOrgId]}
          onClick={({ key }) => {
            this.onSwitchOrg(key);
          }}
        >
          <Menu.ItemGroup className='user__menu' key='g1' title='ORGANIZATIONS'>
            {organisations.map(({ id, name }, index) => {
              return (
                <Menu.Item key={id} title={name} onItemHover={() => {}} value={id}>
                  <Avatar style={{ backgroundColor: backgroundColors[index % backgroundColors.length] }}>
                    {name.slice(0, 1)}
                  </Avatar>
                  &nbsp;&nbsp;&nbsp;&nbsp;{name}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>
        </Menu>
      );
    }
    return null;
  }
}

export default Solutions;
