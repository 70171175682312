import React, { useState, lazy, Suspense, useRef } from 'react';
import { Form, Input, Button, Row, Col, Divider, Alert } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { isEmpty as _isEmpty, omitBy as _omitBy } from 'lodash';
import GenerateStoreCode from './GenerateStoreCode';
import { formItemLayout } from './utilities/initialStates';
import QueryString from 'query-string';
const { TextArea } = Input;

const VerifyPlatformCredentials = lazy(() =>
  import(/* webpackChunkName:  'VerifyPlatformCredentials' */ './VerifyPlatformCredentials')
);

const PlatformInformation = props => {
  const { getFieldDecorator } = props.form;
  const { currentStep, setCurrentStep, customerData, setCustomerData } = props;
  const reCaptchaRef = useRef();

  const [captchaVerified, setCaptchaVerified] = useState(false);

  // ----- Verify Re Captcha
  const onRecaptchaValidate = async value => {
    if (_isEmpty(value)) {
      setCaptchaVerified(false);
    }
    if (!_isEmpty(value)) {
      const queryObj = _omitBy({ secret: process.env.REACT_APP_RECAPTCHA_SECRET_KEY, response: value }, _isEmpty);
      const apiUrl = `${process.env.REACT_APP_API_HOST}/audit/verify-captcha?${QueryString.stringify(queryObj)}`;
      await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: process.env.REACT_APP_DUE_DELEGENCE_TOKEN
        }
      })
        .then(response => {
          if (response?.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        })
        .then(data => {
          if (data?.response?.success) setCaptchaVerified(true);
          else throw new Error();
        })
        .catch(error => {
          setCaptchaVerified(false);
        });
    }
  };

  // ----- Submit Diligence Form Data
  const onSubmitDiligenceForm = () => {
    if (!customerData?.is_store_verified || !customerData?.is_store_code_generated) {
      return null;
    }
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const finalObject = { ...customerData, ...values };
        const { is_store_verified, is_store_code_generated, ...rest } = finalObject;

        const queryObj = _omitBy(rest, _isEmpty);
        const apiUrl = `${process.env.REACT_APP_API_HOST}/audit/build-onboarding-job/`;
        const apiRequest = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_DUE_DELEGENCE_TOKEN
          },
          body: JSON.stringify({
            platform: finalObject?.platform,
            data: queryObj
          })
        });
        const apiResponse = await apiRequest.json();

        if (apiResponse?.status === 'SUCCESS') {
          setCustomerData(finalObject);
          setCurrentStep(currentStep + 1);
        } else {
          setCustomerData({ ...customerData });
        }
      }
    });
  };

  return (
    <>
      <Form {...formItemLayout} className='dc_form'>
        <Row gutter={24}>
          <Col md={24}>
            <span className='c-heading bold sm uppercase'>Generate Store Code</span>
            <Divider dashed />

            <span className='c-heading color-light mb-3 bold color-light'>
              Please generate unique store code by filling below information.
            </span>
            <GenerateStoreCode
              customerData={customerData}
              setCustomerData={setCustomerData}
              reCaptchaRef={reCaptchaRef}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col md={24}>
            <span className='c-heading bold sm uppercase'>Enter store platform credentials</span>
            <Divider dashed />
          </Col>
        </Row>

        <div
          className={`sectionOverlay ${
            !customerData?.is_store_verified && !customerData?.is_store_code_generated ? 'active' : ''
          }`}
        >
          {!customerData?.is_store_verified && !customerData?.is_store_code_generated && (
            <div className='overlayMessage'>
              <Alert
                message={`Generate your ${
                  customerData?.platform === 'magento_custom' ? 'Magento Custom' : customerData?.platform
                } store code`}
                description='(You can edit this section after generating store code)'
                type='error'
              />
            </div>
          )}
          <Suspense fallback={<></>}>
            <VerifyPlatformCredentials customerData={customerData} setCustomerData={setCustomerData} />
          </Suspense>
        </div>

        <div
          className={`sectionOverlay ${
            !customerData?.is_store_verified || !customerData?.is_store_code_generated ? 'active' : ''
          }`}
        >
          {(!customerData?.is_store_verified || !customerData?.is_store_code_generated) && (
            <div className='overlayMessage'>
              <Alert
                message={`Verify ${
                  customerData?.platform === 'magento_custom' ? 'Magento Custom' : customerData?.platform
                } credentials`}
                description='(You can edit this section after varifying credentials)'
                type='error'
              />
            </div>
          )}

          <Row>
            <Col md={24}>
              <span className='c-heading bold sm uppercase'>Enter platform details</span>
              <Divider dashed />
            </Col>
            <Col md={16}>
              <Form.Item label='Enter the statuses which are considered as part of successful order on the platform'>
                {getFieldDecorator('valid_order_status', {
                  initialValue: customerData?.valid_order_status,
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Shopify statuses!'
                    }
                  ]
                })(<TextArea autoSize={{ minRows: 2, maxRows: 4 }} />)}
                <span className='c-heading color-light sm mt-1 mb-0'>
                  Enter the statuses which are considered as part of successful order on the platform Enter in the comma
                  separated format.
                </span>
              </Form.Item>
            </Col>
            <Col md={16}>
              <Form.Item label='Enter product page URL'>
                {getFieldDecorator('product_page', {
                  initialValue: customerData?.product_page,
                  rules: [
                    {
                      required: true,
                      message: 'Please input product page URL!'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col md={16}>
              <Form.Item label='Enter category page URL'>
                {getFieldDecorator('category_page', {
                  initialValue: customerData?.category_page,
                  rules: [
                    {
                      required: true,
                      message: 'Please input product page URL!'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col md={12}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onRecaptchaValidate}
                onErrored={() => setCaptchaVerified(false)}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12}>
            {currentStep > 0 && (
              <button className='c-button is-bordered' onClick={() => setCurrentStep(currentStep - 1)}>
                Previous
              </button>
            )}
          </Col>
          <Col md={12} className='text-right'>
            <Button
              type='primary'
              onClick={onSubmitDiligenceForm}
              disabled={!customerData?.is_store_verified || !customerData?.is_store_code_generated || !captchaVerified}
            >
              Finish Setup
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Form.create()(PlatformInformation);
