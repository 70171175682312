const actions = {
  GET_BASE_INTEGRATIONS: 'integration/GET_BASE_INTEGRATIONS',
  GET_VISUALISATIONS: 'integration/GET_VISUALISATIONS',
  GET_BASE_VISUALISATIONS: 'integration/GET_BASE_VISUALISATIONS',
  ADD_INTEGRATION: 'integration/ADD_INTEGRATION',
  REMOVE_INTEGRATION: 'integration/REMOVE_INTEGRATION',
  UPDATE_INTEGRATION: 'integration/UPDATE_INTEGRATION',
  SET_STATE: 'integration/SET_STATE',
  TOGGLE_LOADING: 'integration/TOGGLE_LOADING',
  TRACK: 'integration/TRACK',
  CONNECTED_ACCOUNTS: 'integration/CONNECTED_ACCOUNTS',
  GET_CONNECTED_ACCOUNTS: 'integration/GET_CONNECTED_ACCOUNTS',
  CLEAN_CONNECTED_ACCOUNTS: 'integration/CLEAN_CONNECTED_ACCOUNTS'
};

export default actions;
