import { all } from 'redux-saga/effects';
import SideBar from './SideBar/sagas';
import Dashboard from './Dashboard/sagas';
import Integrations from './Integrations/sagas';
import Aarya from './AskAarya/sagas';
import ChartBuilder from './Explore/sagas';
import Segment from './Segment/sagas';
import User from './User/sagas';
import Notifications from './NotificationsSaga/sagas';
import Config from './Config/sagas';

export default function* rootSaga() {
  yield all([
    Dashboard(),
    SideBar(),
    Integrations(),
    Aarya(),
    User(),
    ChartBuilder(),
    Segment(),
    Notifications(),
    Config()
  ]);
}
