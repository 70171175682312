import React from 'react';
import { Layout, Spin } from 'antd';
import Sidebar from 'components/layout/SideBar';
import { connect } from 'react-redux';
import { isBrowser } from 'react-device-detect';

const { Sider, Content } = Layout;
@connect(({ Segment }) => ({ loading: Segment.loading }))
class App extends React.PureComponent {
  render() {
    const { children, loading } = this.props;

    return (
      <div className='c-pageContainer'>
        {isBrowser && (
          <Sider className='sidebar__container' width={100}>
            <Sidebar />
          </Sider>
        )}

        <Layout className='layout__main__container'>
          <div>
            <Content className='content__container'>
              <Spin spinning={loading}>{children}</Spin>
            </Content>
          </div>
        </Layout>
      </div>
    );
  }
}

export default App;
