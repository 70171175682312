import React from 'react';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Footer from 'layouts/Footer/Footer';

@withRouter
@connect(({ SideBar, Dashboard, Integrations, User }) => ({
  collapsed: SideBar.detailBar.collapsed,
  mode: Dashboard.mode,
  loading: Dashboard.loading || Integrations.loading || User.loading
}))
class Component extends React.PureComponent {
  render() {
    const { children, loading } = this.props;

    return (
      <>
        <div className='c-pageContainer'>
          <div id='componentWrapper' className='layout__main__container'>
            <div className={`${loading ? 'isFullPage isAtCenter' : 'isFullPage'}`}>
              <Spin spinning={loading}>{children}</Spin>
            </div>

            {!loading && <Footer />}
          </div>
        </div>
      </>
    );
  }
}

export default Component;
