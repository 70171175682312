export const elasticSearchConstants = {
  APPLIED_FILTERS_INSIGHTS: 'INSIGHTS/APPLIED_FILTERS',
  RESET_FILTERS_INSIGHTS: 'INSIGHTS/RESET_FILTERS',
  SET_APPLIED_MULTIFILTER: 'INSIGHTS/SET_APPLIED_MULTIFILTER',
  SET_NAVIGATOR_VISIBLE_SUCCESS: 'INSIGHTS/SET_NAVIGATOR_VISIBLE_SUCCESS',
  SET_NAVIGATOR_VISIBLE_FAILED: 'INSIGHTS/SET_NAVIGATOR_VISIBLE_FAILED',

  SET_NAVIGATOR_PREFERENCES_REQUEST: 'INSIGHTS/SET_NAVIGATOR_PREFERENCES_REQUEST',
  SET_NAVIGATOR_PREFERENCES_SUCCESS: 'INSIGHTS/SET_NAVIGATOR_PREFERENCES_SUCCESS',
  SET_NAVIGATOR_PREFERENCES_ERROR: 'INSIGHTS/SET_NAVIGATOR_PREFERENCES_ERROR',

  GET_NAVIGATOR_PREFERENCES_REQUEST: 'INSIGHTS/GET_NAVIGATOR_PREFERENCES_REQUEST',
  GET_NAVIGATOR_PREFERENCES_SUCCESS: 'INSIGHTS/GET_NAVIGATOR_PREFERENCES_SUCCESS',
  GET_NAVIGATOR_PREFERENCES_ERROR: 'INSIGHTS/GET_NAVIGATOR_PREFERENCES_ERROR'
};
