import React, { lazy, Suspense, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip, Icon, Drawer } from 'antd';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { isEmpty as _isEmpty, isArray as _isArray } from 'lodash';
import { isMobile } from 'react-device-detect';
import HambMenu from 'assets/icons/SVG/menu.svg';
import { mobileRoutes } from 'routes/RoutesMobile';
import GraasLogo from 'assets/images/graas_logo_small.svg';
import './Header.scss';

const ShareDeeplink = lazy(() =>
  import(/* webpackChunkName: 'ShareDeeplink' */ '../../components/ShareDeeplink/ShareDeeplink')
);
const AccountAvatar = lazy(() =>
  import(/* webpackChunkName: 'AccountAvatar' */ '../../components/Widget/WidgetAccountAvatar')
);
const UserProfile = lazy(() =>
  import(/* webpackChunkName: 'UserProfile' */ '../../components/UserProfile/UserProfile')
);
const Notifications = lazy(() => import(/* webpackChunkName: 'Notifications' */ '../../components/Notifications'));

const DateRangePickerWrapper = lazy(() =>
  import(/* webpackChunkName: 'DateRangePickerWrapper' */ '../../components/DateRangePickerWrapper')
);
const MonthRangePickerWrapper = lazy(() =>
  import(/* webpackChunkName: 'MonthRangePickerWrapper' */ 'components/MonthRangePickerWrapper')
);

const Header = props => {
  const {
    heading,
    subHeading,
    dateRange,
    compareDateRange,
    username,
    customComponent,
    customComponentPlacement,
    className,
    isShowInfoIcon,
    isShareVisible,
    isOrgChangeVisible,
    isNotificationVisible,
    isUserProfileVisible,
    isDateRangeVisible,
    isDateRangeCompareEnabled,
    isCompareActive,
    isCustomDashboard,
    isDueDiligenceEnabled,
    onDateRangeChange,
    onCompareDateRangeChange,
    onClickInfoIcon,
    onChangeCompareMode
  } = props;

  const [visible, setVisible] = useState(false);

  /* ----- Props Description -----
    heading => String => Main page Title
    subHeading => String =>  page sub Title
    isShowInfoIcon  => True / False =>  To show info icon near heading
    isShareVisible    => True / False => To show share component button
    isOrgChangeVisible  => True / False =>
    isNotificationVisible  => True / False =>
    isUserProfileVisible  => True / False =>
    isDateRangeVisible  => True / False => To show date range picker
    isDateRangeCompareEnabled => True / False => Compare mode for header date range picker
  */

  return (
    <>
      <header
        className={`c-pageHeader ${isMobile ? 'forMobile' : ''} ${!_isEmpty(className) ? className : ''} ${
          isDueDiligenceEnabled ? 'isFullWidth' : ''
        }`}
      >
        <div className='header_left'>
          {isMobile && (
            <button className='mobileMenuHandler' onClick={() => setVisible(true)}>
              <img src={HambMenu} alt='' />
            </button>
          )}

          {/* --- Heading --- */}
          {!_isEmpty(heading) && <h3 className='heading'>{heading || 'graas'}</h3>}

          {!isMobile && (
            <>
              {/* --- Sub Heading --- */}
              {!_isEmpty(subHeading) && (
                <span className='subHeading'>
                  <Tooltip placement='topLeft' title={subHeading}>
                    {subHeading}
                  </Tooltip>
                </span>
              )}

              {/* --- info icon in header --- */}
              {isShowInfoIcon && (
                <button className='infoButton' onClick={onClickInfoIcon}>
                  <Icon type='info-circle' />
                </button>
              )}

              {/* --- Share Button --- */}
              {isShareVisible && (
                <div className='editSection'>
                  <Suspense fallback={<></>}>
                    <ShareDeeplink />
                  </Suspense>
                </div>
              )}

              {customComponent && customComponentPlacement === 'left' && customComponent}
            </>
          )}
        </div>
        <div className='header_right'>
          <ul>
            {customComponent && customComponentPlacement === 'right' && <li>{customComponent}</li>}

            <>
              {!isMobile && (
                <>
                  {/* --- Date range --- */}
                  {!isDueDiligenceEnabled && isDateRangeVisible && !isCustomDashboard && (
                    <li>
                      <Suspense fallback={<></>}>
                        <DateRangePickerWrapper
                          dateRange={dateRange}
                          compareDateRange={compareDateRange}
                          isCompareActive={isCompareActive}
                          isDateRangeCompareEnabled={isDateRangeCompareEnabled}
                          onDateRangeChange={onDateRangeChange}
                          onCompareDateRangeChange={onCompareDateRangeChange}
                          onChangeCompareMode={onChangeCompareMode}
                        />
                      </Suspense>
                    </li>
                  )}
                  {isCustomDashboard && (
                    <li>
                      <Suspense fallback={<></>}>
                        <MonthRangePickerWrapper dateRange={dateRange} onDateRangeChange={onDateRangeChange} />
                      </Suspense>
                    </li>
                  )}
                  {/* --- Org switch Dropdown --- */}
                  {isOrgChangeVisible && (
                    <li>
                      <Suspense fallback={<></>}>
                        <AccountAvatar />
                      </Suspense>
                    </li>
                  )}
                </>
              )}
              {/* --- Notifications --- */}
              {!isDueDiligenceEnabled && isNotificationVisible && (
                <li>
                  <Suspense fallback={<></>}>
                    <Notifications />
                  </Suspense>
                </li>
              )}
              {/* --- User Profile --- */}
              {isUserProfileVisible && (
                <li>
                  <Suspense fallback={<></>}>
                    <UserProfile username={username} />
                  </Suspense>
                </li>
              )}
            </>
          </ul>
        </div>
      </header>

      {isMobile && (
        <Drawer
          title='Menu'
          placement='left'
          onClose={() => setVisible(false)}
          visible={visible}
          width={300}
          className='c-mobileSidebar'
          closeIcon={<CloseCircleOutlined />}
        >
          <div className='c-headMobile'>
            <button className='closeSidebar' onClick={() => setVisible(false)}>
              <CloseOutlined />
            </button>
            <div className='hm_logo'>
              <img className='logo' src={GraasLogo} alt='graas' />
            </div>
            <div className='hm_title'>
              <span className='mainTitle'>graas</span>
              <span className='subTitle'>Growth</span>
            </div>
          </div>
          <div className='c-transitionWrapper'>
            <div className='c-menuMobile'>
              <ul>
                <li className='hasBorder'>
                  <div className='storeSelect'>
                    <div className='storeSelect_options'>
                      <Suspense fallback={<></>}>
                        <AccountAvatar />
                      </Suspense>
                    </div>
                  </div>
                </li>
                {_isArray(mobileRoutes) &&
                  mobileRoutes.map((route, index) => {
                    return (
                      <li key={index}>
                        <NavLink to={route.link} className='menuLink'>
                          <span className='menu_name'>{route.route_name}</span>
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Header;
