export const insightsConstants = {
  GET_INSIGHTS_REQUEST: 'Insights/GET_INSIGHTS_REQUEST',
  GET_INSIGHTS_SUCCESS: 'Insights/GET_INSIGHTS_SUCCESS',
  GET_INSIGHTS_ERROR: 'Insights/GET_INSIGHTS_ERROR',

  GET_INSIGHT_BY_ID_REQUEST: 'Insights/GET_INSIGHT_BY_ID_REQUEST',
  GET_INSIGHT_BY_ID_SUCCESS: 'Insights/GET_INSIGHT_BY_ID_SUCCESS',
  GET_INSIGHT_BY_ID_ERROR: 'Insights/GET_INSIGHT_BY_ID_ERROR',

  GET_INSIGHT_COMMENTS_REQUEST: 'Insights/GET_INSIGHT_COMMENTS_REQUEST',
  GET_INSIGHT_COMMENTS_SUCCESS: 'Insights/GET_INSIGHT_COMMENTS_SUCCESS',
  GET_INSIGHT_COMMENTS_ERROR: 'Insights/GET_INSIGHT_COMMENTS_ERROR',

  POST_INSIGHT_COMMENTS_REQUEST: 'Insights/POST_INSIGHT_COMMENTS_REQUEST',
  POST_INSIGHT_COMMENTS_SUCCESS: 'Insights/POST_INSIGHT_COMMENTS_SUCCESS',
  POST_INSIGHT_COMMENTS_ERROR: 'Insights/POST_INSIGHT_COMMENTS_ERROR',

  READ_INSIGHT_BY_ID_REQUEST: 'Insights/READ_INSIGHT_BY_ID_REQUEST',
  READ_INSIGHT_BY_ID_SUCCESS: 'Insights/READ_INSIGHT_BY_ID_SUCCESS',
  READ_INSIGHT_BY_ID_ERROR: 'Insights/READ_INSIGHT_BY_ID_ERROR',

  GET_MAPPED_RECOMMENDATION_REQUEST: 'Insights/GET_MAPPED_RECOMMENDATION_REQUEST',
  GET_MAPPED_RECOMMENDATION_SUCCESS: 'Insights/GET_MAPPED_RECOMMENDATION_SUCCESS',
  GET_MAPPED_RECOMMENDATION_ERROR: 'Insights/GET_MAPPED_RECOMMENDATION_ERROR',

  CLEAR_SELECTED_INSIGHT: 'Insights/CLEAR_SELECTED_INSIGHT'
};
