export const attributionConstants = {
  GET_ATTRIBUTION_DIMENSION_REQUEST: 'ATTRIBUTION/GET_ATTRIBUTION_DIMENSION_REQUEST',
  GET_ATTRIBUTION_DIMENSION_SUCCESS: 'ATTRIBUTION/GET_ATTRIBUTION_DIMENSION_SUCCESS',
  GET_ATTRIBUTION_DIMENSION_FAILED: 'ATTRIBUTION/GET_ATTRIBUTION_DIMENSION_FAILED',

  GET_ATTRIBUTION_MODEL_REQUEST: 'ATTRIBUTION/GET_ATTRIBUTION_MODEL_REQUEST',
  GET_ATTRIBUTION_MODEL_SUCCESS: 'ATTRIBUTION/GET_ATTRIBUTION_MODEL_SUCCESS',
  GET_ATTRIBUTION_MODEL_FAILED: 'ATTRIBUTION/GET_ATTRIBUTION_MODEL_FAILED',

  SET_SELECTED_DATERANGE_SUCCESS: 'ATTRIBUTION/SET_SELECTED_DATERANGE_SUCCESS'
};
