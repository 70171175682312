import Api from '../api';

export default class Dashboard extends Api {
  getTemplate = () => this.get(`/templates/`);
  getSchemaDetails = () => this.get(`/visualisations/schema-details/`);

  getDashboardData = id =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/${id}/`);
        var dashData = this.formatDashboardResponse(data);
        resolve(dashData);
      } catch (error) {
        reject(error);
      }
    });

  createDashboardNew = postData => {
    return new Promise(async (resolve, reject) => {
      try {
        const formattedPostData = this.formatDashboardRequestNew(postData);
        var { data } = await this.post(`/create/`, formattedPostData);
        resolve(this.formatDashboardCreateResponse(data));
      } catch (error) {
        reject(error);
      }
    });
  };

  createDashboard = postData =>
    new Promise(async (resolve, reject) => {
      try {
        postData = this.formatDashboardRequest(postData);
        var { data } = await this.post(`/`, postData);
        resolve(this.formatDashboardCreateResponse(data));
      } catch (error) {
        reject(error);
      }
    });

  formatDashboardRequestNew = postData => {
    const modifiedRequestedObj = {};
    modifiedRequestedObj['name'] = postData.name;
    modifiedRequestedObj['base_template_id'] = postData.base_template_id;
    modifiedRequestedObj['chart_details'] = postData.chart_details.map(currViz => {
      const newVizObj = { ...currViz };
      newVizObj['configurations'] = { ...currViz.layoutConfig };
      delete newVizObj.layoutConfig;
      return newVizObj;
    });
    return modifiedRequestedObj;
  };

  publishChart = postData =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/visualisations/custom/`, postData);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  reportDiscrepancy = reportData =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/visualisations/report/discrepancy/`, reportData);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  deleteDashboard = id =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.delete(`/${id}/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  deleteDashboard = id => this.delete(`/${id}/`);

  updateDashboard = updateData =>
    new Promise(async (resolve, reject) => {
      try {
        var { dashId } = updateData;
        updateData = this.formatDashboardRequest(updateData);
        var { data } = await this.put(`/${dashId}/`, updateData);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  updateWidgets = updateData =>
    new Promise(async (resolve, reject) => {
      try {
        var { updatedWidgets, dashId } = updateData;
        var requestData = this.formatWidgetRequest(updatedWidgets, dashId);
        var { data } = await this.put(`/visualisations/${dashId}/`, requestData);
        data = this.formatDashboardResponse(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  createWidgets = widgetData =>
    new Promise(async (resolve, reject) => {
      try {
        widgetData = this.formatWidgetCreateRequest(widgetData);
        var { data } = await this.post(`/visualisations/`, widgetData);
        data = this.formatDashboardResponse(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  deleteWidgets = deleteData =>
    new Promise(async (resolve, reject) => {
      try {
        var { deletedWidgets, dashId } = deleteData;
        deleteData = this.formatWidgetRequest(deletedWidgets, dashId);
        var { data } = await this.delete(`/visualisations/${dashId}/`, {
          data: deleteData
        });
        data = this.formatDashboardResponse(data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  formatDashboardCreateResponse(response) {
    if (response && !response.detail) {
      return {
        id: response.id,
        name: response.name,
        baseTemplateId: response.base_template_id,
        is_public: response.is_public,
        widgetData: {
          dashboardId: response.id,
          widgets: this.formatWidgetResponse(response.visualisation_set)
        }
      };
    }
    return {
      messages: {
        error: 'Could Not fetch Data. Please retry after some time.'
      }
    };
  }

  formatDashboardResponse(response) {
    if (response && !response.detail) {
      var default_widgets = this.formatWidgetResponse(response.visualisation_set);
      var custom_widgets = this.formatCustomWidgetResponse(response.custom_visualisation_set);
      return {
        id: response.id,
        name: response.name,
        baseTemplateId: response.base_template_id,
        is_public: response.is_public,
        widgetData: {
          dashboardId: response.id,
          widgets: default_widgets.concat(custom_widgets)
        }
      };
    }
    return {
      messages: {
        error: 'Could Not fetch Data. Please retry after some time.'
      }
    };
  }

  getNewLayout() {
    var newLayout;
    return function () {
      if (newLayout) {
        if (newLayout.constructor === Object && Object.entries(newLayout).length !== 0) {
          if (newLayout.w === 2 || (newLayout.w === 1 && newLayout.x === 1)) {
            newLayout.y += 1;
            newLayout.x = 0;
          } else {
            newLayout.x = 1;
          }
        } else {
          newLayout.x = 0;
          newLayout.y = 0;
        }
        newLayout.w = 1;
      } else {
        newLayout = {
          h: 2,
          w: 1,
          x: 0,
          y: 0,
          maxH: 4,
          minH: 2
        };
      }
      return newLayout;
    };
  }

  formatCustomWidgetResponse(widgets) {
    if (widgets === undefined) {
      return [];
    }
    if (widgets.length && 'position' in widgets[0].configurations) {
      widgets = widgets.sort((a, b) => a.configurations.position - b.configurations.position);
    }
    var newLayout = this.getNewLayout();
    var layoutConfig;
    return widgets.map(vis => {
      if ('position' in vis.configurations) {
        layoutConfig = newLayout();
        layoutConfig = Object.assign({}, layoutConfig);
      } else {
        layoutConfig = Object.assign({}, vis.configurations);
      }
      return {
        id: vis.code,
        custom_widget_id: vis.id,
        title: vis.name,
        layoutConfig: layoutConfig,
        type: vis.type,
        query: vis.query,
        time_granularity: vis.properties.time_resolution_availability,
        info: vis.description,
        integration: vis.properties.integrations ? vis.properties.integrations : [],
        base_visualisation_id: null,
        owner: vis.owner,
        isSlow: false,
        is_custom: true
      };
    });
  }

  formatWidgetResponse(widgets, custom_widgets) {
    if (widgets.length && 'position' in widgets[0].configurations) {
      widgets = widgets.sort((a, b) => a.configurations.position - b.configurations.position);
    }
    var newLayout = this.getNewLayout();
    var layoutConfig;
    return widgets.map(vis => {
      if ('position' in vis.configurations) {
        layoutConfig = newLayout();
        layoutConfig = Object.assign({}, layoutConfig);
      } else {
        layoutConfig = Object.assign({}, vis.configurations);
      }
      return {
        id: vis.id,
        title: vis.base_visualisation_set.name,
        layoutConfig: layoutConfig,
        type: vis.base_visualisation_set.type,
        query: vis.base_visualisation_set.query,
        time_granularity: vis.base_visualisation_set.properties.time_resolution_availability,
        info: vis.base_visualisation_set.description,
        integration: vis.base_visualisation_set.properties.integrations
          ? vis.base_visualisation_set.properties.integrations
          : [],
        base_visualisation_id: vis.base_visualisation_id,
        owner: vis.owner,
        isSlow: vis.base_visualisation_set.slow_query,
        is_custom: false
      };
    });
  }

  formatDashboardRequest(data) {
    var requestData = {};
    var requestKeyMap = {
      name: 'name',
      template: 'base_template_id',
      is_public: 'is_public',
      owner: 'owner',
      dashId: 'id'
    };
    Object.keys(data).forEach(key => {
      if (requestKeyMap[key]) {
        requestData[requestKeyMap[key]] = data[key];
      }
    });
    return requestData;
  }

  formatWidgetCreateRequest(data) {
    return data.createdWidgets.map(vis => {
      return {
        dashboard_id: data.dashId,
        base_visualisation_id: vis.base_visualisation_id,
        configurations: vis.layoutConfig
      };
    });
  }

  formatWidgetRequest(widgetData, dashId) {
    var requestData = [];
    var requestKeyMap = {
      id: 'id',
      base_visualisation_id: 'base_visualisation_id',
      owner: 'owner',
      layoutConfig: 'configurations',
      is_custom: 'is_custom'
    };
    widgetData.forEach(data => {
      var itemData = {};
      if (data['is_custom'] === true) {
        Object.keys(data).forEach(key => {
          // if (requestKeyMapCustom[key]) {
          itemData['id'] = data['custom_widget_id'];
          itemData['base_visualisation_id'] = data['base_visualisation_id'];
          itemData['owner'] = data['owner'];
          itemData['configurations'] = data['layoutConfig'];
          itemData['is_custom'] = data['is_custom'];
          itemData['name'] = data['title'];
          // }
        });
      } else {
        Object.keys(data).forEach(key => {
          if (requestKeyMap[key]) {
            itemData[requestKeyMap[key]] = data[key];
          }
        });
      }
      itemData['dashboard_id'] = dashId;
      requestData.push(itemData);
    });

    return requestData;
  }

  formatDashboardTrack(dashboard, dateRange, compareRange) {
    var trackingData = {
      id: dashboard.id,
      name: dashboard.name,
      base_template_id: dashboard.baseTemplateId,
      is_public: dashboard.is_public,
      compare_range: compareRange ? compareRange : null,
      date_range: dateRange ? dateRange : null
    };
    return Object.assign(
      {},
      trackingData,
      this.formatWidgetTrack(dashboard.widgetData.widgets, dashboard.widgetData.dashboardId)
    );
  }

  formatWidgetTrack(widgets, dashId) {
    return {
      widgets: widgets.map(widget => {
        return {
          id: widget.id,
          base_visualisation_id: widget.base_visualisation_id,
          type: widget.type,
          dashboard_id: dashId,
          time_granularity: typeof widget.time_granularity !== 'boolean' ? widget.time_granularity : 'day',
          position: null,
          layout_columns: widget?.layoutConfig?.w === 2 ? 'Two Column' : 'One Column'
        };
      })
    };
  }
}
