const actions = {
  GET_SEGMENTS: 'segment/GET_SEGMENTS',
  GET_SEGMENT_REQUEST: 'segment/GET_SEGMENT_REQUEST',
  GET_SEGMENT_SUCCESS: 'segment/GET_SEGMENT_SUCCESS',
  GET_SEGMENT_ERROR: 'segment/GET_SEGMENT_ERROR',
  GET_SEGMENT_CATEGORY: 'segment/GET_SEGMENT_CATEGORY',
  GET_CATEGORY_FILTERS: 'segment/GET_CATEGORY_FILTERS',
  GET_SEGMENT_META: 'segment/GET_SEGMENT_META',
  GET_CLAUSE: 'segment/GET_CLAUSE',
  ADD_SEGMENT: 'segment/ADD_SEGMENT',
  CREATE_AUDIENCE_GOOGLE: 'segment/CREATE_AUDIENCE_GOOGLE',
  CREATE_AUDIENCE_SENDGRID: 'segment/CREATE_AUDIENCE_SENDGRID',
  CREATE_AUDIENCE_MAILCHIMP: 'segment/CREATE_AUDIENCE_MAILCHIMP',
  CREATE_AUDIENCE_FB: 'segment/CREATE_AUDIENCE_FB',
  DELETE_SEGMENT: 'segment/DELETE_SEGMENT',
  SET_STATE: 'segment/SET_STATE',
  TOGGLE_LOADING: 'segment/TOGGLE_LOADING',
  GET_TOTAL_USERS_COUNT: 'segment/GET_TOTAL_USERS_COUNT',
  GET_SUBCATEGORIES: 'segment/GET_SUBCATEGORIES',
  GET_SEGMENT_OVERLAP: 'segment/GET_SEGMENT_OVERLAP',
  GET_SEGMENT_OVERLAP_FETCHING: 'segment/GET_SEGMENT_OVERLAP_FETCHING',
  GET_SEGMENT_OVERLAP_SUCCESS: 'segment/GET_SEGMENT_OVERLAP_SUCCESS',
  GET_SEGMENT_OVERLAP_ERROR: 'segment/GET_SEGMENT_OVERLAP_ERROR'
};

export default actions;
