import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import Header from 'layouts/Header/Header';
import './system.scss';

const ErrorPage = props => {
  const { errorCode, errorMessage, errorPageHeading, errorTitle } = props;
  const userData = useSelector(state => state?.User?.userData);
  useEffect(() => {
    sessionStorage.removeItem('urlParamsObject');
  }, []);
  return (
    <>
      <div className='c-pageBody is-transparent'>
        <div className='c-contentWrapper'>
          <Header
            heading={errorPageHeading || 'Error'}
            username={userData?.email}
            isOrgChangeVisible
            isNotificationVisible
            isUserProfileVisible
          />
          <div className='wrapper_left is-fullWidth'>
            <div className='c-errorPageContainer'>
              <Helmet title='Page 404' />
              {errorCode ? (
                <div className='errorCode'>{errorCode}</div>
              ) : (
                // eslint-disable-next-line jsx-a11y/accessible-emoji
                <span role='img' className='errorCode_emoji'>
                  🚫
                </span>
              )}

              {errorTitle && <div className='errorText'>{errorTitle}</div>}
              {errorMessage && <div className='errorMessage'>{parse(errorMessage)}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
