import { all, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { track, actions as TRACKER_ACTION } from 'redux/tracker';

import Api from './api';
import actions from './actions';
import menuAction from '../SideBar/actions';
import dashboardAction from '../Dashboard/actions';
import integrationActions from '../Integrations/actions';
import segmentActions from '../Segment/actions';
import NotificationActions from '../NotificationsSaga/actions';
import ConfigActions from '../Config/actions';
import { isMobile } from 'react-device-detect';
import { isEmpty, isNumber as _isNumber } from 'lodash';

const auth = new Api('polaris/auth');
const org = new Api('polaris/organisation');

export function* login(action) {
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });
  var { email, password } = action.payload;
  try {
    var userData = yield call(auth.login, email, password);
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
    track(TRACKER_ACTION.login, userData);
    yield put(push('/'));
  } catch (error) {
    if (error.response && error.response.data && error.response.data.detail) {
      yield put({
        type: actions.LOGIN_FAIL,
        payload: {
          errorMessage: error.response.data.detail
        }
      });
    } else {
      yield put({
        type: actions.LOGIN_FAIL,
        payload: {
          errorMessage: 'Could not log you in. Please check Email or Password.'
        }
      });
    }
  }
}

export function* forgotPassword(action) {
  var { email } = action.payload;
  try {
    yield put({
      type: actions.FORGOT_PASSWORD_REQUEST
    });
    yield call(auth.forgotPassword, email);
    yield put({
      type: actions.FORGOT_PASSWORD_SUCCESS
    });
  } catch (error) {
    yield put({
      type: actions.FORGOT_PASSWORD_FAILURE,
      payload: {
        errorMessage: error?.response?.data?.reason || 'Oops! Something went wrong!'
      }
    });
  }
}

export function* switchOrg(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });

  try {
    var userData = yield call(org.switchOrg, action.payload.id);
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
    // route habdlling for accessing switch org and redirect
    let redirectRoute = '/dashboard';
    if (isMobile) {
      redirectRoute = '/dashboardsummary';
    } else {
      const [, route, id] = window.location.pathname.split('/');
      if (route === 'dashboard' && !isEmpty(id) && _isNumber(+id)) {
        redirectRoute = window.location.pathname;
      }
    }
    window.open(redirectRoute, '_self', '', true);
  } catch (error) {
    yield put(push('/dashboard'));
    yield message.error(error.response.data.detail);
  } finally {
    yield put({
      type: actions.SET_DATA,
      payload: {
        loading: false
      }
    });
  }
}

export function* getOrgs() {
  try {
    var orgList = yield call(org.getOrgs);
    yield put({
      type: actions.SET_DATA,
      payload: {
        organisations: orgList
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export function* loginSuccess(action) {
  var { userData } = action.payload;
  yield put({
    type: actions.SET_DATA,
    payload: {
      userData,
      loading: false,
      authorized: true,
      loginError: null
    }
  });

  yield put({
    type: ConfigActions.GET_CONFIG
  });

  yield put({
    type: actions.GET_ORGS
  });

  yield put({
    type: menuAction.UPDATE_MENU
  });
  yield put({
    type: NotificationActions.GET_NOTIFICATION
  });
  yield put({
    type: dashboardAction.GET_TEMPLATES
  });
  yield put({
    type: integrationActions.GET_BASE_VISUALISATIONS
  });
  yield put({
    type: integrationActions.GET_BASE_INTEGRATIONS
  });
  yield put({
    type: segmentActions.GET_SEGMENTS
  });
  yield put({
    type: segmentActions.GET_CATEGORY_FILTERS
  });
}

export function* loginFail(action) {
  var { errorMessage } = action.payload;
  yield put({
    type: actions.SET_DATA,
    payload: {
      userData: null,
      authToken: null,
      loading: false,
      authorized: false,
      loginError: errorMessage
    }
  });
}

export function* logout(action) {
  try {
    yield auth.logout();
    yield put({
      type: actions.SET_DATA,
      payload: {
        userData: null,
        authorized: false,
        loading: false,
        authToken: null,
        loginError: null
      }
    });
    if (action.payload) track(TRACKER_ACTION.logout, action.payload.user);
    yield auth._afterlogout();
    yield put(push('/system/login'));
  } catch (error) {
    message.error('Failed to Logout, Please try again!');
  }
}

export function* CHECK_LOGIN() {
  yield put({
    type: actions.SET_DATA,
    payload: {
      loading: true
    }
  });
  var authorized = yield auth.checkLogin();
  if (authorized) {
    var userData = auth._getUser();
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: {
        userData
      }
    });
  } else {
    yield put({
      type: actions.LOGIN_FAIL,
      payload: {
        errorMessage: ''
      }
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.LOGIN_FAIL, loginFail),
    takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.GET_ORGS, getOrgs),
    takeEvery(actions.SWITCH_ORG, switchOrg),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),

    CHECK_LOGIN() // run once on app load to fetch menu data
  ]);
}
