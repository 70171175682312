import Api from '../api';
import fileDownload from 'js-file-download';

export default class AskAarya extends Api {
  askAarya = question => this.post('/questions/', { question });
  getQuestions = () => this.get('/questions/');
  getAnswers = () => this.get('/answers');
  getReport = (answerId, questionId) =>
    new Promise(async (resolve, reject) => {
      try {
        var response = await this.get(`report/${answerId}`, { responseType: 'blob' });
        fileDownload(response.data, `Aarya_Report_Id_${questionId}.pdf`);
        resolve('File Downloaded');
      } catch (error) {
        reject(error);
      }
    });
}
