import axios from 'axios';
import { FORGOT_PASSWORD_URL, LOGIN_URL } from './User/api-urls';

import jwt_decode from 'jwt-decode';

export default class Api {
  constructor(url = null, timeout = null) {
    if (!timeout) {
      timeout = 50000;
    }

    const axiosCancelToken = axios.CancelToken;
    this.source = axiosCancelToken.source();
    const cancelToken = this.source.token;

    const BASE_URL = `${process.env.REACT_APP_API_HOST || 'http://localhost:8000'}/${url}`;

    var instance = axios.create({
      baseURL: BASE_URL,
      responseType: 'json',
      timeout: timeout,
      cancelToken
    });

    instance.interceptors.request.use(request => {
      if (!this._isPublicApi(request)) {
        request.headers.Authorization = `Bearer ${this._getAccessToken()}`;
      }
      return request;
    });

    this.instance = instance;
    this.get = this._apiMethodWrapper(this.instance.get);
    this.delete = this._apiMethodWrapper(this.instance.delete);
    this.post = this._apiMethodWrapper(this.instance.post);
    this.put = this._apiMethodWrapper(this.instance.put);
    this.patch = this._apiMethodWrapper(this.instance.patch);
    this.cancel = () => {
      this.source.cancel('User navigated somewhere else.');
    };
  }

  _isPublicApi(request) {
    let publicAPI = false;
    if ([LOGIN_URL, FORGOT_PASSWORD_URL].includes(request.url)) {
      publicAPI = true;
    }
    return publicAPI;
  }

  // --- get Access token from cookies
  _getAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return accessToken;
    } else {
      this._clearAuthCookies();
    }
  }

  // --- get refresh token from cookies
  _getRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      return refreshToken;
    } else {
      this._clearAuthCookies();
    }
  }

  // --- get user data from cookies
  _getUser() {
    let userData = localStorage.getItem('user_metadata');
    if (userData) {
      userData = JSON.parse(userData);
      return userData;
    } else {
      this._clearAuthCookies();
    }
  }

  // --- Set access token in cookies
  _setAccessToken(accessToken) {
    if (accessToken) {
      return localStorage.setItem('accessToken', accessToken);
    }
  }

  // --- Set refresh token in cookies
  _setRefreshToken(refreshToken) {
    if (refreshToken) {
      return localStorage.setItem('refreshToken', refreshToken);
    }
  }

  // --- Set user data in cookies
  _setUser(userData) {
    if (userData) {
      return localStorage.setItem('user_metadata', JSON.stringify(userData));
    }
  }

  // --- Get updated refresh token
  async _getTokenFromRefresh() {
    const refreshToken = this._getRefreshToken();

    if (refreshToken) {
      try {
        var { data } = await this.instance.post(
          `${process.env.REACT_APP_API_HOST ?? 'http://localhost:8000'}/polaris/auth/refresh/`,
          {
            refresh: refreshToken
          }
        );

        this._setAccessToken(data.access);
        this.instance.defaults.headers.Authorization = `Bearer ${this._getAccessToken()}`;
        return true;
      } catch (error) {
        console.log('Failed to get AccessToken');
        return false;
      }
    }
    return false;
  }

  // --- Check token expiry
  _checkTokenExpiry() {
    const accessToken = this._getAccessToken();
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken);
      var tokenExp = parseInt(decodedToken?.exp);
      if (tokenExp > parseInt(Date.now() / 1000)) {
        return false;
      }
    }
    return true;
  }

  // --- Check refresh / access token is valid or not
  async _isTokenAlive() {
    if (this._checkTokenExpiry()) {
      const tokenFetched = await this._getTokenFromRefresh();
      if (!tokenFetched) {
        return false;
      }
    }
    return true;
  }

  // --- Clear cookies related to auth
  _clearAuthCookies() {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user_metadata');
  }

  // --- API Method wrapper
  _apiMethodWrapper = func => {
    return async function () {
      const isAuth = await this._isTokenAlive();
      if (!isAuth) {
        window.location.reload();
      }

      if (isAuth) {
        try {
          const result = await func.apply(this, arguments);
          return result;
        } catch (error) {
          return error;
        }
      }
      return { status: 401 };
    };
  };
}
