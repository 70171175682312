/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import moment from 'moment';
import nFormatter from '../redux/utils/nFormatter';
import { isEmpty as _isEmpty, omitBy as _omitBy, isNumber as _isNumber } from 'lodash';

// ---- ICONS - Small ----
import GoogleAds_small from '../assets/small-icons/PNG/google-ads-fevicon.png';
import Magento_small from '../assets/small-icons/PNG/magento-fevicon.png';
import GoogleAnalytics_small from '../assets/small-icons/PNG/google-analytics-fevicon.png';
import Shoptimize_small from '../assets/small-icons/PNG/shoptimize-fevicon.png';
import FacebookAds_small from '../assets/small-icons/PNG/facebookAds-fevicon.png';
import GoogleSearchConsole_small from '../assets/small-icons/PNG/google-console-fevicon.png';
import Shopify_small from '../assets/small-icons/PNG/shopify-fevicon.png';
import Instagram_small from '../assets/small-icons/PNG/instagram-fevicon.png';
import Amazon_small from '../assets/small-icons/PNG/amazon-fevicon.png';
import Delhivery_small from '../assets/small-icons/PNG/delhivery-fevicon.png';
import SendGrid_small from '../assets/small-icons/PNG/sendgrid-favicon.png';
import WebpageTest_small from '../assets/small-icons/PNG/webpage-test-fevicon.png';
import SalesForce_small from '../assets/small-icons/PNG/salesforce-fevicon.png';
import Taboola_small from '../assets/small-icons/PNG/taboola-fevicon.png';
import HubSpot_small from '../assets/small-icons/PNG/hubspot-fevicon.png';
import BillDesk_small from '../assets/small-icons/PNG/billdesk-fevicon.png';
import PayU_small from '../assets/small-icons/PNG/payu-fevicon.png';
import Shopee_small from '../assets/small-icons/PNG/shopee-favicon.png';
import Lazada_small from '../assets/small-icons/PNG/lazada-fevicon.png';
import Mailchimp_small from '../assets/small-icons/PNG/mailchimpm-fevicon.png';
import Amplitude_small from '../assets/small-icons/PNG/amplitude-fevicon.png';
import Shiprocket_small from '../assets/small-icons/PNG/shiprocket-fevicon.png';
import Lighthouse_small from '../assets/small-icons/Lighthouse_small.svg';
import Flipkart_small from '../assets/small-icons/flipkart-logo.png';
import Semrush_small from '../assets/small-icons/PNG/semrush_small_icon.png';
import GraasIconSmall from 'assets/images/graas_logo_small.svg';

// ---- ICONS - Large ----
import GoogleAds from '../assets/icons/PNG/google-ads-logo.png';
import Magento from '../assets/icons/PNG/magento-logo.png';
import GoogleAnalytics from '../assets/icons/PNG/google-analytics-logo.png';
import Shoptimize from '../assets/icons/PNG/shoptimize-logo.png';
import FacebookAds from '../assets/icons/PNG/facebook-ads-logo.png';
import GoogleSearchConsole from '../assets/icons/PNG/search-console-logo.png';
import Shopify from '../assets/icons/PNG/shopify-logo.png';
import Instagram from '../assets/icons/PNG/insta-logo.png';
import Amazon from '../assets/icons/PNG/amazon-logo.png';
import Delhivery from '../assets/icons/PNG/delhivery-logo.png';
import SendGrid from '../assets/icons/PNG/sendgrid-logo.png';
import WebpageTest from '../assets/icons/PNG/webpage-tester-logo.png';
import SalesForce from '../assets/icons/PNG/salesforce-logo.png';
import Taboola from '../assets/icons/PNG/taboola-logo.png';
import HubSpot from '../assets/icons/PNG/hubspot-logo.png';
import BillDesk from '../assets/icons/PNG/billdesk-logo.png';
import PayU from '../assets/icons/PNG/payu-logo.png';
import Shopee from '../assets/icons/PNG/shopee-logo.png';
import Lazada from '../assets/icons/PNG/lazada-logo.png';
import Mailchimp from '../assets/icons/PNG/mailchimp-logo.png';
import Amplitude from '../assets/icons/PNG/amplitude-logo.png';
import Shiprocket from '../assets/icons/PNG/shiprocket-logo.png';
import Klaviyo from '../assets/icons/PNG/klaviyo.png';
import Razorpay from '../assets/icons/SVG/Razorpay.svg';
import Sezzle from '../assets/icons/PNG/sezzle.jpg';
import Unicommerce from '../assets/icons/PNG/unicommerce.png';
import Pickrr from '../assets/icons/PNG/pickrr.png';
import WoCommerce from '../assets/icons/PNG/wooCommerce.png';
import NoLogo from '../assets/icons/PNG/no_logo.png';
import Lighthouse from '../assets/icons/SVG/Lighthouse.svg';
import Flipkart from '../assets/icons/PNG/flipkart-logo.png';
import Semrush from '../assets/icons/PNG/semrush.png';
import Graas from 'assets/images/graas_white.svg';
import QueryString from 'query-string';

// -- code to display actual name of selected filter tag
export const actualFilterTagTitles = [
  {
    value: 'to_do',
    title: 'New'
  },
  {
    value: 'done',
    title: 'Completed'
  },
  {
    value: 'in_progress',
    title: 'In progress'
  },
  {
    value: 'minor',
    title: 'Low'
  },
  {
    value: 'major',
    title: 'Moderate'
  },
  {
    value: 'execution_failed',
    title: 'Failed'
  },
  {
    value: 'ignore',
    title: 'Ignored'
  },
  {
    value: 'is_result_soon',
    title: 'Result soon'
  },
  {
    value: 'is_due_soon',
    title: 'Due soon'
  },
  {
    value: 'read',
    title: 'Read'
  },
  {
    value: 'unread',
    title: 'Unread'
  },
  {
    title: 'Daily - Yesterday',
    value: 'yesterday'
  },
  {
    title: 'Daily - Last Week',
    value: 'last_week'
  },
  {
    title: 'Daily - Last Month',
    value: 'last_month'
  },
  {
    title: 'Daily - Last Year',
    value: 'last_year'
  },
  {
    title: 'Weekly - Last Week',
    value: 'last_week_aggregate'
  },
  {
    title: 'Weekly - Last Month',
    value: 'same_week_last_month'
  },
  {
    title: 'Weekly - Last Year',
    value: 'same_week_last_year'
  }
];

export const setFilterTagTitle = selectedFilterTag => {
  const filterTagName =
    actualFilterTagTitles &&
    actualFilterTagTitles.find(item => selectedFilterTag.toLowerCase() === item.value.toLowerCase());
  return filterTagName ? filterTagName.title : selectedFilterTag;
};

// ---- ICONS MAPPING ----
export const idToIconMap = {
  fbads: {
    small: FacebookAds_small,
    large: FacebookAds,
    title: 'Facebook Ads'
  },
  ga: { small: GoogleAnalytics_small, large: GoogleAnalytics, title: 'Google Analytics' },
  gads: { small: GoogleAds_small, large: GoogleAds, title: 'Google Ads' },
  gsc: { small: GoogleSearchConsole_small, large: GoogleSearchConsole, title: 'Google Search Console' },
  mg: { small: Shoptimize_small, large: Shoptimize, title: 'Web Hosting Platform' },
  shp: { small: Shoptimize_small, large: Shoptimize, title: 'Shoptimize Tracker' },
  webt: { small: WebpageTest_small, large: WebpageTest, title: 'Webpage Tester' },
  sg: { small: SendGrid_small, large: SendGrid, title: 'SendGrid' },
  insta: { small: Instagram_small, large: Instagram, title: 'Instagram' },
  amz: { small: Amazon_small, large: Amazon, title: 'Amazon' },
  dlh: { small: Delhivery_small, large: Delhivery, title: 'Delhivery' },
  shf: { small: Shopify_small, large: Shopify, title: 'Shopify' },
  mgcom: { small: Magento_small, large: Magento, title: 'Magento' },
  slf: { small: SalesForce_small, large: SalesForce, title: 'Sales Force' },
  tbl: { small: Taboola_small, large: Taboola, title: 'Taboola' },
  payu: { small: PayU_small, large: PayU, title: 'PayU' },
  hbs: { small: HubSpot_small, large: HubSpot, title: 'HubSpot' },
  bdsk: { small: BillDesk_small, large: BillDesk, title: 'BillDesk' },
  shpe: { small: Shopee_small, large: Shopee, title: 'Shopee' },
  lz: { small: Lazada_small, large: Lazada, title: 'Lazada' },
  mlc: { small: Mailchimp_small, large: Mailchimp, title: 'Mailchimp' },
  amp: { small: Amplitude_small, large: Amplitude, title: 'Amplitude' },
  shipr: { small: Shiprocket_small, large: Shiprocket, title: 'ShipRocket' },
  klavio: { small: Klaviyo, large: Klaviyo, title: 'Klaviyo' },
  rpay: { small: Razorpay, large: Razorpay, title: 'Razorpay' },
  sez: { small: Sezzle, large: Sezzle, title: 'Sezzle' },
  unicom: { small: Unicommerce, large: Unicommerce, title: 'Unicommerce' },
  pickr: { small: Pickrr, large: Pickrr, title: 'Pickrr' },
  wcom: { small: WoCommerce, large: WoCommerce, title: 'Woocommerce' },
  noLogo: { small: NoLogo, large: NoLogo, title: 'No Logo' },
  lighthouse: { small: Lighthouse_small, large: Lighthouse, title: 'Lighthouse' },
  flp: { small: Flipkart_small, large: Flipkart, title: 'Flipkart' },
  smr: { small: Semrush_small, large: Semrush, title: 'Semrush' },
  d2c: { small: GraasIconSmall, large: Graas, title: 'D2C' },
  mkp: { small: GraasIconSmall, large: Graas, title: 'Marketplace' }
};

// ---- SIDEBAR KEYS ----
export const SIDEBAR_KEYS = ['notification', 'solutions'];

// ---- Routes mapping ----
export const routesMapping = {
  dashboard: '/dashboard',
  mobileDashboard: '/dashboardsummary',
  businessOverviewDashboard: '/business-overview',
  insight: '/insights',
  insightDetail: '/insights/:id',
  recommendation: '/recommendations',
  recommendationDetail: '/recommendations/:id',
  focusedInsights: '/spotlight',
  reports: '/report',
  help: '/help',
  attribution: '/attribution',
  addDataSources: 'data_sources/add'
};

// ---- Set tag color based on priority
export const setPriorityColorClass = priority => {
  let priorityClass = '';
  switch (priority.toLowerCase()) {
    case 'blocker':
      priorityClass = 'is-blocker';
      break;
    case 'critical':
      priorityClass = 'is-critical';
      break;
    case 'major':
      priorityClass = 'is-major';
      break;
    case 'low':
    case 'minor':
      priorityClass = 'is-low';
      break;
    default:
      priorityClass = '';
  }
  return priorityClass;
};

// -- ISO to Date conversion
export const isoToDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  return `${day}-${month}-${year}`;
};

// -- Sort Values
export const sortValues = array => {
  return array.sort(function (a, b) {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

// -- Object for Blank screen messages
export const blankScreenMessages = {
  insights: {
    heading: 'Aarya hasn’t generated any Insights yet. Give her some time to come up with awesome insights. ',
    subHeading: 'Meanwhile just make sure you have connected all the data sources.'
  },
  recommendations: {
    heading:
      'Aarya hasn’t generated any recommendations yet. Give her some time to come up with awesome recommendations. ',
    subHeading: 'Meanwhile just make sure you have connected all the data sources.'
  },
  reports: {
    heading: 'Reports are not generated yet. Please check later.',
    subHeading: '',
    errorMessage: 'Error.. Failed to get the reports data',
    fetchError:
      'Oops! We couldn’t fetch your report. We will investigate this and resolve it on priority. Try again after sometime.',
    notAllowd: 'Oops! Either you are not allowed to fetch this report or the report does not exist.'
  }
};

// -- function to format currency INR
export const formatInrCurrency = amount => {
  return _isNumber(amount) ? amount?.toFixed(2)?.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,') : 0;
};

export const inrCurrencyShortner = amount => {
  amount = amount.toString().replace(/[^0-9.]/g, '');
  if (amount < 1000) {
    return amount;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (amount >= si[index].v) {
      break;
    }
  }
  return (amount / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};

export const accentColor = '#5cb7b7';

// -- Deep check compare objects
export const deepEqual = (x, y) => {
  if (x === y) {
    return true;
  } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else return false;
    }

    return true;
  } else return false;
};

export const codeToKeyMap = {
  fbads: 'facebook-ads',
  gads: 'google-ads',
  gsc: 'google-search-console',
  ga: 'google-analytics',
  mg: 'shoptimize-platform',
  shp: 'shoptimize-tracker',
  webt: 'webpage-tester',
  sg: 'sendgrid',
  dlh: 'delhivery',
  shf: 'shopify',
  amz: 'amazon',
  mlc: 'mailchimp',
  lz: 'lazada',
  shipr: 'shiprocket',
  wcom: 'wcom',
  lighthouse: 'lighthouse',
  unicom: 'unicom',
  smr: 'semrush',
  sia: 'sellinall'
};

// -- This function will return true or false on basis of segment object data

export const isSegmentExist = segmentObject => {
  return !!segmentObject.users_count;
};

// -- This function will retyrn true or false on basis of segment created_at time and current time if it is less than 5 mins

export const shouldShowCalculating = createAt => {
  const currenttime = moment().toISOString();
  const minutes = moment(currenttime).diff(moment(createAt), 'minutes');
  return minutes < 5;
};

export const buildESQuery = filters => {
  const appliedFilters = { ...filters, page: `${filters.page}` };
  const queryObj = _omitBy(appliedFilters, _isEmpty);
  return QueryString.stringify(queryObj);
};

export const facetLabelMapping = {
  dimensionLabel: {
    label: 'Dimension',
    value: 'dimension_label'
  },
  dimensionValueLabel: {
    label: 'Dimension Value',
    value: 'dimension_value_label'
  },
  metricLabel: {
    label: 'Metric',
    value: 'metric_label'
  },
  insightDate: {
    label: 'Insight Date',
    value: 'insight_date'
  },
  readUser: {
    label: 'Read/Unread',
    value: 'is_read_user'
  },
  comparison: {
    label: 'Comparison',
    value: 'time_comparison_category'
  },
  tags: {
    label: 'Applied Tags',
    value: 'tags'
  },
  insight_date__range: {
    label: 'Date Range',
    value: 'insight_date__range'
  },
  rule_type: {
    label: 'Insight Types',
    value: 'rule_type'
  },
  group: {
    label: 'Analysis Type',
    value: 'group'
  },
  channel: {
    label: 'Channels',
    value: 'channel'
  }
};

export const showSeeSplitMapping = [
  'MarketingOverallAdCustom.conversionRate',
  'MarketingOverallAdCustom.TotalAdSpend',
  'MarketingOverall.ctr',
  'MarketingOverallAdCustom.roas',
  'MarketingOverallAdCustom.costPerConversion',
  'MarketingOverallAdCustom.revenue',
  'MarketingOverall.impressions',
  'MarketingOverallAdCustom.AvgCPC'
];
export const dimensionMappingForSeeSplit = ['MarketingOverallAdCustom.channel', 'MarketingOverall.channel'];

const getPercentChangeJsx = ({ percent, isNegativeMeasure = false, shouldShowSign = false }) => {
  if (!percent) return null;

  const percentChangeString = parseInt(percent).toFixed(2);

  if (shouldShowSign) {
    return <span style={{ color: '#cccccc', fontSize: '12px' }}>{percentChangeString.replace('-', '')} %</span>;
  }

  if (parseFloat(percentChangeString) < 0) {
    return (
      <span style={{ color: '#FF6636', fontSize: '12px' }} className={isNegativeMeasure ? 'negative-measure-down' : ''}>
        <i className={`fa fa-caret-down`} style={{ marginRight: '4px' }}></i>
        {percentChangeString.replace('-', '')} %
      </span>
    );
  }

  return (
    <span style={{ color: '#4FE261', fontSize: '12px' }} className={isNegativeMeasure ? 'negative-measure-up' : ''}>
      <i className={`fa fa-caret-up`} style={{ marginRight: '4px' }}></i>
      {percentChangeString} %
    </span>
  );
};

export const shouldShowSplit = raw_data => {
  let channelsObject = {};
  let splitValue = {};
  let objectKey = {};

  if (raw_data) {
    for (let key in raw_data['summary']) {
      channelsObject = raw_data['summary'][key]['groups'];
    }
    objectKey = Object.keys(channelsObject);
    let dimensionValue = dimensionMappingForSeeSplit.find(
      singleDimensionValue => singleDimensionValue === objectKey[0]
    );
    if (channelsObject.hasOwnProperty(dimensionValue)) {
      splitValue = channelsObject?.[dimensionValue];
    }
  }

  return !_isEmpty(splitValue);
};

export const showSplitData = raw_data => {
  let channelsObject = {};
  let channelsCompare = {};
  let splitValue = {};
  let splitCompare = {};
  let objectKey = {};

  //TODO: handle negativeMeasure in future
  // const measure = raw_data?.compareSummary && Object.keys(raw_data?.compareSummary);

  const isNegativeMeasure = false;

  if (raw_data) {
    for (let key in raw_data['summary']) {
      channelsObject = raw_data['summary'][key]['groups'];
      channelsCompare = raw_data?.['compareSummary']?.[key]?.['groups'];
    }
    objectKey = Object.keys(channelsObject);
    let dimensionValue = dimensionMappingForSeeSplit.find(
      singleDimensionValue => singleDimensionValue === objectKey[0]
    );
    if (channelsObject.hasOwnProperty(dimensionValue)) {
      splitValue = channelsObject?.[dimensionValue];
      splitCompare = channelsCompare?.[dimensionValue];
    }
  }
  return (
    <div>
      {Object.entries(splitValue).map(splitItem => {
        const [key, value] = splitItem;
        const compareValue = splitCompare?.[key];

        return (
          <div key={key} className='c-popoverGraph'>
            <div className='pg_info with-title'>
              <span className='pg_title'>{key} </span>
              <span className='pg_data'>
                {nFormatter(value, 2)}
                <div className='pg_change'>
                  {!_isEmpty(raw_data?.compareSummary) &&
                    getPercentChangeJsx({ percent: getPercentChange(value, compareValue), isNegativeMeasure })}
                </div>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export const dateToSMDate = date => {
  const formattedDate = moment(date).format('DD  MMM  YYYY');
  if (formattedDate === 'Invalid date') return date;
  return formattedDate;
};

export const getPercentChange = (currentValue, oldValue) => {
  const percent = ((currentValue - oldValue) / oldValue) * 100;

  if (isNaN(percent)) return Infinity;

  return percent.toFixed(1);
};

export const getPercentChangeWithIcon = (currentValue, oldValue) => {
  const percent = ((currentValue - oldValue) / oldValue) * 100;

  if (isNaN(percent)) return Infinity;

  const checkDifference = Math.sign(percent);
  return {
    change: Math.abs(percent).toFixed(2),
    difference: checkDifference
  };
};

export const getPageFromMeta = meta => {
  const { page, params } = meta;
  const route = {
    insight: '/insights',
    recommendation: '/recommendations',
    report: '/report',
    report_detail: `/report/${params?.id || 0}`,
    segment_create: '/segments/add'
  };
  return route[page || '/'];
};

// --- function to create date ranges for data compair
export const createDateRangeObject = dateRange => {
  let defaultRange = {
    compare_date_range: {
      start_date: moment().subtract(2, 'day').utc().format('YYYY-MM-DD'),
      end_date: moment().subtract(2, 'day').utc().format('YYYY-MM-DD')
    },
    date_range: {
      start_date: moment().subtract(1, 'day').utc().format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'day').utc().format('YYYY-MM-DD')
    }
  };

  if (dateRange === 'lastWeek') {
    defaultRange = {
      compare_date_range: {
        start_date: moment().subtract(8, 'day').utc().format('YYYY-MM-DD'),
        end_date: moment().subtract(15, 'day').utc().format('YYYY-MM-DD')
      },
      date_range: {
        start_date: moment().utc().format('YYYY-MM-DD'),
        end_date: moment().subtract(7, 'day').utc().format('YYYY-MM-DD')
      }
    };
  }

  return defaultRange || {};
};

export const handleSymbol = (title, value) => {
  if (!title.includes('(')) return value;

  const currencySign = title.split('(')[1].split(')')[0];
  return `${currencySign} ${value}`;
};

export const getTagColor = (tag = '') => {
  const tagLowerCase = tag.toLowerCase();
  const low = ['opportunity'];
  const critical = ['threat'];

  if (low.includes(tagLowerCase)) return 'is-low';
  else if (critical.includes(tagLowerCase)) return 'is-critical';
  else return 'is-disabled';
};

export const userMenuLinksArray = [
  // {
  //   iconType: 'bell',
  //   linkText: 'Notification',
  //   linkClassName: 'icon',
  //   linkButtonClassName: 'linkButton'
  // }
];

export const truncateString = (stringText, numberTillTruncat) => {
  return stringText?.length > numberTillTruncat ? stringText.substring(0, numberTillTruncat - 1) + '...' : stringText;
};

// ----------- DATE - MOMENT Related Functions ----------------
export const stringToMoment = rangeStrings =>
  rangeStrings ? [moment(rangeStrings[0]).format('YYYY-MM-DD'), moment(rangeStrings[1]).format('YYYY-MM-DD')] : null;

export const stringToMomentWithoutFormat = rangeStrings =>
  rangeStrings ? [moment(rangeStrings[0]), moment(rangeStrings[1])] : null;
