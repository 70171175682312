import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import SideBar from './SideBar/reducers';
import Dashboard from './Dashboard/reducers';
import Integrations from './Integrations/reducers';
import Aarya from './AskAarya/reducers';
import ChartBuilder from './Explore/reducers';
import Segment from './Segment/reducers';
import User from './User/reducers';
import Recommendations from './RecommendationsThunk/reducer';
import Insights from './InsightThunk/insight.reducer';
import Report from './Report/report.reducer';
import ShareLink from './ShareLink/reducer';
import mobileDashboard from './DashboardMobile/reducer';
import elasticReducer from './ElasticSearch/elasticSearch.reducer';
import spotlightDashboard from './SpotlightDashboard/SpotlightDashboard.reducer';
import attribution from './Attribution/Attribution.reducer';
import chart from './Chart/chart.reducer';
import notifications from './NotificationsSaga/reducers';
import configs from './Config/reducers';

const InsightsRecommendations = combineReducers({
  Insights,
  Recommendations
});

export default history =>
  combineReducers({
    router: connectRouter(history),
    SideBar,
    Dashboard,
    Integrations,
    Aarya,
    Segment,
    User,
    ChartBuilder,
    InsightsRecommendations,
    ShareLink,
    mobileDashboard,
    Report,
    elasticReducer,
    spotlightDashboard,
    attribution,
    notifications,
    configs,
    chart
  });
