import { chartConstants } from './chart.constants';

const initialState = {
  metrics: {},
  dimensions: {},
  mapping: {}
};

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case chartConstants.GET_MAPPING_REQUEST:
      return {
        ...state,
        mapping: {
          ...state.mapping,
          loading: true,
          error: false
        }
      };

    case chartConstants.GET_MAPPING_SUCCESS:
      return {
        ...state,
        mapping: {
          ...action.payload,
          loading: false,
          error: false
        }
      };

    case chartConstants.GET_MAPPING_FAILED:
      return {
        ...state,
        mapping: {
          ...action.payload,
          loading: false,
          error: true
        }
      };

    // --- Metrics
    case chartConstants.GET_METRICS_REQUEST:
      return {
        ...state,
        metrics: {
          ...state.mapping,
          loading: true,
          error: false
        }
      };

    case chartConstants.GET_METRICS_SUCCESS:
      return {
        ...state,
        metrics: {
          ...action.payload,
          loading: false,
          error: false
        }
      };

    case chartConstants.GET_METRICS_FAILED:
      return {
        ...state,
        metrics: {
          ...action.payload,
          loading: false,
          error: true
        }
      };

    // --- Dimension
    case chartConstants.GET_DIMENSION_REQUEST:
      return {
        ...state,
        dimensions: {
          ...state.mapping,
          loading: true,
          error: false
        }
      };

    case chartConstants.GET_DIMENSION_SUCCESS:
      return {
        ...state,
        dimensions: {
          ...action.payload,
          loading: false,
          error: false
        }
      };

    case chartConstants.GET_DIMENSION_FAILED:
      return {
        ...state,
        dimensions: {
          ...action.payload,
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
};

export default chartReducer;
