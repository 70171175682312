export const chartConstants = {
  GET_MAPPING_REQUEST: 'CHART/GET_MAPPING_REQUEST',
  GET_MAPPING_SUCCESS: 'CHART/GET_MAPPING_SUCCESS',
  GET_MAPPING_ERROR: 'CHART/GET_MAPPING_ERROR',

  GET_METRICS_REQUEST: 'CHART/GET_METRICS_REQUEST',
  GET_METRICS_SUCCESS: 'CHART/GET_METRICS_SUCCESS',
  GET_METRICS_ERROR: 'CHART/GET_METRICS_ERROR',

  GET_DIMENSION_REQUEST: 'CHART/GET_DIMENSION_REQUEST',
  GET_DIMENSION_SUCCESS: 'CHART/GET_DIMENSION_SUCCESS',
  GET_DIMENSION_ERROR: 'CHART/GET_DIMENSION_ERROR'
};
