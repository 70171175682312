import { insightsConstants } from './insight.constants';

const initialState = {
  insights_list: {},
  selected_insight: {},
  selected_insight_comments: {},
  mapped_recommendation: {}
};

const insights = (state = initialState, action) => {
  switch (action.type) {
    // --- Get Insights
    case insightsConstants.GET_INSIGHTS_REQUEST:
      return { ...state, insights_list: { ...state.insights_list, insight_fetching_status: 'fetching' } };
    case insightsConstants.GET_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights_list: { ...state.insights_list, insight_fetching_status: 'success', ...action.payload }
      };
    case insightsConstants.GET_INSIGHTS_ERROR:
      return {
        ...state,
        insights_list: { ...state.insights_list, insight_fetching_status: 'fail' }
      };

    // --- Get Insights by ID
    case insightsConstants.GET_INSIGHT_BY_ID_REQUEST:
      return {
        ...state,
        selected_insight: { ...state.selected_insight, insight_details_fetching_status: 'fetching', ...action.payload }
      };
    case insightsConstants.GET_INSIGHT_BY_ID_SUCCESS:
      return {
        ...state,
        selected_insight: { ...state.selected_insight, insight_details_fetching_status: 'success', ...action.payload }
      };
    case insightsConstants.GET_INSIGHT_BY_ID_ERROR:
      return { ...state, selected_insight: { insight_details_fetching_status: 'fail' } };

    // --- Read Insight
    case insightsConstants.READ_INSIGHT_BY_ID_REQUEST:
      return {
        ...state,
        selected_insight: { ...state.selected_insight, insight_read_status: 'fetching' }
      };
    case insightsConstants.READ_INSIGHT_BY_ID_SUCCESS:
      return {
        ...state,
        selected_insight: { ...state.selected_insight, insight_read_status: 'success', ...action.payload }
      };
    case insightsConstants.READ_INSIGHT_BY_ID_ERROR:
      return {
        ...state,
        selected_insight: { ...state.selected_insight, insight_read_status: 'fail' }
      };

    // --- Get Insight comments
    case insightsConstants.GET_INSIGHT_COMMENTS_REQUEST:
      return {
        ...state,
        selected_insight_comments: { ...state.selected_insight_comments, comment_fetching_status: 'fetching' }
      };
    case insightsConstants.GET_INSIGHT_COMMENTS_SUCCESS:
      return {
        ...state,
        selected_insight_comments: {
          ...state.selected_insight_comments,
          comment_fetching_status: 'success',
          ...action.payload
        }
      };
    case insightsConstants.GET_INSIGHT_COMMENTS_ERROR:
      return {
        ...state,
        selected_insight_comments: {
          ...state.selected_insight_comments,
          comment_fetching_status: 'fail'
        }
      };

    // --- Post Insight comment
    case insightsConstants.POST_INSIGHT_COMMENTS_REQUEST:
      return {
        ...state,
        selected_insight_comments: {
          ...state.selected_insight_comments,
          insight_comment_post_status: 'fetching',
          comments: [...state.selected_insight_comments.comments]
        }
      };
    case insightsConstants.POST_INSIGHT_COMMENTS_SUCCESS:
      return {
        ...state,
        selected_insight_comments: {
          ...state.selected_insight_comments,
          insight_comment_post_status: 'success',
          comments: [action.payload.data, ...state.selected_insight_comments.comments]
        }
      };
    case insightsConstants.POST_INSIGHT_COMMENTS_ERROR:
      return {
        ...state,
        selected_insight_comments: {
          ...state.selected_insight_comments,
          insight_comment_post_status: 'fail',
          comments: [...state.selected_insight_comments.comments]
        }
      };

    // --- Get mapped recommendation Insights
    case insightsConstants.GET_MAPPED_RECOMMENDATION_REQUEST:
      return {
        ...state,
        mapped_recommendation: {
          ...state.mapped_recommendation,
          mapped_recomm_fetching_status: 'fetching'
        }
      };
    case insightsConstants.GET_MAPPED_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        mapped_recommendation: {
          ...state.mapped_recommendation,
          mapped_recomm_fetching_status: 'success',
          ...action.payload
        }
      };
    case insightsConstants.GET_MAPPED_RECOMMENDATION_ERROR:
      return {
        ...state,
        mapped_recommendation: {
          ...state.mapped_recommendation,
          mapped_recomm_fetching_status: 'fail'
        }
      };

    // --- Clear Insight details on unload component
    case insightsConstants.CLEAR_SELECTED_INSIGHT:
      return {
        ...state,
        selected_insight: {},
        mapped_recommendation: {},
        selected_insight_comments: {},
        insights_list: {}
      };
    default:
      return state;
  }
};

export default insights;
