import { ShareLinkConstants } from './constants';

const initialState = {
  verification: {},
  urlParameter: {}
};

const ShareLink = (state = initialState, action) => {
  switch (action.type) {
    // --- Get Token
    case ShareLinkConstants.GET_SHARETOKEN_REQUEST:
      return {
        ...state,
        urlParameter: { ...state.insights_list, data_fetching_status: 'fetching' }
      };
    case ShareLinkConstants.GET_SHARETOKEN_SUCCESS:
      return {
        ...state,
        urlParameter: {
          ...state.urlParameter,
          token: action.payload,
          data_fetching_status: 'success'
        }
      };
    case ShareLinkConstants.GET_SHARETOKEN_ERROR:
      return {
        ...state,
        urlParameter: { ...state.urlParameter, data_fetching_status: 'fail' }
      };

    // --- Verify Token
    case ShareLinkConstants.GET_TOKEN_VERIFICATION_REQUEST:
      return {
        ...state,
        verification: { ...state.insights_list, data_fetching_status: 'fetching' }
      };
    case ShareLinkConstants.GET_TOKEN_VERIFICATION_SUCCESS:
      return {
        ...state,
        verification: {
          ...state.verification,
          ...action.payload,
          data_fetching_status: 'success'
        }
      };
    case ShareLinkConstants.GET_TOKEN_VERIFICATION_ERROR:
      return {
        ...state,
        verification: { ...state.verification, data_fetching_status: 'fail' }
      };

    // --- clear Token
    case ShareLinkConstants.CLEAR_TOKEN_DATA:
      return {
        ...state,
        verification: {},
        urlParameter: {}
      };

    default:
      return state;
  }
};

export default ShareLink;
