import Api from '../api';

export default class Notifications extends Api {
  getNotificationData = async () => {
    try {
      var { data } = await this.get('/');
      return data;
    } catch (error) {
      return error;
    }
  };

  updateNotificationData = async (id, payload) => {
    try {
      const { data } = await this.post(`/${id}/action/`, { ...payload });
      return data;
    } catch (error) {
      return error;
    }
  };
}
