import React, { Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import AppLayout from './App';
import ComponentLayout from './Component';
import AuthLayout from './Auth';
import Explore from './Explore';
import Diligence from './Diligence';
import DueDiligence from 'pages/DueDiligence';

const Layouts = {
  app: AppLayout,
  component: ComponentLayout,
  auth: AuthLayout,
  explore: Explore,
  diligence: Diligence
};

var mapStateToProps = ({ User }) => ({
  user: User,
  is_due_deligenced_store: User?.userData?.organisationSet?.is_due_dilligence_store
});
@withRouter
@connect(mapStateToProps)
class Layout extends React.PureComponent {
  previousPath = '';

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { prevLocation } = prevProps;
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
      location: { pathname },
      user,
      is_due_deligenced_store
    } = this.props;

    // Layout Rendering
    const getLayout = () => {
      if (
        /^\/dashboard(?=\/|$)/i.test(pathname) ||
        /^\/data_sources(?=\/|$)/i.test(pathname) ||
        /^\/segments(?=\/|$)/i.test(pathname) ||
        /^\/explore(?=\/|$)/i.test(pathname) ||
        /^\/recommendations(?=\/|$)/i.test(pathname) ||
        /^\/insights(?=\/|$)/i.test(pathname) ||
        /^\/help(?=\/|$)/i.test(pathname) ||
        /^\/report(?=\/|$)/i.test(pathname) ||
        /^\/error(?=\/|$)/i.test(pathname) ||
        /^\/spotlight(?=\/|$)/i.test(pathname) ||
        /^\/business-overview(?=\/|$)/i.test(pathname) ||
        /^\/attribution(?=\/|$)/i.test(pathname) ||
        /^\/chart(?=\/|$)/i.test(pathname) ||
        /^\/chartbuilder(?=\/|$)/i.test(pathname) ||
        /^\/competitive-intelligence(?=\/|$)/i.test(pathname)
      ) {
        return 'component';
      }
      if (/^\/system(?=\/|$)/i.test(pathname)) {
        return 'auth';
      }
      if (/^\/onboarding(?=\/|$)/i.test(pathname)) {
        return 'duediligence';
      }
      return 'app';
    };

    const Container = !is_due_deligenced_store ? Layouts[getLayout()] : Layouts['diligence'];
    const isUserAuthorized = user.authorized;
    const isUserLoading = user.loading;
    const isAuthLayout = getLayout() === 'auth';
    const isDueDiligence = getLayout() === 'duediligence';

    const params = window.location.search;

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
        return <div className='shop__initial__loading' />;
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isAuthLayout && !isUserAuthorized) {
        if (!isDueDiligence) {
          return (
            <Redirect
              to={{
                pathname: '/system/login',
                search: params
              }}
            />
          );
        }
        return <DueDiligence />;
      }

      if (isDueDiligence) {
        return (
          <Redirect
            to={{
              pathname: '/dashboard'
            }}
          />
        );
      }

      // in other case render previously set layout
      return <Container data={this.props}>{children}</Container>;
    };

    return (
      <Fragment>
        <Helmet titleTemplate='graas | %s' title='Home' />
        {BootstrappedLayout()}
      </Fragment>
    );
  }
}

export default Layout;
