import { elasticSearchConstants } from './elasticSearch.constants';

const initialState = {
  appliedFilters: {
    metric_label: [],
    dimension_label: [],
    insight_date: [],
    is_read_user: [],
    priority: [],
    time_comparison_category: [],
    search: '',
    page: 1,
    tags: [],
    rule_type: [],
    group: []
  },
  insights: {
    loading: false,
    error: false
  },
  configs: {
    loading: false,
    error: false,
    pageSize: 0
  },
  navigator: {
    loading: false,
    error: false,
    is_navigator_visible: false
  },
  preferences: {
    loading: false,
    error: false
  },
  loading: false,
  error: false
};

const appliedFiltersReducer = (state = initialState.appliedFilters, action) => {
  if (action.filterType === elasticSearchConstants.RESET_FILTERS_INSIGHTS) {
    return initialState.appliedFilters;
  }

  return { ...state, [action.filterType]: action.payload };
};

const elasticInsightReducer = (state = initialState, action) => {
  switch (action.type) {
    case elasticSearchConstants.APPLIED_FILTERS_INSIGHTS:
      const appliedFilters = appliedFiltersReducer(state.appliedFilters, action);
      return {
        ...state,
        appliedFilters
      };
    case 'GET_ES_MAIN_REQUEST':
      return {
        ...state,
        loading: true,
        error: false
      };

    case 'GET_ES_INSIGHTS_REQUEST':
      return {
        ...state,
        insights: {
          ...state.insights,
          loading: true,
          error: false
        }
      };

    case 'GET_ES_INSIGHTS_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        insights: {
          ...action.payload,
          loading: false,
          error: false
        }
      };

    case 'GET_ES_INSIGHTS_FAILED':
      return {
        ...state,
        loading: false,
        error: true,
        insights: {
          ...action.payload,
          loading: false,
          error: true
        }
      };

    case 'SET_APPLIED_MULTIFILTER': {
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload
        }
      };
    }

    case 'GET_INSIGHT_NAVIGATOR_REQUEST': {
      return {
        ...state,
        configs: {
          ...state.navigator,
          loading: true,
          error: false
        }
      };
    }

    case 'GET_INSIGHT_NAVIGATOR_SUCCESS': {
      return {
        ...state,
        navigator: {
          ...state.navigator,
          loading: false,
          error: false,
          question_list: action.payload
        }
      };
    }

    case 'GET_INSIGHT_NAVIGATOR_FAILED': {
      return {
        ...state,
        navigator: {
          ...state.navigator,
          loading: false,
          error: true
        }
      };
    }

    case 'SET_NAVIGATOR_VISIBLE_SUCCESS': {
      return {
        ...state,
        navigator: {
          ...state.navigator,
          is_navigator_visible: action.payload
        }
      };
    }

    case 'GET_NAVIGATOR_PREFERENCES_REQUEST': {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          loading: true,
          error: false
        }
      };
    }
    case 'GET_NAVIGATOR_PREFERENCES_SUCCESS': {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
          loading: false,
          error: false
        }
      };
    }
    case 'GET_NAVIGATOR_PREFERENCES_ERROR': {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          loading: false,
          error: true
        }
      };
    }
    default:
      return state;
  }
};

export default elasticInsightReducer;
