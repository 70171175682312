import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Switch from 'react-router-transition-switch';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import Layout from 'layouts';
import ErrorPage from 'pages/System/404';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <div className='shop__initial__loading' />
  });

const routes = [
  {
    path: '/ask_aarya',
    Component: loadable(() => import('pages/AskAarya/')),
    exact: true
  },
  // Dashboards
  {
    path: '/dashboard/add',
    Component: loadable(() => import('pages/Dashboard/add')),
    exact: true
  },
  {
    path: '/dashboard/create',
    Component: loadable(() => import('pages/Dashboard/create')),
    exact: true
  },
  {
    path: '/dashboard/:id',
    Component: loadable(() => import('pages/Dashboard')),
    exact: true
  },
  {
    path: '/dashboard',
    Component: loadable(() => import('pages/ChartListing/ChartListing')),
    exact: true
  },
  {
    path: '/competitive-intelligence',
    Component: loadable(() => import('pages/SiaCIListing/SiaCIListing')),
    exact: true
  },
  {
    path: '/business-overview',
    Component: loadable(() => import('pages/ChartListing/ChartListing')),
    exact: true
  },
  // Integrations
  {
    path: '/data_sources',
    Component: loadable(() => import('pages/Integrations')),
    exact: true
  },
  {
    path: '/data_sources/add',
    Component: loadable(() => import('pages/Integrations/add')),
    exact: true
  },
  {
    path: '/data_sources/:id',
    Component: loadable(() => import('pages/Integrations')),
    exact: true
  },
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/System/login')),
    exact: true
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/System/forgot-password')),
    exact: true
  },
  {
    path: '/system/signup',
    Component: loadable(() => import('pages/SignUp/SignUp')),
    exact: true
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/System/register')),
    exact: true
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/System/404')),
    exact: true
  },
  {
    path: '/error',
    Component: loadable(() => import('./pages/Error/Error')),
    exact: true
  },
  {
    path: '/settings',
    Component: loadable(() => import('pages/System/settings')),
    exact: true
  },
  // oauth callbacks
  {
    path: '/callback',
    Component: loadable(() => import('pages/System/callback')),
    exact: false
  },
  //User Analysis
  {
    path: '/explore/user_analysis',
    Component: loadable(() => import('pages/UserAnalysis')),
    exact: true
  },
  //Explore
  {
    path: '/explore/chart_builder',
    Component: loadable(() => import('pages/ChartBuilder')),
    exact: true
  },
  {
    path: '/explore',
    Component: loadable(() => import('pages/ChartBuilder')),
    exact: true
  },
  //Segments
  {
    path: '/segments',
    Component: loadable(() => import('pages/Segments')),
    exact: true
  },
  {
    path: '/segments/add',
    Component: loadable(() => import('pages/Segments/add')),
    exact: true
  },

  // HelpSection
  {
    path: '/help',
    Component: loadable(() => import('pages/HelpSection/HelpSection')),
    exact: true
  },

  //  insight
  {
    path: '/insights',
    Component: loadable(() => import('pages/InsightsContainer')),
    exact: true
  },

  //  insight detail
  {
    path: '/insights/:id',
    // TODO: remove that folder nesting and directly place insightDetail component under src/insightDetail
    // TODO: remove this component import and create a InsightDetail PAGE inside pages folder, similar as other pages i.e. GrowthAdvisory, ReportDetail etc.
    Component: loadable(() => import('./components/ElasticInsightDetailsWrapper/ElasticInsightDetailsWrapper')),
    exact: true
  },

  //  recommendation
  {
    path: '/recommendations',
    Component: loadable(() => import('pages/RecommendationContainer')),
    exact: true
  },
  //  recommendation
  // TODO: remove this component import and create a RecommendationDetail PAGE inside pages folder, similar as other pages i.e. GrowthAdvisory, ReportDetail etc.
  // TODO: Folder structure will be same as Insight Detail Page. RecDetailPage -> <Header/> and <RecoDetail/> components and this page will be responsible for all API calls and
  // TODO: Reco detail will be completely props driven same as Insight Detail
  {
    path: '/recommendations/:id',
    Component: loadable(() => import('./components/RecommendationDetailWrapper/RecommendationDetailwrapper')),
    exact: true
  },
  //  recommendation
  {
    path: '/dashboardsummary',
    Component: loadable(() => import('pages/DashboardMobile/DashboardMobile')),
    exact: true
  },
  {
    path: '/report',
    Component: loadable(() => import('pages/Report/Report')),
    exact: true
  },
  {
    path: '/report/:id',
    Component: loadable(() => import('components/ReportDetail/ReportDetail')),
    exact: true
  },
  {
    path: '/spotlight',
    Component: loadable(() => import('pages/FocusedInsightsContainer')),
    exact: true
  },
  {
    path: '/attribution',
    Component: loadable(() => import('pages/Attribution/Attribution')),
    exact: true
  }
  // {
  //   path: '/chartbuilder',
  //   Component: loadable(() => import('pages/ChartListing/ChartBuilderPOC')),
  //   exact: true
  // }
];

@connect()
class Router extends React.Component {
  render() {
    const { history } = this.props;
    const params = window.location.search;

    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname }
              } = props;
              return <div key={pathname}>{children}</div>;
            }}
          >
            <Route
              exact
              path='/'
              render={() => (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                    search: params
                  }}
                />
              )}
            />
            <Route exact path='/explore' render={() => <Redirect to='/explore/chart_builder' />} />

            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact} component={Component} />
            ))}
            <Route
              component={() => (
                <ErrorPage
                  errorCode='404'
                  errorTitle='Page Not Found'
                  errorMessage='Request for the access or <a href="https://growth.shoptimize.ai/" class="c-button is-link"> click here</a> to go back on the Growth platform'
                />
              )}
            />
          </Switch>
        </Layout>
      </ConnectedRouter>
    );
  }
}

export default Router;
