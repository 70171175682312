import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/graas_blue.svg';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className='c-footer'>
      <span className='footer_text'>Powered by</span>
      <Link to={{ pathname: 'https://growth.graas.ai/' }} target='_blank'>
        <img src={Logo} alt='graas' className='footer_img' />
      </Link>
    </footer>
  );
};

export default Footer;
