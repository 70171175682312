import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import { track, actions as TRACKER_ACTION } from 'redux/tracker';
import actions from './actions';
import menuAction from 'redux/SideBar/actions';
import authActions from 'redux/User/actions';
import Api from './api';
import { handleIntegrationError, actions as utilAction } from 'redux/utils/handleGenericError';
import { codeToKeyMap } from 'utilities/utilities_exports';

const integration = new Api('polaris/integration');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getBaseVisualisations() {
  try {
    var baseVisualisations = yield call(integration.getBaseVisualisations);
    yield put({
      type: actions.SET_STATE,
      payload: {
        baseVisualisations
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getAvailableIntegrations() {
  try {
    var availableIntegrations = yield call(integration.getAvailableIntegrations);
    yield put({
      type: actions.SET_STATE,
      payload: {
        availableIntegrations
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getVisualisations(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    var { urlKey, trackerPayload } = action.payload;
    yield put({
      type: actions.SET_STATE,
      payload: {
        availableVisualisation: [],
        accounts: []
      }
    });
    var data = yield call(integration.getVisualisations, urlKey);
    yield put({
      type: actions.SET_STATE,
      payload: {
        availableVisualisation: integration.formatVisualisationResponse(data),
        accounts: integration.getAccounts(data)
      }
    });
    yield put({
      type: actions.TRACK,
      payload: {
        event: TRACKER_ACTION.integrationMetricsView,
        integrationData: { ...data, trackerPayload }
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* addIntegration(action) {
  var { type, postData } = action.payload;
  yield put({
    type: actions.TOGGLE_LOADING
  });
  yield put({
    type: actions.TRACK,
    payload: {
      event: TRACKER_ACTION.integrationFormSubmit,
      integrationData: {
        integration_type_set: {
          code: type,
          id: postData[0].integration_type_id
        },
        name: postData[0].name
      }
    }
  });
  try {
    var data = yield call(integration.addIntegration, postData, type);
    yield all(
      data.map(integration =>
        put({
          type: actions.TRACK,
          payload: {
            event: TRACKER_ACTION.integrationAdd,
            integrationData: integration
          }
        })
      )
    );
    yield put({
      type: menuAction.UPDATE_MENU
    });
  } catch (error) {
    const newAction = { ...action, payload: { ...action.payload, error } };
    yield call(handleGenericError, newAction);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function trackIntegration(action) {
  var { event, integrationData } = action.payload;
  var trackerData = integration.formatTrackerData(integrationData);
  track(event, trackerData);
}

export function handleGenericError(action) {
  handleIntegrationError(action);
}

export function* getConnectedAccountsLastLogStatus(action) {
  const {
    payload: { integrationCode, connectedAccounts }
  } = action;
  const newConnectedAccounts = [...connectedAccounts];

  for (const account of connectedAccounts) {
    try {
      const data = yield call(integration.getConnectedAccountLogStatus, codeToKeyMap[integrationCode], account.id);
      const foundIndex = newConnectedAccounts.findIndex(currAccount => currAccount.id === data.integration_id);
      if (foundIndex !== -1)
        newConnectedAccounts[foundIndex] = { ...newConnectedAccounts[foundIndex], logStatus: { ...data } };
    } catch (error) {
      const newAction = { ...action, payload: { ...action.payload, error } };
      yield call(handleGenericError, newAction);
    }
  }
  yield put({ type: actions.CONNECTED_ACCOUNTS, payload: newConnectedAccounts });
}

export function* cleanConnectedAccounts(action) {
  const { payload } = action;
  yield put({ type: actions.CLEAN_CONNECTED_ACCOUNTS, payload });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_BASE_VISUALISATIONS, getBaseVisualisations),
    takeEvery(actions.GET_BASE_INTEGRATIONS, getAvailableIntegrations),
    takeEvery(actions.GET_VISUALISATIONS, getVisualisations),
    takeEvery(actions.ADD_INTEGRATION, addIntegration),
    takeEvery(actions.TRACK, trackIntegration),
    takeEvery(utilAction.HANDLE_GENERIC_ERROR, handleGenericError),
    takeEvery(actions.GET_CONNECTED_ACCOUNTS, getConnectedAccountsLastLogStatus),
    takeEvery(actions.SET_STATE, cleanConnectedAccounts)
  ]);
}
