import { ValidationError } from 'redux/utils/handleGenericError';
import Api from '../api';

export default class Integrations extends Api {
  getBaseVisualisations = () =>
    new Promise(async (resolve, reject) => {
      try {
        console.log('revert the end point before push');
        var { data } = await this.get(`/visualisations/`);
        var baseVisualisations = this.formatBaseVisualisationResponse(data);
        resolve(baseVisualisations);
      } catch (error) {
        reject(error);
      }
    });

  getAvailableIntegrations = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/type-category-mapping/`);
        var availableIntegrations = this.formatIntegrationsResponse(data);
        resolve(availableIntegrations);
      } catch (error) {
        reject(error);
      }
    });

  getVisualisations = urlKey =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/${urlKey}/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  /**
   * TODO : Make More generic by making map.
   */
  formatBaseVisualisationResponse(visualisations) {
    return visualisations.map(visualisation => {
      var visType = {
        title: visualisation.name,
        id: visualisation.id,
        key: visualisation.code,
        type: visualisation.type,
        query: visualisation.query
      };
      var visList = [];
      if (visualisation.base_visualisation_set && visualisation.base_visualisation_set.length) {
        visList = visualisation.base_visualisation_set.map(vis => {
          return {
            title: vis.name,
            id: vis.id,
            key: vis.code,
            type: vis.type,
            query: vis.query
          };
        });
      }
      visType.children = visList;
      return visType;
    });
  }

  formatVisualisationResponse(visualisation) {
    return visualisation.integration_type_set.base_visualisation_set.map(vis => {
      return {
        id: vis.id,
        name: vis.name,
        description: vis.description
      };
    });
  }

  formatIntegrationsResponse(integrations) {
    return integrations.map(int => {
      return int;
    });
  }

  getAccounts(data) {
    return [
      {
        name: data.name,
        id: data.id,
        tracking_id: data.tracking_id ? data.tracking_id : null
      }
    ];
  }

  formatTrackerData(data) {
    return {
      ...data?.trackerPayload,
      id: data.id ? data.id : null,
      name: data.name ? data.name : null,
      code: data?.meta?.integration_type_set?.code,
      integration_type_id: data?.meta?.integration_type_set?.id
    };
  }

  addIntegration = async (postData, type) => {
    const path = {
      fbads: 'facebook-ads',
      gads: 'google-ads',
      gsc: 'google-search-console',
      ga: 'google-analytics',
      mg: 'shoptimize-platform',
      shp: 'shoptimize-tracker',
      webt: 'webpage-tester',
      sg: 'sendgrid',
      dlh: 'delhivery',
      shf: 'shopify',
      amz: 'amazon',
      mlc: 'mailchimp',
      lz: 'lazada',
      shipr: 'shiprocket',
      wcom: 'wcom',
      lighthouse: 'lighthouse',
      unicom: 'unicom',
      smr: 'semrush'
    };
    const { data } = await this.post(`${path[type]}/`, postData);
    if (data?.error) throw new ValidationError(data.error.message, data.error.description);
    return data;
  };

  handleBackendErrorResponse = data => {
    throw new ValidationError(data.error.message, data.error.description);
  };

  /**
   * Oauth Funcitons
   */

  getAuthUrl = async (key, params = null) => {
    const { data } = await this.get(`/${key}/oauth/`, { params });
    if (data?.error) throw new ValidationError(data.error.message, data.error.description);
    return data;
  };

  getAuthUrlShopify = (key, values) =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/${key}/oauth/`, values);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  getGaAccounts = async (key, code) => {
    const { data } = await this.post(`/${key}/oauth/list/`, { code });
    if (data?.error) this.handleBackendErrorResponse(data);
    return this.formatGaAccountDetails(data);
  };

  getGscAccounts = async (key, code) => {
    const { data } = await this.post(`/${key}/oauth/list/`, { code });
    if (data?.error) this.handleBackendErrorResponse(data);
    return this.formatGscAccountDetails(data);
  };

  getUserAccounts = async (key, code) => {
    const { data } = await this.post(`/${key}/oauth/list/`, { code });
    if (data?.error) this.handleBackendErrorResponse(data);
    return this.formatAccountDetails(data);
  };

  formatGaAccountDetails = data => {
    var accounts = {};

    data.accounts.forEach(account => {
      if (!(account.property_name in accounts)) {
        accounts[account.property_name] = [];
      }
      accounts[account.property_name].push(account);
    });
    return {
      accounts,
      credentials_file_name: data.pass_on_params.credentials_file_name,
      accounts_raw: data.accounts
    };
  };

  formatGscAccountDetails = data => {
    return {
      credentials_file_name: data.pass_on_params.credentials_file_name,
      accounts: data.accounts
    };
  };

  formatAccountDetails = data => {
    var accounts = {};

    data.accounts.forEach(account => {
      if (!(account.mcc_name in accounts)) {
        accounts[account.mcc_name] = [];
      }
      accounts[account.mcc_name].push(account);
    });
    if (accounts.hasOwnProperty('null')) {
      accounts['Other'] = accounts['null'];
      delete accounts['null'];
    }
    return {
      accounts,
      credentials_file_name: data.pass_on_params.credentials_file_name,
      accounts_raw: data.accounts
    };
  };

  getConnectedAccountLogStatus = async (integration_code, id) => {
    const { data } = await this.get(`logging/${integration_code}/?integration_id=${id}`);
    if (data?.error) this.handleBackendErrorResponse(data);
    return data;
  };
}
