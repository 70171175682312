export const recommendationsConstants = {
  GET_RECOMMENDATIONS_REQUEST: 'Recommendations/GET_RECOMMENDATIONS_REQUEST',
  GET_RECOMMENDATIONS_SUCCESS: 'Recommendations/GET_RECOMMENDATIONS_SUCCESS',
  GET_RECOMMENDATIONS_ERROR: 'Recommendations/GET_RECOMMENDATIONS_ERROR',

  GET_RECOMMENDATION_BY_ID_REQUEST: 'Recommendations/GET_RECOMMENDATION_BY_ID_REQUEST',
  GET_RECOMMENDATION_BY_ID_SUCCESS: 'Recommendations/GET_RECOMMENDATION_BY_ID_SUCCESS',
  GET_RECOMMENDATION_BY_ID_ERROR: 'Recommendations/GET_RECOMMENDATION_BY_ID_ERROR',

  GET_RECOMMENDATION_COMMENTS_REQUEST: 'Recommendations/GET_RECOMMENDATION_COMMENTS_REQUEST',
  GET_RECOMMENDATION_COMMENTS_SUCCESS: 'Recommendations/GET_RECOMMENDATION_COMMENTS_SUCCESS',
  GET_RECOMMENDATION_COMMENTS_ERROR: 'Recommendations/GET_RECOMMENDATION_COMMENTS_ERROR',

  POST_RECOMMENDATION_COMMENTS_REQUEST: 'Recommendations/POST_RECOMMENDATION_COMMENTS_REQUEST',
  POST_RECOMMENDATION_COMMENTS_SUCCESS: 'Recommendations/POST_RECOMMENDATION_COMMENTS_SUCCESS',
  POST_RECOMMENDATION_COMMENTS_ERROR: 'Recommendations/POST_RECOMMENDATION_COMMENTS_ERROR',

  READ_RECOMMENDATION_BY_ID_REQUEST: 'Recommendations/READ_RECOMMENDATION_BY_ID_REQUEST',
  READ_RECOMMENDATION_BY_ID_SUCCESS: 'Recommendations/READ_RECOMMENDATION_BY_ID_SUCCESS',
  READ_RECOMMENDATION_BY_ID_ERROR: 'Recommendations/READ_RECOMMENDATION_BY_ID_ERROR',

  GET_MAPPED_RECOMMENDATION_REQUEST: 'Recommendations/GET_MAPPED_RECOMMENDATION_REQUEST',
  GET_MAPPED_RECOMMENDATION_SUCCESS: 'Recommendations/GET_MAPPED_RECOMMENDATION_SUCCESS',
  GET_MAPPED_RECOMMENDATION_ERROR: 'Recommendations/GET_MAPPED_RECOMMENDATION_ERROR',

  CLEAR_SELECTED_RECOMMENDATION: 'Recommendations/CLEAR_SELECTED_RECOMMENDATION',

  GET_ASSIGNING_USERS_REQUEST: 'Recommendations/GET_ASSIGNING_USERS_REQUEST',
  GET_ASSIGNING_USERS_SUCCESS: 'Recommendations/GET_ASSIGNING_USERS_SUCCESS',
  GET_ASSIGNING_USERS_ERROR: 'Recommendations/GET_ASSIGNING_USERS_ERROR',

  UPDATE_ASSIGNING_USERS_REQUEST: 'Recommendations/UPDATE_ASSIGNING_USERS_REQUEST',
  UPDATE_ASSIGNING_USERS_SUCCESS: 'Recommendations/UPDATE_ASSIGNING_USERS_SUCCESS',
  UPDATE_ASSIGNING_USERS_ERROR: 'Recommendations/UPDATE_ASSIGNING_USERS_ERROR',

  HANDLE_RECOMMENDATION_STATUS_REQUEST: 'Recommendations/HANDLE_RECOMMENDATION_STATUS_REQUEST',
  HANDLE_RECOMMENDATION_STATUS_SUCCESS: 'Recommendations/HANDLE_RECOMMENDATION_STATUS_SUCCESS',
  HANDLE_RECOMMENDATION_STATUS_ERROR: 'Recommendations/HANDLE_RECOMMENDATION_STATUS_ERROR',

  GET_MAPPED_INSIGHTS_REQUEST: 'Insights/GET_MAPPED_INSIGHTS_REQUEST',
  GET_MAPPED_INSIGHTS_SUCCESS: 'Insights/GET_MAPPED_INSIGHTS_SUCCESS',
  GET_MAPPED_INSIGHTS_ERROR: 'Insights/GET_MAPPED_INSIGHTS_ERROR',

  GET_METRIC_LIST_REQUEST: 'Recommendations/GET_METRIC_LIST_REQUEST',
  GET_METRIC_LIST_SUCCESS: 'Recommendations/GET_METRIC_LIST_SUCCESS',
  GET_METRIC_LIST_ERROR: 'Recommendations/GET_METRIC_LIST_ERROR',

  GET_CHART_MAPPING_DATA_REQUEST: 'Recommendations/GET_CHART_MAPPING_DATA_REQUEST',
  GET_CHART_MAPPING_DATA_SUCCESS: 'Recommendations/GET_CHART_MAPPING_DATA_SUCCESS',
  GET_CHART_MAPPING_DATA_ERROR: 'Recommendations/GET_CHART_MAPPING_DATA_ERROR',

  SET_APPLIED_FILTERS: 'Recommendations/SET_APPLIED_FILTERS'
};
