import Api from '../api';

export default class Sidebar extends Api {
  getMenuData = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get('/');
        var menuData = this.formatMenu(data);        
        resolve(menuData);
      } catch (error) {
        reject(error);
      }
    });

  formatMenu(data) {
    var roots = [];

    data.forEach(item => {
      if (item.parent === null) {
        roots.push(item);
      } else {
        var [parent] = data.filter(i => i.name === item.parent);
        if (!('children' in parent)) {
          parent.children = [];
        }
        parent.children.push(item);
      }
    });

    roots.forEach(item => {
      item.id = item.name.toLowerCase().split(' ').join('_');
      if (item.children) {
        item.children.sort((a, b) => a.position - b.position);
        item.children.forEach(child => {
          child.key = child.id;
          child.id = child.position;
        });
      } else {
        item.children = [];
      }
    });
    roots.sort((a, b) => a.position - b.position);
    return roots;
  }

  formatDetail(menu, key) {
    return menu.filter(menu => menu.id === key)[0].children;
  }
}
