import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const config = new Api('polaris/configs');
const user = new Api('polaris/organisation/user');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getConfigData(action) {
  let apiData = {};

  try {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
        error: false
      }
    });
    const configData = yield call(config.getConfigData);
    if (apiData && apiData.hasError) {
      throw new Error();
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          error: false,
          ...configData
        }
      });
    }
  } catch (error) {
    yield call(handleError, error);
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: true
      }
    });
  }
}

export function* updateConfigData(action) {
  try {
    const configDataResponse = yield call(user.updateConfigData, action.payload);
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        ...configDataResponse
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CONFIG, getConfigData), takeEvery(actions.UPDATE_CONFIG, updateConfigData)]);
}
