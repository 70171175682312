import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const menu = new Api('polaris/sidebar');
const menuDetails = new Api('polaris/sidebar-detail');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* setDetailbarData(action) {
  yield put({
    type: actions.SET_DETAIL,
    payload: {
      detailBar: action.payload.detailBar
    }
  });
}

export function* setSelectedMenu(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      selectedMenuKeys: action.payload.selectedMenuKeys
    }
  });
}

export function* setSelectedDetail(action) {
  yield put({
    type: actions.SET_DETAIL,
    payload: {
      detailBar: action.payload.detailBar
    }
  });
}

export function* getMenu(action) {
  try {
    var menuData = yield call(menu.getMenuData);
    yield put({
      type: actions.SET_STATE,
      payload: {
        menuData
      }
    });
    if (action.actionKey) {
      var detailData = yield call(menu.formatDetail, menuData, action.actionKey);
      yield put({
        type: actions.SET_DETAIL,
        payload: {
          detailBar: {
            items: detailData
          }
        }
      });
    }
  } catch (error) {
    yield call(handleError, error);
  }

  try {
    var menuDataDetails = yield call(menuDetails.getMenuData);
    yield put({
      type: actions.SET_STATE,
      payload: {
        menuDataDetails
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }

}

export function* getItems(action) {
  try {
    var dashboardData_ = yield call(menu.getMenuData);
    var dashboardData = [];
    dashboardData_.map((item, i) => {
      if (item['id'] === 'dashboard') {
        dashboardData.push(item);
      }
    });
    yield put({
      type: actions.SET_STATE,
      payload: {
        dashboardData
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_DETAILBAR_DATA, setDetailbarData),
    takeEvery(actions.SET_SELECTED_MENU, setSelectedMenu),
    takeEvery(actions.SET_SELECTED_DETAIL, setSelectedDetail),
    takeEvery(actions.UPDATE_MENU, getMenu),
    takeEvery(actions.GET_MENU, getItems)
  ]);
}
