// -- routes for mobile
export const mobileRoutes = [
  // {
  //   link: '/spotlight',
  //   route_name: 'Focus Insights',
  //   icon: ''
  // },
  {
    link: '/dashboardsummary',
    route_name: 'Dashboard',
    icon: ''
  },
  {
    link: '/insights',
    route_name: 'Insights',
    icon: ''
  },
  {
    link: '/recommendations',
    route_name: 'Recommendations',
    icon: ''
  }
];
