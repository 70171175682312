import Api from '../api';
import axios from 'axios';
import _ from 'lodash';
export default class Segment extends Api {
  getSegments = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/custom/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  getSegmentCategory = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/category/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  getSegmentMeta = () =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/meta/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  getClauseValues = params =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/clauses/values/`, { params });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  addSegment = payload =>
    new Promise(async (resolve, reject) => {
      try {
        let meta_data = this.formatCreateSegmentMeta(payload.selectedFilters);
        var { data } = await this.post(`/custom/`, {
          title: payload.title,
          description: payload.description,
          meta_data
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  createAudienceGoogle = payload =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/export/google/`, payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  createAudienceMailchimp = payload =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/export/mailchimp/`, payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  createAudienceSendgrid = payload =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/export/sendgrid/`, payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  createAudienceFb = payload =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.post(`/export/facebook/`, payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  deleteSegment = id =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.delete(`/custom/${id}/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  getUserCount = meta =>
    new Promise(async (resolve, reject) => {
      try {
        if (this.cancelSource && this.cancelSource.cancel) {
          this.cancelSource.cancel('Another Request Recieved');
        }

        if (!meta.length) {
          return resolve('All');
        }
        const CancelToken = axios.CancelToken;
        this.cancelSource = CancelToken.source();

        let meta_data = this.formatCreateSegmentMeta(meta);
        var { data } = await this.get(`/stats/`, {
          params: { meta: JSON.stringify(meta_data) },
          cancelToken: this.cancelSource.token
        });
        if (data && data.length) {
          resolve(data[0].count);
        }
        resolve('Error');
      } catch (error) {
        if (error.message === 'Another Request Recieved') {
          resolve('...');
        } else {
          resolve('Error');
        }
      }
    });

  getTotalUsersCount = () =>
    new Promise(async (resolve, reject) => {
      const polarisAPI = new Api('polaris');
      try {
        const query = [
          {
            filters: [],
            measures: ['ShpEvents.uniqueUserCount'],
            timezone: 'Asia/Kolkata',
            timeDimensions: [{ dimension: 'ShpEvents.eventTime' }],
            aggregationTypes: { 'ShpEvents.uniqueUserCount': 'sum' }
          }
        ];
        const { data } = await polarisAPI.get(`/query/data/?query=${JSON.stringify(query)}&format=json`);
        resolve(data.data[0]['ShpEvents.uniqueUserCount']);
      } catch (error) {
        reject(error);
      }
    });

  getSubcategories = () =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.get(`/sub-category`);
        const res = _.keyBy(data, obj => obj.id);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });

  getSegmentOverlap = async selectedRowKeys => {
    try {
      const tokenData = await this.get(`/?seg_ids=[${selectedRowKeys}]`);
      return tokenData;
    } catch (error) {
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  downloadSegmentData = id =>
    new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/download/users/${id}/`);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });

  formatCreateSegmentMeta = selectedFilters => {
    let meta_data = [];
    try {
      meta_data = selectedFilters.map(filter => {
        let { sql_template, operator, values, value_dtype, value_key, code, category } = filter;
        return {
          sql_template,
          operator,
          code,
          value_key,
          category,
          values,
          value_type: value_dtype
        };
      });
    } catch (err) {}
    return meta_data;
  };
}
