import actions from './actions';

const initialState = {
  categories: [],
  meta: [],
  segments: true,
  details: null,
  clauses: null,
  loading: false,
  totalUsers: 0,
  segmentOverlap: {},
  isLoading: true,
  isError: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_SEGMENT_REQUEST:
      return { ...state, isLoading: true, isError: false };
    case actions.GET_SEGMENT_SUCCESS:
      return { ...state, isLoading: false, isError: false };
    case actions.GET_SEGMENT_ERROR:
      return { ...state, isLoading: false, isError: true };
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    case actions.GET_SEGMENT_OVERLAP_FETCHING:
      return {
        ...state,
        segmentOverlap: { data_fetching_status: 'fetching' }
      };
    case actions.GET_SEGMENT_OVERLAP_SUCCESS:
      return {
        ...state,
        segmentOverlap: { ...action.payload, data_fetching_status: 'success' }
      };
    case actions.GET_SEGMENT_OVERLAP_ERROR:
      return {
        ...state,
        segmentOverlap: { data_fetching_status: 'fail' }
      };
    default:
      return state;
  }
}
