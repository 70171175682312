export const reportConstants = {
  GET_REPORT_LIST_REQUEST: 'GET_REPORT_LIST_REQUEST',
  GET_REPORT_LIST_SUCCESS: 'GET_REPORT_LIST_SUCCESS',
  GET_REPORT_LIST_ERROR: 'GET_REPORT_LIST_ERROR',

  GET_REPORT_DETAIL_REQUEST: 'GET_REPORT_DETAIL_REQUEST',
  GET_REPORT_DETAIL_SUCCESS: 'GET_REPORT_DETAIL_SUCCESS',
  GET_REPORT_DETAIL_ERROR: 'GET_REPORT_DETAIL_ERROR',
  SET_REPORT_DETAIL_DATA: 'SET_REPORT_DETAIL_DATA'
};
