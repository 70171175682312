import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const notification = new Api('polaris/notifications/');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getNotification(action) {
  try {
    var notificationData = yield call(notification.getNotificationData);
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: notificationData || []
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* updateNotification(action) {
  var { id, ...rest } = action.payload;
  try {
    const notificationData = yield call(notification.updateNotificationData, id, rest);
    // const notificationData = yield call(notification.getNotificationData);
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: notificationData || []
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NOTIFICATION, getNotification),
    takeEvery(actions.UPDATE_NOTIFICATION, updateNotification)
  ]);
}
