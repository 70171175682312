import { all, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';

import { track, actions as TRACKER_ACTION } from 'redux/tracker';
import actions from './actions';
import menuAction from '../SideBar/actions';
import authActions from 'redux/User/actions';
import Api from './api';
import businessOverviewData from 'components/ChartWidget/utility/BusinessOverviewConfig';

const dashboard = new Api('polaris/dashboard');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* getDashboard(action) {
  yield put({
    type: actions.SET_MODE,
    payload: {
      mode: 'loading'
    }
  });
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    let dashData = {};
    if (action.payload.id === 'business-overview') {
      dashData = businessOverviewData;
      window.open('/dashboard', '_self', '', true);
    } else {
      dashData = yield call(dashboard.getDashboardData, action.payload.id);
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...dashData
      }
    });
    yield put({
      type: actions.TRACK_DASHBOARD,
      payload: {
        event: TRACKER_ACTION.dashboardView,
        dashboardData: dashData
      }
    });
  } catch (error) {
    yield call(handleError, error);
  } finally {
    yield put({
      type: actions.SET_MODE,
      payload: {
        mode: 'view'
      }
    });
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* createDashboard(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    var dash = yield call(dashboard.createDashboard, action.payload);
    yield put({
      type: menuAction.UPDATE_MENU,
      actionKey: 'dashboard'
    });
    yield put({
      type: actions.TRACK_DASHBOARD,
      payload: {
        event: TRACKER_ACTION.dashboardAdd,
        dashboardData: dash
      }
    });
    yield put(push('/dashboard/' + dash.id));
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* createDashboardNew(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    var dash = yield call(dashboard.createDashboardNew, action.payload);
    yield put({
      type: menuAction.UPDATE_MENU,
      actionKey: 'dashboard'
    });
    yield put({
      type: actions.TRACK_DASHBOARD,
      payload: {
        event: TRACKER_ACTION.dashboardAdd,
        dashboardData: dash
      }
    });
    yield put(push('/dashboard/' + dash.id));
    yield window.location.reload();
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* deleteDashboard(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    yield call(dashboard.deleteDashboard, action.payload.id);
    yield put({
      type: menuAction.UPDATE_MENU,
      actionKey: 'dashboard'
    });
    // yield put({
    //   type: actions.TRACK_DASHBOARD,
    //   payload: {
    //     event: TRACKER_ACTION.dashboardRemove,
    //     dashboardData: action.payload.dashboardData
    //   }
    // });
    // yield put({
    //   type: actions.SET_MODE,
    //   payload: {
    //     mode: 'view'
    //   }
    // });
    yield put(push('/dashboard'));
    // yield window.location.reload();
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* updateDashboard(action) {
  yield put({
    type: actions.TOGGLE_LOADING
  });
  var { dashId, name, template, deletedWidgets, createdWidgets, updatedWidgets } = action.payload;
  try {
    var response;
    yield call(dashboard.updateDashboard, { dashId, name, template });
    if (deletedWidgets.length) {
      response = yield call(dashboard.deleteWidgets, { deletedWidgets, dashId });
    }
    if (updatedWidgets.length) {
      response = yield call(dashboard.updateWidgets, { updatedWidgets, dashId });
    }
    if (createdWidgets.length) {
      response = yield call(dashboard.createWidgets, { createdWidgets, dashId });
      response = yield call(dashboard.getDashboardData, dashId);
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...response,
        mode: 'view'
      }
    });
    yield put({
      type: actions.TRACK_DASHBOARD,
      payload: {
        event: TRACKER_ACTION.dashboardPublish,
        dashboardData: response
      }
    });
    yield put({
      type: menuAction.UPDATE_MENU
    });
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
  yield put(push('/dashboard/' + dashId));
}

export function trackDashboard(action) {
  var { event, dashboardData, dateRange, compareRange } = action.payload;
  var trackerData;
  if (dashboardData) {
    trackerData = dashboard.formatDashboardTrack(dashboardData, dateRange, compareRange);
  } else {
    trackerData = null;
  }
  track(event, trackerData);
}

export function trackWidget(action) {
  var { event, widgetData, dashId } = action.payload;
  var trackerData;
  if (widgetData) {
    trackerData = dashboard.formatWidgetTrack(widgetData, dashId);
  } else {
    trackerData = null;
  }
  track(event, trackerData);
}

export function* setState(action) {
  yield put({
    type: actions.SET_STATE,
    payload: action.payload
  });
}

export function* getTemplates() {
  try {
    var { data } = yield call(dashboard.getTemplate);
    yield put({
      type: actions.SET_STATE,
      payload: {
        templates: data
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getSchemaDetails() {
  try {
    var { data } = yield call(dashboard.getSchemaDetails);
    yield put({
      type: actions.SET_STATE,
      payload: {
        schema_details: data
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* submitDiscrepancy(action) {
  try {
    yield call(dashboard.reportDiscrepancy, action.payload);
    yield message.success('Data Discrepancy Reported!');
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* publishChart(action) {
  try {
    var chart = yield call(dashboard.publishChart, action.payload);
    yield message.success('Chart has Been Published.');
  } catch (error) {
    yield call(handleError, error);
  }
}

function* updateDashBoardRedux(payload) {
  yield put({ type: actions.UPDATE_DASHBOARD_IN_REDUX, payload });
}

export function* updateDashboardInRedux(action) {
  yield call(updateDashBoardRedux, action.payload);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TEMPLATES, getTemplates),
    takeEvery(actions.GET_DASHBOARD, getDashboard),
    takeEvery(actions.ADD_DASHBOARD, createDashboard),
    takeEvery(actions.DELETE_DASHBOARD, deleteDashboard),
    takeEvery(actions.UPDATE_DASHBOARD, updateDashboard),
    takeEvery(actions.SET_DATE_RANGE, setState),
    takeEvery(actions.SUBMIT_DISCREPANCY, submitDiscrepancy),
    takeEvery(actions.TRACK_DASHBOARD, trackDashboard),
    takeEvery(actions.PUBLISH_CHART, publishChart),
    takeEvery(actions.GET_SCHEMA_DETAILS, getSchemaDetails),
    takeEvery(actions.TRACK_WIDGET, trackWidget),
    takeEvery(actions.SET_MODE, setState),
    takeEvery(actions.UPDATE_DASHBOARD_CHART_STATE, updateDashboardInRedux),
    takeEvery(actions.CREATE_DASHBOARD_NEW, createDashboardNew)
  ]);
}
