import actions from './actions';

const initialState = {
  id: null,
  dateRange: '',
  name: '',
  baseTemplateId: null,
  mode: 'view',
  widgetData: {
    dashboardId: null,
    widgets: []
  },
  templates: null,
  loading: false,
  schema_details: [],
  dashboardEditModeWidgets: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    case actions.UPDATE_DASHBOARD_IN_REDUX:
      return { ...state, dashboardEditModeWidgets: action.payload.widgets };
    default:
      return state;
  }
}
