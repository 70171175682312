import React from 'react';
import { Menu, Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import actions from 'redux/SideBar/actions';
import { track, actions as TRACKER_ACTIONS } from 'redux/tracker';

const mapStateToProps = ({ SideBar, User }) => ({
  detailBar: SideBar.detailBar,
  user: User?.userData
});

const mapDispatchToProps = dispatch => ({
  setSelectedDetailItem: key =>
    dispatch({
      type: actions.SET_SELECTED_DETAIL,
      payload: {
        detailBar: {
          selectedDetailKeys: [key]
        }
      }
    }),
  toggleCollapse: () =>
    dispatch({
      type: actions.TOGGLE_DETAILBAR
    })
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class DetailBar extends React.Component {
  selectItem = key => {
    this.props.setSelectedDetailItem(key);
    this.props.history.push('/' + this.props.detailBar.id.toLowerCase() + '/' + key);
  };

  componentDidUpdate() {
    var array = this.props.location.pathname.split('/');
    var key = array[2] ? array[2] : null;
    if (key && this.props.detailBar.selectedDetailKeys[0] !== key) {
      this.props.setSelectedDetailItem(key);
    }
  }

  toggleCollapse = () => {
    try {
      track(TRACKER_ACTIONS.sidebarToggle, {
        meta: {
          state: this.props.detailBar.collapsed ? 'open' : 'close',
          user_email: this.props.user?.email || '',
          user_name: this.props.user?.username || '',
          store_code: this.props.user?.organisationSet?.store_code || ''
        }
      });
    } catch (error) {
      console.log(error);
    }
    this.props.toggleCollapse();
  };

  render() {
    return (
      <div classNames='detailbar-collapse' in={!this.props.detailBar.collapsed}>
        <div className={this.props.detailBar.collapsed ? 'detailbar__container collapsed' : 'detailbar__container'}>
          <div className='toggle__wrapper'>
            <div className='toggle'>
              <Button type='default' shape='circle' size='small' onClick={this.toggleCollapse}>
                {this.props.detailBar.collapsed ? <Icon type='right' /> : <Icon type='left' />}
              </Button>
            </div>
          </div>
          <div className='detailbar__menu'>
            <Menu
              mode='inline'
              inlineIndent={40}
              selectedKeys={this.props.detailBar.selectedDetailKeys}
              onClick={({ key }) => {
                this.selectItem(key);
              }}
            >
              <Menu.ItemGroup key='g1' title={this.props.detailBar.title}>
                <Menu.Item title={'Chart Builder'} key={'chart_builder'}>
                  <Link to={'chart_builder'}>Chart Builder</Link>
                </Menu.Item>
                <Menu.Item title={'user_analysis'} key={'user_analysis'}>
                  <Link to='/user_analysis'>User Analysis</Link>
                </Menu.Item>
                {/*  <Menu.Item title={'Product Analysis'} key={'product_analysis'}>
                  <Link to={'product_analysis'}>Product Analysis</Link>
                </Menu.Item>*/}
                {/*<Menu.Item title={'Exploratory Analysis'} key={'exploratory_analysis'}>
                  <Link to={'exploratory_analysis'}>Exploratory Analysis</Link>
                </Menu.Item>*/}
              </Menu.ItemGroup>
            </Menu>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailBar;
