const actions = {
  SUBMIT_QUESTION: 'askAarya/SUBMIT_QUESTION',
  GET_QUESTIONS: 'askAarya/GET_QUESTIONS',
  GET_ANSWERS: 'askAarya/GET_ANSWERS',
  GET_REPORT: 'askAarya/GET_REPORT',
  SET_STATE: 'askAarya/SET_STATE',
  TOGGLE_LOADING: 'askAarya/TOGGLE_LOADING'
};

export default actions;
