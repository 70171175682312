import Api from '../api';

export default class Config extends Api {
  getConfigData = async () => {
    try {
      var { data } = await this.get('/');

      return data;
    } catch (error) {
      console.log({ error });
      return {
        hasError: true,
        error: error.response
      };
    }
  };

  updateConfigData = async payload => {
    try {
      const { data } = await this.patch(`/update/`, { ...payload });
      return data;
    } catch (error) {
      return error;
    }
  };
}
