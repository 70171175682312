import { focusedInsightsDashboardConstants } from './SpotlightDashboard.constants';

const initialState = {
  focused_insights_tree: {},
  focused_insights_bottom_cards: {},
  focused_insights_notification: {}
};

const spotlightDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_GRID_REQUEST:
      return {
        ...state,
        focused_insights_bottom_cards: { loading: true, error: false }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_GRID_SUCCESS:
      return {
        ...state,
        focused_insights_bottom_cards: { loading: false, error: false, ...action.payload }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_GRID_FAILED:
      return {
        ...state,
        focused_insights_bottom_cards: { loading: false, error: true }
      };

    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_TREE_REQUEST:
      return {
        ...state,
        focused_insights_tree: { loading: true, error: false }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_TREE_SUCCESS:
      return {
        ...state,
        focused_insights_tree: { loading: false, error: false, data: action.payload }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_TREE_FAILED:
      return {
        ...state,
        focused_insights_tree: { loading: false, error: true }
      };

    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_NOTIFICATION_REQUEST:
      return {
        ...state,
        focused_insights_notification: { loading: true, error: false }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        focused_insights_notification: { loading: false, error: false, data: action.payload }
      };
    case focusedInsightsDashboardConstants.GET_FOCUSED_INSIGHTS_NOTIFICATION_FAILED:
      return {
        ...state,
        focused_insights_notification: { loading: false, error: true }
      };

    case focusedInsightsDashboardConstants.CLEAR_FOCUSED_INSIGHTS_DATA:
      return {
        ...state,
        focused_insights_tree: {},
        focused_insights_bottom_cards: {},
        focused_insights_notification: {}
      };

    default:
      return state;
  }
};

export default spotlightDashboardReducer;
