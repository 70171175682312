export const ShareLinkConstants = {
  GET_SHARETOKEN_REQUEST: 'ShareLink/GET_SHARETOKEN_REQUEST',
  GET_SHARETOKEN_SUCCESS: 'ShareLink/GET_SHARETOKEN_SUCCESS',
  GET_SHARETOKEN_ERROR: 'ShareLink/GET_SHARETOKEN_ERROR',

  GET_TOKEN_VERIFICATION_REQUEST: 'ShareLink/GET_TOKEN_VERIFICATION_REQUEST',
  GET_TOKEN_VERIFICATION_SUCCESS: 'ShareLink/GET_TOKEN_VERIFICATION_SUCCESS',
  GET_TOKEN_VERIFICATION_ERROR: 'ShareLink/GET_TOKEN_VERIFICATION_ERROR',

  CLEAR_TOKEN_DATA: 'ShareLink/CLEAR_TOKEN_DATA'
};
