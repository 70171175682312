import { reportConstants } from './report.constants';

const initialState = {
  reports: {},
  selectedReport: {},
  loading: true,
  error: false
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportConstants.GET_REPORT_LIST_REQUEST:
      return { ...state, loading: true, error: false };
    case reportConstants.GET_REPORT_LIST_SUCCESS:
      return { ...state, reports: action.payload, loading: false, error: false };
    case reportConstants.GET_REPORT_LIST_ERROR:
      return { ...state, loading: false, error: true };
    case reportConstants.GET_REPORT_DETAIL_REQUEST:
      return { ...state, loading: true, error: false };
    case reportConstants.GET_REPORT_DETAIL_SUCCESS:
      return { ...state, selectedReport: action.payload, loading: false, error: false };
    case reportConstants.GET_REPORT_DETAIL_ERROR:
      return { ...state, loading: false, error: true };
    case reportConstants.SET_REPORT_DETAIL_DATA:
      return { ...state, loading: false, error: false, selectedReport: action.payload };
    default:
      return state;
  }
};

export default ReportReducer;
