import actions from './actions';

const initialState = {
  id: null,
  name: '',
  active: true,
  integrationType: null,
  availableVisualisation: [],
  accounts: [],
  availableIntegrations: [],
  baseVisualisations: [],
  loading: false,
  connectedAccounts: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    case actions.CONNECTED_ACCOUNTS:
      return { ...state, connectedAccounts: [...action.payload] };
    case actions.CLEAN_CONNECTED_ACCOUNTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
