const businessOverviewConfig = {
  name: 'marketplace Overview dashboard',
  dashboardId: 'business-overview',
  widgetRows: [
    {
      row_id: 1,
      isSmallCardsRow: true,
      queries: [
        {
          id: 1,
          is_custom: false,
          is_business_overview_widget: true,
          owner: null,
          showSummary: true,
          splitSummary: false,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.count'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.count': 'sum'
              }
            }
          ],
          time_granularity: true,
          title: 'Orders',
          type: 'msspline',
          isTopCard: true,
          iconClass: 'visits'
        },
        {
          id: 2,
          is_custom: false,
          is_business_overview_widget: true,
          showSummary: true,
          splitSummary: false,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.averageOrderValue'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.averageOrderValue': 'avg'
              }
            }
          ],
          time_granularity: true,
          title: 'AOV',
          type: 'msspline',
          isTopCard: true,
          iconClass: 'rpv'
        },
        {
          id: 3,
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          showSummary: true,
          splitSummary: false,
          query: [
            {
              filters: [],
              measures: ['MarketingOverall.TotalAdSpend'],
              timezone: 'Asia/Kolkata',
              timeDimensions: [
                { dateRange: 'Last 7 days', dimension: 'MarketingOverall.reportDate', granularity: 'day' }
              ],
              aggregationTypes: { 'MarketingOverall.TotalAdSpend': 'sum' }
            }
          ],
          time_granularity: true,
          title: 'Ad Spends',
          type: 'msspline',
          isTopCard: true,
          iconClass: 'c-ratio'
        },
        {
          id: 4,
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          showSummary: true,
          splitSummary: false,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.totalPrice'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.totalPrice': 'sum'
              }
            }
          ],
          time_granularity: true,
          title: 'Revenue',
          type: 'msspline',
          isTopCard: true,
          iconClass: 'revenue'
        }
      ]
    },
    {
      row_id: 2,
      isSmallCardsRow: false,
      queries: [
        {
          base_visualisation_id: null,
          custom_widget_id: null,
          id: 5,
          info: 'Ad Spend By Channel',
          integration: ['gads', 'fbads'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          layoutConfig: {
            h: 1,
            maxH: 1,
            minH: 1,
            w: 0,
            x: 0,
            y: 0
          },
          owner: null,
          query: [
            {
              filters: [],
              measures: ['MarketingOverall.TotalAdSpend'],
              timezone: 'Asia/Kolkata',
              dimensions: ['MarketingOverall.channel'],
              timeDimensions: [
                { dateRange: 'Last 7 days', dimension: 'MarketingOverall.reportDate', granularity: 'day' }
              ],
              aggregationTypes: { 'MarketingOverall.TotalAdSpend': 'sum' }
            }
          ],

          time_granularity: true,
          showSummary: true,
          splitSummary: true,
          title: 'Ad Spend By Channel',
          type: 'msstackedcolumn2d'
        },
        {
          id: 6,
          isSlow: false,
          is_custom: false,
          info: 'Revenue By Channel',
          integration: ['d2c', 'mkp'],
          layoutConfig: {
            h: 1,
            maxH: 1,
            minH: 1,
            w: 0,
            x: 0,
            y: 0
          },
          is_business_overview_widget: true,
          showSummary: true,
          splitSummary: true,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.totalPrice'],
              dimensions: ['MgOrders.channel'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.totalPrice': 'sum'
              }
            }
          ],
          time_granularity: true,
          title: 'Revenue',
          type: 'msspline'
        },
        {
          id: 7,
          is_custom: false,
          info: 'AOV Trend By Channel',
          integration: ['d2c', 'mkp'],
          layoutConfig: {
            h: 1,
            maxH: 1,
            minH: 1,
            w: 0,
            x: 0,
            y: 0
          },
          is_business_overview_widget: true,
          showSummary: true,
          splitSummary: true,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.averageOrderValue'],
              dimensions: ['MgOrders.channel'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.averageOrderValue': 'avg'
              }
            }
          ],
          time_granularity: true,
          title: 'AOV Trend',
          type: 'msspline'
        },
        {
          id: 8,
          is_custom: false,
          info: 'Orders By Channel',
          integration: ['d2c', 'mkp'],
          layoutConfig: {
            h: 1,
            maxH: 1,
            minH: 1,
            w: 0,
            x: 0,
            y: 0
          },
          is_business_overview_widget: true,
          owner: null,
          showSummary: true,
          splitSummary: true,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.count'],
              dimensions: ['MgOrders.channel'],
              timezone: 'Asia/Kolkata',
              renewQuery: true,
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MgOrders.createdAt',
                  granularity: 'day'
                }
              ],
              aggregationTypes: {
                'MgOrders.count': 'sum'
              }
            }
          ],
          time_granularity: true,
          title: 'Orders',
          type: 'msstackedcolumn2d'
        },
        {
          id: 9,
          info: 'Campaign ROI deatils',
          integration: ['fbads', 'gads'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              order: {
                'MarketingOverallCustom.revenue': 'desc',
                'MarketingOverallCustom.roas': 'desc',
                'MarketingOverallCustom.clicks': 'desc',
                'MarketingOverallCustom.impressions': 'desc',
                'MarketingOverallCustom.TotalAdSpend': 'desc'
              },
              filters: [],
              measures: [
                'MarketingOverallCustom.revenue',
                'MarketingOverallCustom.roas',
                'MarketingOverallCustom.clicks',
                'MarketingOverallCustom.impressions',
                'MarketingOverallCustom.TotalAdSpend'
              ],
              timezone: 'Asia/Kolkata',
              dimensions: ['MarketingOverallCustom.campaignName','MarketingOverallCustom.channel'],
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'MarketingOverallCustom.reportDate'
                }
              ],
              aggregationTypes: {
                'MarketingOverallCustom.roas': 'avg',
                'MarketingOverallCustom.clicks': 'sum',
                'MarketingOverallCustom.revenue': 'sum',
                'MarketingOverallCustom.impressions': 'sum',
                'MarketingOverallCustom.TotalAdSpend': 'sum'
              },
              negativeMeasures: {
                'MarketingOverallCustom.TotalAdSpend': true
              }
            }
          ],
          time_granularity: false,
          title: 'Top Campaign Performance',
          type: 'table',
          showSummary: true,
          splitSummary: false,
          isFullWidth: true
        },

        {
          id: 10,
          info: 'Top User Segments',
          integration: ['shp'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              limit: 10,
              measures: ['ShpEvents.uniqueUserCount'],
              timeDimensions: [
                {
                  dimension: 'ShpEvents.eventTime',
                  dateRange: 'Last 7 days'
                }
              ],
              dimensions: ['ShpEvents.city'],
              timezone: 'Asia/Kolkata',
              filters: [
                {
                  dimension: 'ShpEvents.eventName',
                  operator: 'equals',
                  values: ['OrderSuccess']
                }
              ],
              aggregationTypes: {
                'ShpEvents.uniqueUserCount': 'sum'
              }
            }
          ],
          time_granularity: false,
          splitSummary: false,
          title: 'D2C User Segments',
          type: 'bubble'
        },

        {
          id: 11,
          info: 'Purchase funnel showing step-wise conversions (unique user count)',
          integration: ['shp'],
          time_resolution_availability: false,
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              filters: [],
              measures: [
                'ShpEvents.addToCartsUsers',
                'ShpEvents.addToCartsSuccessUsers',
                'ShpEvents.checkoutClicksUsers',
                'ShpEvents.submitOrderClicksUsers',
                'ShpEvents.orderSuccessUsers'
              ],
              timezone: 'Asia/Kolkata',
              dimensions: [],
              timeDimensions: [
                {
                  dateRange: 'Last 7 days',
                  dimension: 'ShpEvents.eventTime'
                }
              ]
            }
          ],
          time_granularity: false,
          splitSummary: false,
          title: 'D2C Purchase Funnel',
          type: 'funnel'
        },

        {
          base_visualisation_id: null,
          custom_widget_id: null,
          id: 14,
          info: 'Order Split By Channel',
          integration: ['d2c', 'mkp'],
          time_resolution_availability: false,
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              order: {
                'MgOrders.count': 'desc'
              },
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrders.count'],
              timezone: 'Asia/Kolkata',
              dimensions: ['MgOrders.channel'],
              timeDimensions: [{ dateRange: 'Last 7 days', dimension: 'MgOrders.createdAt' }],
              aggregationTypes: { 'MgOrders.count': 'sum' }
            }
          ],
          time_granularity: false,
          splitSummary: false,
          showSummary: true,
          title: 'Order Split By Channel',
          type: 'doughnut2d'
        },

        {
          base_visualisation_id: null,
          custom_widget_id: null,
          id: 13,
          info: 'Region wise Order count',
          integration: ['d2c', 'mkp'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              limit: '10',
              measures: ['MgOrders.count'],
              timeDimensions: [
                {
                  dimension: 'MgOrders.createdAt',
                  dateRange: 'Last 7 days'
                }
              ],
              dimensions: ['MgOrders.city'],
              timezone: 'Asia/Kolkata',
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              aggregationTypes: {
                'MgOrders.count': 'sum'
              }
            }
          ],
          showSummary: true,
          time_granularity: false,
          splitSummary: false,
          title: 'Top 10 Regions',
          type: 'treemap'
        },
        {
          base_visualisation_id: null,
          custom_widget_id: null,
          id: 12,
          info: 'New vs Repeat Users',
          integration: ['shp'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              filters: [],
              measures: ['ShpEvents.uniqueUserCount'],
              timezone: 'Asia/Kolkata',
              dimensions: ['ShpSessions.isFirst'],
              timeDimensions: [{ dateRange: 'Last 7 days', dimension: 'ShpSessions.sessionStartTime' }],
              aggregationTypes: { 'ShpEvents.uniqueUserCount': 'sum' }
            }
          ],
          showSummary: true,
          time_granularity: false,
          splitSummary: false,
          title: 'New vs Repeat Users',
          type: 'doughnut2d'
        },
        {
          id: 15,
          info: 'New vs Repeat Buyer Count',
          integration: ['d2c'],
          time_resolution_availability: false,
          is_business_overview_widget: true,
          isSlow: false,
          is_custom: false,
          showSummary: true,
          query: [
            {
              filters: [
                {
                  values: ['pending', 'complete', 'processing'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                },
                {
                  values: ['d2c'],
                  operator: 'equals',
                  dimension: 'MgOrders.shopCategory'
                }
              ],
              measures: ['MgOrders.buyersCount'],
              timezone: 'Asia/Kolkata',
              dimensions: ['MgOrders.purchaseType'],
              timeDimensions: [
                {
                  dimension: 'MgOrders.createdAt',
                  dateRange: ['2021-07-08', '2021-07-15'],
                  compareRange: []
                }
              ],
              aggregationTypes: {
                'MgOrders.count': 'sum'
              }
            }
          ],
          time_granularity: false,
          splitSummary: false,
          title: 'New vs Repeat Buyers - D2C',
          type: 'doughnut2d'
        },

        {
          base_visualisation_id: null,
          custom_widget_id: null,
          id: 16,
          info: 'Top Product Performance',
          integration: ['d2c', 'mkp'],
          isSlow: false,
          is_custom: false,
          is_business_overview_widget: true,
          query: [
            {
              limit: 50,
              order: {
                'MgOrderLines.lineTotal': 'desc'
              },
              filters: [
                {
                  values: ['complete', 'pending', 'processing', 'full_store_credit'],
                  operator: 'equals',
                  dimension: 'MgOrders.status'
                },
                {
                  values: ['web'],
                  operator: 'equals',
                  dimension: 'MgOrders.sourceName'
                }
              ],
              measures: ['MgOrderLines.qtyOrdered', 'MgOrderLines.lineTotal'],
              mergeKey: 'MgProducts.name',
              skipSummary: true,
              timezone: 'Asia/Kolkata',
              dimensions: ['MgOrderLines.name'],
              timeDimensions: [
                {
                  dateRange: ['Last 7 days'],
                  dimension: 'MgOrderLines.createdAt',
                  compareRange: []
                }
              ],
              aggregationTypes: {
                'MgOrderLines.lineTotal': 'sum',
                'MgOrderLines.qtyOrdered': 'sum'
              }
            }
          ],
          time_granularity: false,
          splitSummary: false,
          title: 'Top Product Performance',
          type: 'table',
          isFullWidth: true
        }
      ]
    }
  ]
};

export default businessOverviewConfig;
