const actions = {
  SET_STATE: 'sidebar/SET_STATE',
  SET_DETAILBAR_DATA: 'sidebar/SET_DETAILBAR_DATA',
  SET_SELECTED_MENU: 'sidebar/SET_SELECTED_MENU',
  SET_SELECTED_DETAIL: 'sidebar/SET_SELECTED_DETAIL',
  SET_DETAIL: 'sidebar/SET_DETAIL',
  UPDATE_MENU: 'sidebar/UPDATE_MENU',
  GET_MENU: 'sidebar/GET_MENU',
  TOGGLE_DETAILBAR: 'sidebar/TOGGLE_DETAILBAR'
};

export default actions;
