import React from 'react';
import { Drawer, Menu, Icon, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Solutions from './../TopBar/Solutions';

import actions from 'redux/SideBar/actions';
import { track, actions as TRACKER_ACTIONS } from 'redux/tracker';
import { SIDEBAR_KEYS, routesMapping } from '../../../utilities/utilities_exports';

const mapStateToProps = ({ SideBar, User }) => ({
  detailBar: SideBar.detailBar,
  user: User?.userData,
  showBusinessOverview: User.userData.organisationSet.show_business_overview,
  isMarketplaceStore: User?.userData?.isMarketplaceStore
});

const mapDispatchToProps = dispatch => ({
  setSelectedDetailItem: key =>
    dispatch({
      type: actions.SET_SELECTED_DETAIL,
      payload: {
        detailBar: {
          selectedDetailKeys: [key]
        }
      }
    }),
  toggleCollapse: () =>
    dispatch({
      type: actions.TOGGLE_DETAILBAR
    })
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class DetailBar extends React.Component {
  redirectToAdd = () => {
    // ---- to close sidebar on click of add button
    this.props.toggleCollapse();

    if (this.props.detailBar.id) {
      if (this.props.detailBar.id.toLowerCase() === 'data_sources') {
        try {
          track(TRACKER_ACTIONS.addIntegrationClick, {
            meta: {
              user_email: this.props.user?.email || '',
              user_name: this.props.user?.username || '',
              store_code: this.props.user?.organisationSet?.store_code || ''
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (this.props.detailBar.id.toLowerCase() === 'dashboard') {
        try {
          track(TRACKER_ACTIONS.addDashboardClick, {
            meta: {
              user_email: this.props.user?.email || '',
              user_name: this.props.user?.username || '',
              store_code: this.props.user?.organisationSet?.store_code || ''
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.props.history.push('/' + this.props.detailBar.id.toLowerCase() + '/add');
    } else {
      this.props.history.push('/');
    }
  };

  redirectToAllSegments = () => {
    // ---- to close sidebar on click of add button
    this.props.toggleCollapse();
    this.props.history.push('/segments/add');
  };

  selectItem = key => {
    this.props.setSelectedDetailItem(key);
    this.props.history.push('/' + this.props.detailBar.id.toLowerCase() + '/' + key);
    this.props.toggleCollapse();
  };

  componentDidUpdate() {
    var array = this.props.location.pathname.split('/');
    var key = array[2] ? array[2] : null;
    if (key && this.props.detailBar.selectedDetailKeys[0] !== key) {
      this.props.setSelectedDetailItem(key);
    }
  }

  toggleCollapse = () => {
    try {
      track(TRACKER_ACTIONS.sidebarToggle, {
        meta: {
          state: this.props.detailBar.collapsed ? 'open' : 'close',
          user_email: this.props.user?.email || '',
          user_name: this.props.user?.username || '',
          store_code: this.props.user?.organisationSet?.store_code || ''
        }
      });
    } catch (error) {
      console.log(error);
    }
    this.props.toggleCollapse();
  };

  buildCustomMenu = title => {
    let menu;
    if (title === 'solutions') {
      menu = <Solutions />;
    }

    return menu;
  };

  render() {
    const { detailBar } = this.props;
    const { id, title, code } = detailBar;
    let uniqSideMenuValues = Array.from(new Set(this.props.detailBar.items.map(elem => `${elem.name}`))).map(
      distinctVal => {
        return this.props.detailBar.items.find(elem => elem.name === distinctVal);
      }
    );

    if (title === 'Data Sources') {
      let codeMap = {};
      uniqSideMenuValues.forEach(val => {
        if (!codeMap[val.code]) {
          codeMap[val.code] = val;
        }
      });
      uniqSideMenuValues = Object.values(codeMap);
    }

    return (
      <Drawer
        placement='left'
        closable={false}
        onClose={this.toggleCollapse}
        visible={this.props.detailBar.collapsed}
        getContainer={false}
        width='240px'
        style={{ position: 'absolute' }}
      >
        <div className={this.props.detailBar.collapsed ? 'detailbar__container collapsed' : 'detailbar__container'}>
          <div className='detailbar__menu'>
            {SIDEBAR_KEYS.includes(id) ? (
              this.buildCustomMenu(id)
            ) : (
              <Menu
                mode='inline'
                inlineIndent={40}
                selectedKeys={this.props.detailBar.selectedDetailKeys}
                onClick={({ key }) => {
                  this.selectItem(key);
                }}
              >
                {code === 'integrations' && (
                  <Menu.ItemGroup key='g2' title={title.toUpperCase()}>
                    <li className='ant-menu-item all-data no-border mb-0'>
                      <Row type='flex' align='center' justify='center'>
                        <Col md={20}>
                          <Link
                            to={'/data_sources/add'}
                            onClick={() => this.props.toggleCollapse()}
                            style={{ color: '#595959' }}
                          >
                            All Data Sources
                          </Link>
                        </Col>
                        <Col md={4} className='text-right'>
                          {!['notification', 'solutions', 'exploration'].includes(id) && (
                            <Icon
                              type='plus-circle-o'
                              className='add_circle'
                              role='button'
                              onClick={() => {
                                this.props.history.push('/data_sources/add');
                                this.props.toggleCollapse();
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </li>
                    <li className='ant-menu-item-group-title no-border uppercase'>Connected Data Sources</li>
                  </Menu.ItemGroup>
                )}

                {code === 'dashboards' && (
                  <Menu.ItemGroup key='g3' title={title.toUpperCase()}>
                    <li className='ant-menu-item all-data no-border mb-0'>
                      {this.props.showBusinessOverview && (
                        <Link
                          to={routesMapping?.businessOverviewDashboard}
                          onClick={() => this.props.toggleCollapse()}
                          disabled={!this.props.showBusinessOverview}
                        >
                          Business Overview
                        </Link>
                      )}
                      {this.props.isMarketplaceStore && (
                        <Link to={'/competitive-intelligence'} onClick={() => this.props.toggleCollapse()}>
                          Competitive Intelligence
                        </Link>
                      )}
                    </li>
                    <span className='ant-menu-item-group-title block uppercase'>
                      <Row type='flex' align='middle' justify='center'>
                        <Col md={16}>Custom Dashboards</Col>
                        {!['solutions', 'exploration'].includes(id) && (
                          <Col md={8} className='text-right'>
                            <button className='add_circle' onClick={this.redirectToAdd}>
                              <Icon type='plus-circle-o' />
                            </button>
                          </Col>
                        )}
                      </Row>
                    </span>
                  </Menu.ItemGroup>
                )}

                {code === 'exploratory-analysis' && (
                  <Menu.ItemGroup key='g4' title={title.toUpperCase()}>
                    <li className='ant-menu-item'>
                      <a href='/explore/chart_builder'>Chart Builder</a>
                    </li>
                    <li className='ant-menu-item'>
                      <a href='/explore/user_analysis'>User Analysis</a>
                    </li>
                  </Menu.ItemGroup>
                )}
                {code === 'segments' && (
                  <Menu.ItemGroup key='g5' title={title.toUpperCase()}>
                    <li className='ant-menu-item all-data no-border mb-0'>
                      <Row type='flex' align='center' justify='center'>
                        <Col md={20}>
                          <Link
                            to={'/segments'}
                            onClick={() => this.props.toggleCollapse()}
                            style={{ color: '#595959' }}
                          >
                            All Segments
                          </Link>
                        </Col>
                        <Col md={4} className='text-right'>
                          {!['solutions', 'exploration'].includes(id) && (
                            <Icon
                              type='plus-circle-o'
                              className='add_circle'
                              role='button'
                              onClick={() => {
                                this.props.toggleCollapse();
                                this.redirectToAllSegments();
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </li>
                  </Menu.ItemGroup>
                )}

                <Menu.ItemGroup key='g6' title={null} className='customNavLinks'>
                  {uniqSideMenuValues?.map(item => (
                    <Menu.Item title={item.integration_name || item.name} key={item.id}>
                      <Link to={`/${item.id}`}>{item.integration_name || item.name}</Link>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              </Menu>
            )}
          </div>
        </div>
      </Drawer>
    );
  }
}

export default DetailBar;
