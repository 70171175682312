import React, { useState } from 'react';
import { Avatar, Steps, Icon } from 'antd';
import Logo from 'assets/icons/SVG/polaris-growthPlatform-logo.svg';
import { initialDiligenceObject } from './utilities/initialStates';
import StoreInformation from './StoreInformation';
import PlatformInformation from './PlatformInformation';
// import DataSourceInformation from './DataSourceInformation';
import './DueDiligence.scss';
const { Step } = Steps;

const DueDiligence = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [customerData, setCustomerData] = useState({
    ...initialDiligenceObject
  });

  const steps = [
    {
      title: 'Store Information',
      content: (
        <StoreInformation
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      ),
      icon: <Icon type='idcard' theme='twoTone' />
    },
    {
      title: 'Connect Platform',
      content: (
        <PlatformInformation
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      ),
      icon: <Icon type='dashboard' theme='twoTone' />
    }
    // {
    //   title: 'Connect Data Sources',
    //   content: <DataSourceInformation />,
    //   icon: <Icon type='api' theme='twoTone' />
    // }
  ];

  return (
    <div className='dueContainer'>
      <div className='dc_header mb-4'>
        <Avatar size={84} src={Logo} style={{ background: '#ffffff', padding: '6px' }} className='mb-3' />
        <div className='c-heading md bold'>Onboarding</div>
      </div>
      <div className='dc_body mb-4'>
        <div className='c-chartWidget'>
          <div className='widget_body'>
            <div>
              <Steps current={currentStep} className='mb-4'>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} icon={item.icon} />
                ))}
              </Steps>
              <div className='steps-content'>{steps[currentStep].content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueDiligence;
