import React from 'react';
import { Layout, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from 'components/layout/SideBar';
import DetailBar from 'components/layout/DetailBar';
import { isMobile } from 'react-device-detect';
import Footer from 'layouts/Footer/Footer';

const { Sider } = Layout;

@withRouter
@connect(({ SideBar, Dashboard, Integrations, User }) => ({
  collapsed: SideBar.detailBar.collapsed,
  mode: Dashboard.mode,
  loading: Dashboard.loading || Integrations.loading || User.loading
}))
class Component extends React.PureComponent {
  render() {
    const { children, loading } = this.props;

    return (
      <>
        <div className='c-pageContainer'>
          {!isMobile && (
            <Sider className='sidebar__container' width={100}>
              <Sidebar />
            </Sider>
          )}
          <div id='componentWrapper' className='layout__main__container'>
            <DetailBar />
            <div className={`${loading ? 'isFullPage isAtCenter' : 'isFullPage'}`}>
              <Spin spinning={loading}>{children}</Spin>
            </div>

            {!loading && <Footer />}
          </div>
        </div>
      </>
    );
  }
}

export default Component;
