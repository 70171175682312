import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';

import actions from './actions';
import authActions from 'redux/User/actions';

import Api from './api';

const segment = new Api('segments');
const segmentVenn = new Api('segments/overlap');

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
}

export function* getSegment() {
  try {
    yield put({ type: actions.GET_SEGMENT_REQUEST });
    var segments = yield call(segment.getSegments);
    yield put({ type: actions.GET_SEGMENT_SUCCESS });
    yield put({
      type: actions.SET_STATE,
      payload: {
        segments
      }
    });
  } catch (error) {
    yield put({ type: actions.GET_SEGMENT_ERROR });
    yield call(handleError, error);
  }
}

export function* getTotalUsersCount() {
  try {
    const totalUsers = yield call(segment.getTotalUsersCount);
    yield put({
      type: actions.SET_STATE,
      payload: {
        totalUsers
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getSubcategories() {
  try {
    const subcategories = yield call(segment.getSubcategories);
    yield put({
      type: actions.SET_STATE,
      payload: { subcategories }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getSegmentCategory() {
  try {
    var categories = yield call(segment.getSegmentCategory);
    yield put({
      type: actions.SET_STATE,
      payload: {
        categories
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getSegmentMeta() {
  try {
    var meta = yield call(segment.getSegmentMeta);
    yield put({
      type: actions.SET_STATE,
      payload: {
        meta
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* addSegment(action) {
  try {
    yield put({
      type: actions.TOGGLE_LOADING
    });
    var segments = yield call(segment.addSegment, action.payload);
    yield put({
      type: actions.SET_STATE,
      payload: {
        segments
      }
    });

    yield put(push('/segments'));
  } catch (error) {
    yield call(handleError, error);
  }
  yield put({
    type: actions.TOGGLE_LOADING
  });
}

export function* createAudienceGoogle(action) {
  try {
    var audience_export_google = yield call(segment.createAudienceGoogle, action.payload);
    message.info(audience_export_google.message);
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* createAudienceSendgrid(action) {
  try {
    var audience_export_sendgrid = yield call(segment.createAudienceSendgrid, action.payload);
    message.info(audience_export_sendgrid.message);
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* createAudienceFb(action) {
  try {
    var audience_export_fb = yield call(segment.createAudienceFb, action.payload);
    message.info(audience_export_fb.message);
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* createAudienceMailchimp(action) {
  try {
    var audience_export_mailchimp = yield call(segment.createAudienceMailchimp, action.payload);
    message.info(audience_export_mailchimp.message);
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* deleteSegment(action) {
  try {
    yield call(segment.deleteSegment, action.payload.id);
    yield put({
      type: actions.GET_SEGMENTS
    });
    message.success('Segment Deleted Successfully.');
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getClauseValues() {
  try {
    let clauses = yield call(segment.getClauseValues);
    yield put({
      type: actions.SET_STATE,
      payload: {
        clauses
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getSegmentOverlap(action) {
  try {
    yield put({
      type: actions.GET_SEGMENT_OVERLAP_FETCHING
    });
    let clauses = yield call(segmentVenn.getSegmentOverlap, action.payload);
    const { data } = clauses;
    const arr = refactorVennData(data.sets);
    data.sets = arr;
    yield put({
      type: actions.GET_SEGMENT_OVERLAP_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: actions.GET_SEGMENT_OVERLAP_ERROR
    });
    yield call(handleError, error);
  }
}

export function* getCategoryFilters() {
  try {
    let [meta, categories, clauses] = yield all([
      call(segment.getSegmentMeta),
      call(segment.getSegmentCategory),
      call(segment.getClauseValues)
    ]);
    let categoryFilters = {};

    if (meta && meta.length && categories && categories.length && clauses) {
      categoryFilters = categories.sort((a, b) => a.position - b.position);
      meta.forEach(filter => {
        categoryFilters.forEach(category => {
          if (!category.filters) category.filters = [];
          if (category.id === filter.category) {
            category.filters.push({ ...filter, supported_values: clauses[filter.value_key] });
          }
          category.filters.sort((a, b) => a.position - b.position);
        });
      });
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        categoryFilters
      }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

function refactorVennData(data) {
  const arr = data.map(val => {
    return { ...val, total: val.size, size: val.sets.length === 1 ? 12 : val.sets.length === 2 ? 4 : 2 };
  });

  return arr;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SEGMENTS, getSegment),
    takeEvery(actions.ADD_SEGMENT, addSegment),
    takeEvery(actions.CREATE_AUDIENCE_GOOGLE, createAudienceGoogle),
    takeEvery(actions.CREATE_AUDIENCE_FB, createAudienceFb),
    takeEvery(actions.CREATE_AUDIENCE_SENDGRID, createAudienceSendgrid),
    takeEvery(actions.CREATE_AUDIENCE_MAILCHIMP, createAudienceMailchimp),
    takeEvery(actions.DELETE_SEGMENT, deleteSegment),
    takeEvery(actions.GET_CLAUSE, getClauseValues),
    takeEvery(actions.GET_SEGMENT_META, getSegmentMeta),
    takeEvery(actions.GET_SEGMENT_CATEGORY, getSegmentCategory),
    takeEvery(actions.GET_CATEGORY_FILTERS, getCategoryFilters),
    takeEvery(actions.GET_TOTAL_USERS_COUNT, getTotalUsersCount),
    takeEvery(actions.GET_SEGMENT_OVERLAP, getSegmentOverlap),
    takeEvery(actions.GET_SUBCATEGORIES, getSubcategories)
  ]);
}
