import { attributionConstants } from './Attribution.constants';

const initialState = {
  models: {},
  dimensions: {},
  selected_date_range: []
};

const attributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case attributionConstants.GET_ATTRIBUTION_MODEL_REQUEST:
      return {
        ...state,
        models: { loading: true, error: false }
      };
    case attributionConstants.GET_ATTRIBUTION_MODEL_SUCCESS:
      return {
        ...state,
        models: { loading: false, error: false, ...action.payload }
      };
    case attributionConstants.GET_ATTRIBUTION_MODEL_FAILED:
      return {
        ...state,
        models: { loading: false, error: true }
      };

    case attributionConstants.GET_ATTRIBUTION_DIMENSION_REQUEST:
      return {
        ...state,
        dimensions: { loading: true, error: false }
      };
    case attributionConstants.GET_ATTRIBUTION_DIMENSION_SUCCESS:
      return {
        ...state,
        dimensions: { loading: false, error: false, ...action.payload }
      };
    case attributionConstants.GET_ATTRIBUTION_DIMENSION_FAILED:
      return {
        ...state,
        dimensions: { loading: false, error: true }
      };

    case attributionConstants.SET_SELECTED_DATERANGE_SUCCESS:
      return {
        ...state,
        selected_date_range: action.payload
      };

    default:
      return state;
  }
};

export default attributionReducer;
