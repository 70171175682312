import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import GraasIconSmall from 'assets/images/graas_logo_small.svg';
import GraasIconBig from 'assets/images/graas_white.svg';
import RedirectionModalBg from 'assets/images/redirectionModal.webp';
import './Auth.scss';

const Auth = props => {
  const [showRedirectionModal, setShowRedirectionModal] = useState(true);
  return (
    <>
      <div className='c-loginPageWrapper'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-md-7 col-sm-12'>
              <div className='login_info'>
                <div className='login_info__logo'>
                  <img src={GraasIconBig} alt='graas' />
                </div>
                <span className='login_info__title'>
                  Turbo-charge your
                  <br />
                  eCommerce growth
                </span>
                <span className='login_external_link'>
                  <Link to={{ pathname: 'http://graas.ai/' }} target='_blank' className='link'>
                    Click here to know more about our growth engine
                  </Link>
                </span>
              </div>
            </div>
            <div className='col-md-5 col-sm-12'>
              <div className='c-loginContainer'>
                <div className='growth__logo'>
                  <img src={GraasIconSmall} alt='graas' />
                </div>
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showRedirectionModal}
        onCancel={() => setShowRedirectionModal(false)}
        width={671}
        className='redirectionModal'
        footer={null}
      >
        <img src={RedirectionModalBg} alt='growth' />
        <div className='modalContent'>
          <div className='textContent'>https://growth.shoptimize.ai will be sun setting soon. </div>
          <div className='buttonContent'>
            <button onClick={() => window.location.replace('https://growth.graas.ai/')}>
              Please visit growth.graas.ai
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Auth;
