export const currency_list = [
  {
    code: 'INR',
    name: 'Indian rupee',
    symbol: '₹',
    numeric_code: '356'
  },
  {
    code: 'AED',
    name: 'United Arab Emirates dirham',
    symbol: 'د.إ',
    numeric_code: '784'
  },
  {
    code: 'AFN',
    name: 'Afghan afghani',
    symbol: '؋',
    numeric_code: '971'
  },
  {
    code: 'ALL',
    name: 'Albanian lek',
    symbol: 'L',
    numeric_code: '008'
  },
  {
    code: 'AMD',
    name: 'Armenian dram',
    symbol: '֏',
    numeric_code: '051'
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean guilder',
    symbol: 'ƒ',
    numeric_code: '532'
  },
  {
    code: 'AOA',
    name: 'Angolan kwanza',
    symbol: 'Kz',
    numeric_code: '973'
  },
  {
    code: 'ARS',
    name: 'Argentine peso',
    symbol: '$',
    numeric_code: '032'
  },
  {
    code: 'AUD',
    name: 'Australlian Dollar',
    symbol: '$',
    numeric_code: '036'
  },
  {
    code: 'AWG',
    name: 'Aruban florin',
    symbol: 'ƒ',
    numeric_code: '533'
  },
  {
    code: 'AZN',
    name: 'Azerbaijani manat',
    symbol: '₼',
    numeric_code: '944'
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina convertible mark',
    symbol: 'KM',
    numeric_code: '977'
  },
  {
    code: 'BBD',
    name: 'Barbadian dollar',
    symbol: '$',
    numeric_code: '052'
  },
  {
    code: 'BDT',
    name: 'Bangladeshi taka',
    symbol: '৳',
    numeric_code: '050'
  },
  {
    code: 'BGN',
    name: 'Bulgarian lev',
    symbol: 'лв',
    numeric_code: '975'
  },
  {
    code: 'BHD',
    name: 'Bahraini dinar',
    symbol: '.د.ب',
    numeric_code: '048',
    digits_after_decimal_separator: 3
  },
  {
    code: 'BIF',
    name: 'Burundian franc',
    symbol: 'Fr',
    numeric_code: '108',
    digits_after_decimal_separator: 0
  },
  {
    code: 'BMD',
    name: 'Bermudian dollar',
    symbol: '$',
    numeric_code: '060'
  },
  {
    code: 'BND',
    name: 'Brunei dollar',
    symbol: '$',
    numeric_code: '096'
  },
  {
    code: 'BOB',
    name: 'Bolivian boliviano',
    symbol: 'Bs.',
    numeric_code: '068'
  },
  {
    code: 'BRL',
    name: 'Brazilian real',
    symbol: 'R$',
    numeric_code: '986'
  },
  {
    code: 'BSD',
    name: 'Bahamian dollar',
    symbol: '$',
    numeric_code: '044'
  },
  {
    code: 'BTN',
    name: 'Bhutanese ngultrum',
    symbol: 'Nu.',
    numeric_code: '064'
  },
  {
    code: 'BWP',
    name: 'Botswana pula',
    symbol: 'P',
    numeric_code: '072'
  },
  {
    code: 'BYN',
    name: 'New Belarusian ruble',
    symbol: 'Br',
    numeric_code: '933'
  },
  {
    code: 'BZD',
    name: 'Belize dollar',
    symbol: '$',
    numeric_code: '084'
  },
  {
    code: 'CAD',
    name: 'Canadian dollar',
    symbol: '$',
    numeric_code: '124'
  },
  {
    code: 'CDF',
    name: 'Congolese franc',
    symbol: 'Fr',
    numeric_code: '976'
  },
  {
    code: 'CHF',
    name: 'Swiss franc',
    symbol: 'Fr',
    numeric_code: '756'
  },
  {
    code: 'CLP',
    name: 'Chilean peso',
    symbol: '$',
    numeric_code: '152',
    digits_after_decimal_separator: 0
  },
  {
    code: 'CNY',
    name: 'Chinese yuan',
    symbol: '¥',
    numeric_code: '156'
  },
  {
    code: 'COP',
    name: 'Colombia Peso',
    symbol: '$',
    numeric_code: '170'
  },
  {
    code: 'CRC',
    name: 'Costa Rican colón',
    symbol: '₡',
    numeric_code: '188'
  },
  {
    code: 'CUC',
    name: 'Cuban convertible peso',
    symbol: '$',
    numeric_code: '931'
  },
  {
    code: 'CVE',
    name: 'Cape Verdean escudo',
    symbol: 'Esc',
    numeric_code: '132'
  },
  {
    code: 'CZK',
    name: 'Czech koruna',
    symbol: 'Kč',
    numeric_code: '203'
  },
  {
    code: 'DJF',
    name: 'Djiboutian franc',
    symbol: 'Fr',
    numeric_code: '262',
    digits_after_decimal_separator: 0
  },
  {
    code: 'DKK',
    name: 'Danish krone',
    symbol: 'kr',
    numeric_code: '208'
  },
  {
    code: 'DOP',
    name: 'Dominican peso',
    symbol: '$',
    numeric_code: '214'
  },
  {
    code: 'DZD',
    name: 'Algerian dinar',
    symbol: 'د.ج',
    numeric_code: '012'
  },
  {
    code: 'EGP',
    name: 'Egyptian pound',
    symbol: '£',
    numeric_code: '818'
  },
  {
    code: 'ERN',
    name: 'Eritrean nakfa',
    symbol: 'Nfk',
    numeric_code: '232'
  },
  {
    code: 'ETB',
    name: 'Ethiopian birr',
    symbol: 'Br',
    numeric_code: '230'
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
    numeric_code: '978'
  },
  {
    code: 'FJD',
    name: 'Fijian dollar',
    symbol: '$',
    numeric_code: '242'
  },
  {
    code: 'FKP',
    name: 'Falkland Islands pound',
    symbol: '£',
    numeric_code: '238'
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    symbol: '£',
    numeric_code: '826'
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    symbol: 'ლ',
    numeric_code: '981'
  },
  {
    code: 'GHS',
    name: 'Ghanaian cedi',
    symbol: '₵',
    numeric_code: '936'
  },
  {
    code: 'GIP',
    name: 'Gibraltar pound',
    symbol: '£',
    numeric_code: '292'
  },
  {
    code: 'GMD',
    name: 'Gambian dalasi',
    symbol: 'D',
    numeric_code: '270'
  },
  {
    code: 'GNF',
    name: 'Guinean franc',
    symbol: 'Fr',
    numeric_code: '324',
    digits_after_decimal_separator: 0
  },
  {
    code: 'GTQ',
    name: 'Guatemalan quetzal',
    symbol: 'Q',
    numeric_code: '320'
  },
  {
    code: 'GYD',
    name: 'Guyanese dollar',
    symbol: '$',
    numeric_code: '328'
  },
  {
    code: 'HKD',
    name: 'Hong Kong dollar',
    symbol: '$',
    numeric_code: '344'
  },
  {
    code: 'HNL',
    name: 'Honduran lempira',
    symbol: 'L',
    numeric_code: '340'
  },
  {
    code: 'HRK',
    name: 'Croatian kuna',
    symbol: 'kn',
    numeric_code: '191'
  },
  {
    code: 'HTG',
    name: 'Haitian gourde',
    symbol: 'G',
    numeric_code: '332'
  },
  {
    code: 'HUF',
    name: 'Hungarian forint',
    symbol: 'Ft',
    numeric_code: '348'
  },
  {
    code: 'IDR',
    name: 'Indonesian rupiah',
    symbol: 'Rp',
    numeric_code: '360',
    digits_after_decimal_separator: 0
  },
  {
    code: 'ILS',
    name: 'Israeli New Shekel',
    symbol: '₪',
    numeric_code: '376'
  },

  {
    code: 'IQD',
    name: 'Iraqi dinar',
    symbol: 'ع.د',
    numeric_code: '368',
    digits_after_decimal_separator: 3
  },
  {
    code: 'IRR',
    name: 'Iranian rial',
    symbol: '﷼',
    numeric_code: '364'
  },
  {
    code: 'ISK',
    name: 'Icelandic króna',
    symbol: 'kr',
    numeric_code: '352',
    digits_after_decimal_separator: 0
  },
  {
    code: 'JMD',
    name: 'Jamaican dollar',
    symbol: '$',
    numeric_code: '388'
  },
  {
    code: 'JOD',
    name: 'Jordanian dinar',
    symbol: 'د.ا',
    numeric_code: '400',
    digits_after_decimal_separator: 3
  },
  {
    code: 'JPY',
    name: 'Japanese yen',
    symbol: '¥',
    numeric_code: '392',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    symbol: 'K',
    numeric_code: '404'
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstani som',
    symbol: 'с',
    numeric_code: '417'
  },
  {
    code: 'KHR',
    name: 'Cambodian riel',
    symbol: '៛',
    numeric_code: '116'
  },
  {
    code: 'KMF',
    name: 'Comorian franc',
    symbol: 'Fr',
    numeric_code: '174',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KPW',
    name: 'North Korean won',
    symbol: '₩',
    numeric_code: '408'
  },
  {
    code: 'KRW',
    name: 'South Korean won',
    symbol: '₩',
    numeric_code: '410',
    digits_after_decimal_separator: 0
  },
  {
    code: 'KWD',
    name: 'Kuwaiti dinar',
    symbol: 'د.ك',
    numeric_code: '414',
    digits_after_decimal_separator: 3
  },
  {
    code: 'KYD',
    name: 'Cayman Islands dollar',
    symbol: '$',
    numeric_code: '136'
  },
  {
    code: 'KZT',
    name: 'Kazakhstani tenge',
    symbol: '₸',
    numeric_code: '398'
  },
  {
    code: 'LAK',
    name: 'Lao kip',
    symbol: '₭',
    numeric_code: '418'
  },
  {
    code: 'LBP',
    name: 'Lebanese pound',
    symbol: 'ل.ل',
    numeric_code: '422'
  },
  {
    code: 'LKR',
    name: 'Sri Lankan rupee',
    symbol: 'Rs',
    numeric_code: '144'
  },
  {
    code: 'LRD',
    name: 'Liberian dollar',
    symbol: '$',
    numeric_code: '430'
  },
  {
    code: 'LSL',
    name: 'Lesotho loti',
    symbol: 'L',
    numeric_code: '426'
  },
  {
    code: 'LYD',
    name: 'Libyan dinar',
    symbol: 'ل.د',
    numeric_code: '434',
    digits_after_decimal_separator: 3
  },
  {
    code: 'MAD',
    name: 'Moroccan dirham',
    symbol: 'د.م.',
    numeric_code: '504'
  },
  {
    code: 'MDL',
    name: 'Moldovan leu',
    symbol: 'L',
    numeric_code: '498'
  },
  {
    code: 'MGA',
    name: 'Malagasy ariary',
    symbol: 'Ar',
    numeric_code: '969'
  },
  {
    code: 'MKD',
    name: 'Macedonian denar',
    symbol: 'ден',
    numeric_code: '807'
  },
  {
    code: 'MMK',
    name: 'Burmese kyat',
    symbol: 'Ks',
    numeric_code: '104'
  },
  {
    code: 'MNT',
    name: 'Mongolian tögrög',
    symbol: '₮',
    numeric_code: '496'
  },
  {
    code: 'MOP',
    name: 'Macanese pataca',
    symbol: 'P',
    numeric_code: '446'
  },
  {
    code: 'MRO',
    name: 'Mauritanian ouguiya',
    symbol: 'UM',
    numeric_code: '478'
  },
  {
    code: 'MUR',
    name: 'Mauritian rupee',
    symbol: '₨',
    numeric_code: '480'
  },
  {
    code: 'MVR',
    name: 'Maldivian rufiyaa',
    symbol: '.ރ',
    numeric_code: '462'
  },
  {
    code: 'MWK',
    name: 'Malawian kwacha',
    symbol: 'MK',
    numeric_code: '454'
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    symbol: '$',
    numeric_code: '484'
  },
  {
    code: 'MYR',
    name: 'Malaysian ringgit',
    symbol: 'RM',
    numeric_code: '458'
  },
  {
    code: 'MZN',
    name: 'Mozambican metical',
    symbol: 'MT',
    numeric_code: '943'
  },
  {
    code: 'NAD',
    name: 'Namibian dollar',
    symbol: '$',
    numeric_code: '516'
  },
  {
    code: 'NGN',
    name: 'Nigerian naira',
    symbol: '₦',
    numeric_code: '566'
  },
  {
    code: 'NIO',
    name: 'Nicaraguan córdoba',
    symbol: 'C$',
    numeric_code: '558'
  },
  {
    code: 'NOK',
    name: 'Norwegian krone',
    symbol: 'kr',
    numeric_code: '578'
  },
  {
    code: 'NPR',
    name: 'Nepalese rupee',
    symbol: '₨',
    numeric_code: '524'
  },
  {
    code: 'NZD',
    name: 'New Zealand dollar',
    symbol: '$',
    numeric_code: '554'
  },
  {
    code: 'OMR',
    name: 'Omani rial',
    symbol: 'ر.ع.',
    numeric_code: '512',
    digits_after_decimal_separator: 3
  },
  {
    code: 'PAB',
    name: 'Panamanian balboa',
    symbol: 'B/.',
    numeric_code: '590'
  },
  {
    code: 'PEN',
    name: 'Nuevo Sol',
    symbol: 'S/',
    numeric_code: '604'
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean kina',
    symbol: 'K',
    numeric_code: '598'
  },
  {
    code: 'PHP',
    name: 'Philippine peso',
    symbol: '₱',
    numeric_code: '608'
  },
  {
    code: 'PKR',
    name: 'Pakistani rupee',
    symbol: '₨',
    numeric_code: '586'
  },
  {
    code: 'PLN',
    name: 'Polish złoty',
    symbol: 'zł',
    numeric_code: '985'
  },
  {
    code: 'PYG',
    name: 'Paraguayan guaraní',
    symbol: '₲',
    numeric_code: '600',
    digits_after_decimal_separator: 0
  },
  {
    code: 'QAR',
    name: 'Qatari riyal',
    symbol: 'ر.ق',
    numeric_code: '634'
  },
  {
    code: 'RON',
    name: 'Romanian leu',
    symbol: 'lei',
    numeric_code: '946'
  },
  {
    code: 'RSD',
    name: 'Serbian dinar',
    symbol: 'дин.',
    numeric_code: '941'
  },
  {
    code: 'RUB',
    name: 'Russian ruble',
    symbol: '₽',
    numeric_code: '643'
  },
  {
    code: 'RWF',
    name: 'Rwandan franc',
    symbol: 'Fr',
    numeric_code: '646',
    digits_after_decimal_separator: 0
  },
  {
    code: 'SAR',
    name: 'Saudi riyal',
    symbol: 'ر.س',
    numeric_code: '682'
  },
  {
    code: 'SBD',
    name: 'Solomon Islands dollar',
    symbol: '$',
    numeric_code: '090'
  },
  {
    code: 'SCR',
    name: 'Seychellois rupee',
    symbol: '₨',
    numeric_code: '690'
  },
  {
    code: 'SDG',
    name: 'Sudanese pound',
    symbol: 'ج.س.',
    numeric_code: '938'
  },
  {
    code: 'SEK',
    name: 'Swedish krona',
    symbol: 'kr',
    numeric_code: '752'
  },
  {
    code: 'SGD',
    name: 'Singapore dollar',
    symbol: '$',
    numeric_code: '702'
  },
  {
    code: 'SHP',
    name: 'Saint Helena pound',
    symbol: '£',
    numeric_code: '654'
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean leone',
    symbol: 'Le',
    numeric_code: '694'
  },
  {
    code: 'SOS',
    name: 'Somali shilling',
    symbol: 'Sh',
    numeric_code: '706'
  },
  {
    code: 'SRD',
    name: 'Surinamese dollar',
    symbol: '$',
    numeric_code: '968'
  },
  {
    code: 'SSP',
    name: 'South Sudanese pound',
    symbol: '£',
    numeric_code: '728'
  },
  {
    code: 'STD',
    name: 'São Tomé and Príncipe dobra',
    symbol: 'Db',
    numeric_code: '678'
  },
  {
    code: 'SYP',
    name: 'Syrian pound',
    symbol: '£',
    numeric_code: '760'
  },
  {
    code: 'SZL',
    name: 'Swazi lilangeni',
    symbol: 'L',
    numeric_code: '748'
  },
  {
    code: 'THB',
    name: 'Baht',
    symbol: '฿',
    numeric_code: '764'
  },
  {
    code: 'TJS',
    name: 'Tajikistani somoni',
    symbol: 'ЅМ',
    numeric_code: '972'
  },
  {
    code: 'TMT',
    name: 'Turkmenistan manat',
    symbol: 'm',
    numeric_code: '934'
  },
  {
    code: 'TND',
    name: 'Tunisian dinar',
    symbol: 'د.ت',
    numeric_code: '788',
    digits_after_decimal_separator: 3
  },
  {
    code: 'TOP',
    name: 'Tongan paʻanga',
    symbol: 'T$',
    numeric_code: '776'
  },
  {
    code: 'TRY',
    name: 'Turkish lira',
    symbol: '₺',
    numeric_code: '949'
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago dollar',
    symbol: '$',
    numeric_code: '780'
  },
  {
    code: 'TWD',
    name: 'New Taiwan dollar',
    symbol: '$',
    numeric_code: '901'
  },
  {
    code: 'TZS',
    name: 'Tanzanian shilling',
    symbol: 'Sh',
    numeric_code: '834'
  },
  {
    code: 'UAH',
    name: 'Ukrainian hryvnia',
    symbol: '₴',
    numeric_code: '980'
  },
  {
    code: 'UGX',
    name: 'Ugandan shilling',
    symbol: 'Sh',
    numeric_code: '800',
    digits_after_decimal_separator: 0
  },
  {
    code: 'USD',
    name: 'US Dollar',
    symbol: '$',
    numeric_code: '840'
  },
  {
    code: 'UYU',
    name: 'Uruguayan peso',
    symbol: '$',
    numeric_code: '858'
  },
  {
    code: 'UZS',
    name: 'Uzbekistani som',
    symbol: "so'm",
    numeric_code: '860'
  },
  {
    code: 'VEF',
    name: 'Venezuelan bolívar',
    symbol: 'Bs F',
    numeric_code: '937'
  },
  {
    code: 'VND',
    name: 'Vietnamese đồng',
    symbol: '₫',
    numeric_code: '704',
    digits_after_decimal_separator: 0
  },
  {
    code: 'VUV',
    name: 'Vanuatu vatu',
    symbol: 'Vt',
    numeric_code: '548',
    digits_after_decimal_separator: 0
  },
  {
    code: 'WST',
    name: 'Samoan tālā',
    symbol: 'T',
    numeric_code: '882'
  },
  {
    code: 'XAF',
    name: 'Central African CFA franc',
    symbol: 'Fr',
    numeric_code: '950',
    digits_after_decimal_separator: 0
  },
  {
    code: 'XCD',
    name: 'East Caribbean dollar',
    symbol: '$',
    numeric_code: '951'
  },
  {
    code: 'XOF',
    name: 'West African CFA franc',
    symbol: 'Fr',
    numeric_code: '952',
    digits_after_decimal_separator: 0
  },
  {
    code: 'XPF',
    name: 'CFP franc',
    symbol: 'Fr',
    numeric_code: '953',
    digits_after_decimal_separator: 0
  },
  {
    code: 'YER',
    name: 'Yemeni rial',
    symbol: '﷼',
    numeric_code: '886'
  },
  {
    code: 'ZAR',
    name: 'Rand',
    symbol: 'R',
    numeric_code: '710'
  },
  {
    code: 'ZMW',
    name: 'Zambian kwacha',
    symbol: 'ZK',
    numeric_code: '967'
  }
];

export const size = [
  {
    value: 'large',
    label: 'Large'
  },
  {
    value: 'medium',
    label: 'Medium'
  },
  {
    value: 'small',
    label: 'Small'
  }
];

export const store_industry = [
  {
    value: 'Jewellery',
    label: 'Jewellery'
  },
  {
    value: 'Fashion and Fashion Accessories',
    label: 'Fashion and Fashion Accessories'
  },
  {
    value: 'Ayurvedic Products',
    label: 'Ayurvedic Products'
  },
  {
    value: 'Consumer Electronics and Appliances',
    label: 'Consumer Electronics and Appliances'
  },
  {
    value: 'Cosmetics and Personal Care',
    label: 'Cosmetics and Personal Care'
  },
  {
    value: 'Food and Beverages',
    label: 'Food and Beverages'
  },
  {
    value: 'Furniture, Home Decor and Kitchen Products',
    label: 'Furniture, Home Decor and Kitchen Products'
  },
  {
    value: 'Personalized Gifts',
    label: 'Personalized Gifts'
  },
  {
    value: 'Electrical Appliances',
    label: 'Electrical Appliances'
  },
  {
    value: 'Health and Nutrition products',
    label: 'Health and Nutrition products'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const store_platform = [
  {
    value: 'shopify',
    label: 'Shopify'
  },
  {
    value: 'shoptimize',
    label: 'Shoptimize'
  },
  {
    value: 'magento_custom',
    label: 'Magento Custom'
  },
  {
    value: 'woocommerce',
    label: 'Woo-commerce'
  }
];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

export const initialDiligenceObject = {
  store_code: '',
  store_name: '',
  store_url: '',
  store_admin_url: '',
  store_industry: '',
  store_primary_currency: '',
  size: 'large',
  valid_order_status:
    'approved, cancelled, completed, delivered, failed, new-order-created, on-hold, pending, processing, refunded, shipped, dispatch',
  user_name: '',
  user_email: '',
  platform: 'shopify',
  category_page: '',
  product_page: '',
  currency_symbol: '',
  is_store_verified: false,
  is_store_code_generated: false
};
