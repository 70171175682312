import { all, put, call, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';

import actions from './actions';
import authActions from 'redux/User/actions';
import Api from './api';

const aarya = new Api('polaris/ask-aarya', 50000);

function* handleError(error) {
  if (error.status === 401) {
    yield put({
      type: authActions.LOGOUT
    });
    message.info('You have been logged out.');
  }
  if (error.response) {
    if (error.response.data && error.response.data.detail) {
      message.error(error.response.data.detail);
    } else {
      message.error('Something went wrong. Please try again after some time.');
    }
  }
}

export function* submitQuestion(action) {
  var { question } = action.payload;
  yield put({
    type: actions.TOGGLE_LOADING
  });
  try {
    yield call(aarya.askAarya, question);
    yield put({
      type: actions.GET_QUESTIONS
    });
    yield put({
      type: actions.TOGGLE_LOADING
    });
    yield message.success('Aarya is getting geared up to help with your analysis. You will hear back from her soon.');
  } catch (error) {
    yield call(handleError, error);
    yield put({
      type: actions.TOGGLE_LOADING
    });
  }
}

export function* getQuestions() {
  try {
    var { data } = yield call(aarya.getQuestions);
    yield put({
      type: actions.SET_STATE,
      payload: { questions: data }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getAnswers() {
  try {
    var { data } = yield call(aarya.getAnswers);
    yield put({
      type: actions.SET_STATE,
      payload: { answers: data }
    });
  } catch (error) {
    yield call(handleError, error);
  }
}

export function* getReport(action) {
  var { answerId, questionId } = action.payload;
  try {
    yield call(aarya.getReport, answerId, questionId);
  } catch (error) {
    yield call(handleError, error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SUBMIT_QUESTION, submitQuestion),
    takeEvery(actions.GET_QUESTIONS, getQuestions),
    takeEvery(actions.GET_ANSWERS, getAnswers),
    takeEvery(actions.GET_REPORT, getReport)
  ]);
}
