export const focusedInsightsDashboardConstants = {
  GET_FOCUSED_INSIGHTS_GRID_REQUEST: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_GRID_REQUEST',
  GET_FOCUSED_INSIGHTS_GRID_SUCCESS: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_GRID_SUCCESS',
  GET_FOCUSED_INSIGHTS_GRID_FAILED: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_GRID_FAILED',

  GET_FOCUSED_INSIGHTS_TREE_REQUEST: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_TREE_REQUEST',
  GET_FOCUSED_INSIGHTS_TREE_SUCCESS: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_TREE_SUCCESS',
  GET_FOCUSED_INSIGHTS_TREE_FAILED: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_TREE_FAILED',

  GET_FOCUSED_INSIGHTS_NOTIFICATION_REQUEST: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_NOTIFICATION_REQUEST',
  GET_FOCUSED_INSIGHTS_NOTIFICATION_SUCCESS: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_NOTIFICATION_SUCCESS',
  GET_FOCUSED_INSIGHTS_NOTIFICATION_FAILED: 'SPOTLIGHT/GET_FOCUSED_INSIGHTS_NOTIFICATION_FAILED',
  GET_QUERY_DATA_REQUEST: 'SPOTLIGHT/GET_QUERY_DATA_REQUEST',
  GET_QUERY_DATA_SUCCESS: 'SPOTLIGHT/GET_QUERY_DATA_SUCCESS',
  GET_QUERY_DATA_ERROR: 'SPOTLIGHT/GET_QUERY_DATA_ERROR',

  CLEAR_FOCUSED_INSIGHTS_DATA: 'SPOTLIGHT/CLEAR_FOCUSED_INSIGHTS_DATA'
};
