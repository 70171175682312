import actions from './actions';

var initialState = {
  authorized: false,
  authToken: null,
  userData: null,
  loading: false,
  organisations: null,
  loginError: null,
  forgotPasswordRequested: false,
  forgotPasswordSuccess: false,
  forgotPasswordLoading: false,
  forgotPasswordError: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA:
    case actions.LOGOUT:
      return { ...state, ...action.payload };
    case actions.TOGGLE_LOADING:
      return { ...state, loading: !state.loading };

    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordRequested: true,
        forgotPasswordSuccess: false
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordRequested: true,
        forgotPasswordSuccess: true
      };
    case actions.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordRequested: true,
        forgotPasswordSuccess: false,
        forgotPasswordError: action.payload.errorMessage
      };
    default:
      return state;
  }
}
