import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select, Alert } from 'antd';
import { isEmpty as _isEmpty, map as _map, omitBy as _omitBy, trim as _trim } from 'lodash';
import { store_platform, formItemLayout } from './utilities/initialStates';
const { Option } = Select;

const GenerateStoreCode = props => {
  const { customerData, setCustomerData, reCaptchaRef } = props;
  const { getFieldDecorator } = props.form;

  const [validating, setValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const onChangePlatform = platform => {
    reCaptchaRef.current.reset();
    let platformCustomObject = {
      ...customerData,
      platform,
      store_url: '',
      store_admin_url: '',
      store_code: '',
      category_page: '',
      product_page: '',
      is_store_code_generated: false,
      is_store_verified: false,
    };

    if(['shoptimize','magento_custom'].includes(platform)){
      platformCustomObject = {...platformCustomObject, web_hosting_platform: platform || ''}
    } else{
      delete platformCustomObject['web_hosting_platform']
    }

    setCustomerData(platformCustomObject);
  };

  //----- Function to generate store code
  const onGenerateStoreCode = e => {
    e.preventDefault();

    props.form.validateFields(async (err, values) => {
      if (!err) {
        setValidating(true);
        setErrorMessage('');

        let updatedValues = { ...values };

        Object.keys(updatedValues).forEach(key => {
          updatedValues[key] = _trim(updatedValues[key]);
        });

        let storeData = { ...customerData };
        const queryObj = _omitBy(updatedValues, _isEmpty);
        console.log(JSON.stringify(queryObj), typeof queryObj);

        try {
          const apiUrl = `${process.env.REACT_APP_API_HOST}/audit/generate-store-code/`;
          const apiRequest = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: process.env.REACT_APP_DUE_DELEGENCE_TOKEN
            },
            body: JSON.stringify(queryObj)
          });
          const apiResponse = await apiRequest.json();

          if (apiResponse?.status === 'SUCCESS') {
            storeData = {
              ...storeData,
              ...updatedValues,
              store_code: apiResponse?.store_code,
              category_page: updatedValues?.store_url,
              product_page: updatedValues?.store_url,
              is_store_code_generated: true,
              ...(customerData?.platform === 'shopify'
                ? { shop_name: updatedValues?.store_admin_url.split('.')[0] || apiResponse?.store_code }
                : { shop_name: apiResponse?.store_code })
            };
          } else {
            storeData = {
              ...storeData,
              store_code: '',
              shop_name: '',
              is_store_code_generated: false,
              is_store_verified: false
            };
            setErrorMessage(apiResponse?.message || 'Something is went wrong');
          }
        } catch (err) {
          storeData = {
            ...storeData,
            store_code: '',
            shop_name: '',
            is_store_code_generated: false,
            is_store_verified: false
          };
          setErrorMessage('Invalid URL');
        }
        setValidating(false);
        setCustomerData(storeData);
      }
    });
  };

  return (
    <>
      <Form {...formItemLayout} className='dc_form' onSubmit={onGenerateStoreCode}>
        <Row gutter={24}>
          <Col md={6}>
            <Form.Item label='Store platform'>
              {getFieldDecorator('platform', {
                initialValue: customerData?.platform,
                rules: [
                  {
                    required: true,
                    message: 'Please select store platform!'
                  }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={onChangePlatform}>
                  {!_isEmpty(store_platform) &&
                    _map(store_platform, platform => (
                      <Option value={platform.value} key={platform.value}>
                        {platform.label}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={9}>
            <Form.Item
              label={`${
                customerData?.platform === 'magento_custom' ? 'Magento Custom' : customerData?.platform
              } store URL`}
            >
              {getFieldDecorator('store_url', {
                initialValue: customerData?.store_url,
                rules: [
                  {
                    message: `Please enter valid ${customerData?.platform} store URL`,
                    validator: (_, value) => {
                      const regExForUrl = value.match(
                        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                      );

                      if (regExForUrl !== null) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Invalid URL');
                      }
                    }
                  }
                ]
              })(<Input />)}
              <span className='c-heading color-light sm mt-1 mb-0'>
                <b>{customerData?.platform === 'magento_custom' ? 'Magento Custom' : customerData?.platform}</b> product
                website URL
              </span>
            </Form.Item>
          </Col>

          {customerData?.platform === 'shopify' && (
            <Col md={9}>
              <Form.Item label={`${customerData?.platform || 'Store'} admin panel URL`}>
                {getFieldDecorator('store_admin_url', {
                  initialValue: customerData?.store_admin_url,
                  rules: [
                    {
                      message: `Please enter valid ${customerData?.platform} admin panel URL`,
                      validator: (_, value) => {
                        const regExForUrl = value.match(
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
                        );
                        const regExSpecialChar = value.match(/[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/);
                        if (new RegExp('myshopify').test(value) && regExForUrl === null && regExSpecialChar === null) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject('Invalid URL');
                        }
                      }
                    }
                  ]
                })(<Input />)}
                <span className='c-heading color-light sm mt-1 mb-0'>
                  If Shopify admin URL is <b>https://mystore.myshopify.com/</b>, please remove
                  <b> https://</b> from URL
                </span>
              </Form.Item>
            </Col>
          )}
          <Col md={6}>
            <Button htmlType='submit' loading={validating} className='mt-4'>
              Validate Store
            </Button>
          </Col>
          <Col md={16}>
            {!_isEmpty(errorMessage) && (
              <div className='mb-4'>
                <Alert message={errorMessage} type='error' showIcon />
              </div>
            )}
            {customerData?.is_store_code_generated && (
              <div className='mb-4'>
                <Alert message='Store verified successfully' type='success' showIcon />
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Form.create()(GenerateStoreCode);
