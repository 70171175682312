import Api from '../api';
import { FORGOT_PASSWORD_URL, LOGIN_URL } from './api-urls';

export default class Auth extends Api {
  checkLogin() {
    return this._isTokenAlive();
  }

  login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.instance.post(LOGIN_URL, { email, password });
        var userData = this.getUserData(data);
        this._afterlogin(data.access, data.refresh, userData);
        resolve(userData);
      } catch (error) {
        reject(error);
      }
    });
  };

  logout = async () => {
    await this.instance.post('/logout/', { refresh_token: this._getRefreshToken() });
    this._clearAuthCookies();
    window.location.reload();
  };

  forgotPassword = email => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.instance.get(FORGOT_PASSWORD_URL, {
          params: {
            email
          }
        });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  switchOrg = async orgId => {
    try {
      var { data } = await this.instance.post(`/switch/`, { organisation_id: orgId });
      var userData = this.getUserData(data);
      this._afterlogin(data.access, data.refresh, userData);
      return userData;
    } catch (error) {
      console.log(error);
    }
  };

  getOrgs = () => {
    return new Promise(async (resolve, reject) => {
      try {
        var { data } = await this.get(`/list/`);
        var orgs = this.formatOrgList(data);
        resolve(orgs);
      } catch (error) {
        reject(error);
      }
    });
  };

  getUserData(response) {
    var loginPayload = JSON.parse(atob(response.access.split('.')[1]));
    return {
      username: loginPayload?.username,
      email: loginPayload?.email,
      organisationId: loginPayload?.organisation_id,
      organisationSet: loginPayload?.organisation_set,
      userId: loginPayload?.user_id,
      organisationUserId: loginPayload?.organisation_user_id,
      mgValidOrderStatus: loginPayload?.mg_valid_order_status,
      isShoptimizeUser: loginPayload?.is_shoptimize_user,
      sourceName: loginPayload?.shf_valid_source_channels,
      isMarketplaceStore: loginPayload?.is_marketplace_store || false
    };
  }

  formatOrgList(orgs) {
    return orgs?.map(org => ({
      id: org.organisation_set.id,
      name: org.organisation_set.name,
      store_code: org.organisation_set.store_code,
      store_url: org.organisation_set.store_url,
      organisation_url_suffix: org.organisation_set.organisation_url_suffix,
      industry: org.organisation_set.industry,
      show_business_overview: org.organisation_set.show_business_overview
    }));
  }

  _afterlogin(accessToken, refreshToken, user) {
    this._setAccessToken(accessToken);
    this._setRefreshToken(refreshToken);
    this._setUser(user);
  }

  _afterlogout() {
    this._clearAuthCookies();
    window.location.reload();
  }
}
