(function initTracker() {
  if (process.env.REACT_APP_TRACKER_KEY) {
    window.ShoptimizeTrackerServices.shopti_tracker_init(process.env.REACT_APP_TRACKER_KEY);
  }
})();

var { shoptimize_track } = window.ShoptimizeTrackerServices;

export var actions = {
  // USER EVENTS
  login: 'Login',
  logout: 'Logout',
  loginPageVisit: 'loginPageVisit',
  orgChange: 'OrgChange',
  // DASHBOARD EVENTS
  mainDashboardVisit: 'BusinessOverviewDashboardVisit',
  competitorDashboardVisit: 'CompetitorDashboardVisit',
  dashboardView: 'DashboardView',
  addDashboardClick: 'AddDashboardClick',
  dashboardTemplateSelect: 'DashboardTemplateSelect',
  dashboardAdd: 'DashboardAdd',
  dashboardDateModify: 'DashboardDateModify',
  dashboardCompareModify: 'DashboardCompareModify',
  dashboardEdit: 'DashboardEdit',
  dashboardPublish: 'DashboardPublish',
  dashboardEditCancel: 'DashboardEditCancel',
  dashboardRemove: 'DashboardRemove',
  // WIDGET EVENTS
  widgetAdd: 'WidgetAdd',
  widgetRemove: 'WidgetRemove',
  widgetFullScreen: 'WidgetFullScreen',
  widgetTimeGranularityModify: 'WidgetTimeGranularityModify',
  widgetAddClick: 'WidgetAddClick',
  widgetInfoClick: 'WidgetInfoClick',
  widgetResize: 'WidgetResize',
  // INTEGRATION EVENTS\
  integrationAdd: 'IntegrationAdd',
  integrationRemove: 'IntegrationRemove',
  integrationMetricsView: 'IntegrationMetricsView',
  integrationFormOpen: 'IntegrationFormOpen',
  integrationFormSubmit: 'IntegrationFormSubmit',
  addIntegrationClick: 'AddIntegrationClick',
  // DETAILBAR TOGGLE
  sidebarToggle: 'SidebarToggle',

  // DEEPLINK VERIFICATION
  deeplinkTokenGenerate: 'deeplinkTokenGenerate',
  deeplinkTokenAccess: 'deeplinkTokenAccess',
  deeplinkTokenVerified: 'deeplinkTokenVerified',

  insightPageVisit: 'insightPageVisit',
  insightDetailsPageVisit: 'insightDetailsPageVisit',
  recommendationPageVisit: 'recommendationPageVisit',
  recommendationDetailsPageVisit: 'recommendationDetailsPageVisit',

  insightNavigatorOptionSelect: 'insightNavigatorOptionSelect',
  insightNavigatorClose: 'insightNavigatorClose',

  // FOCUS INSIGHTS
  focusInsightPageVisit: 'focusInsightPageVisit',
  focusInsightCount: 'focusInsightCount',
  focusInsightClick: 'focusInsightClick',

  // ATTRIBUTION
  attributionPageVisit: 'attributionPageVisit'
};

export var track = (action, data) => {
  if (process.env.REACT_APP_TRACKER_KEY) {
    if (process.env.NODE_ENV === 'production') {
      shoptimize_track(action, data);
    } else {
      console.log({ action, data });
    }
  }
};
