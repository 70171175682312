import React from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from 'components/layout/SideBar';
import DetailBarExplore from 'components/layout/DetailBarExplore';

const { Sider, Content } = Layout;

@withRouter
@connect(({ SideBar, Dashboard, Integrations, User }) => ({
  collapsed: SideBar.detailBar.collapsed,
  mode: Dashboard.mode,
  loading: Dashboard.loading || Integrations.loading || User.loading
}))
class Component extends React.PureComponent {
  render() {
    const { children, loading } = this.props;

    return (
      <div className='c-pageContainer'>
        <Sider className='sidebar__container' width={100}>
          <Sidebar />
        </Sider>
        {/* <div className='edit__mode__background'></div> */}
        <Layout className='layout__main__container'>
          <div>
            <Content className='content__container'>
              <DetailBarExplore />
              <div className='component__content__wrapper component__container'>
                <div className='component__content__container'>
                  <div className='component__content'>{children}</div>
                </div>
              </div>
            </Content>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Component;
