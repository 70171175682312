import actions from './actions';

const initialState = {
  menuData: [],
  detailBar: {
    title: '',
    id: '',
    items: [],
    selectedDetailKeys: [],
    collapsed: false,
    code: ''
  },
  dashboardData: [],
  selectedMenuKeys: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.SET_DETAIL:
      var newDetail = Object.assign({}, state.detailBar, { ...action.payload.detailBar });
      return { ...state, detailBar: newDetail };
    case actions.TOGGLE_DETAILBAR:
      return { ...state, detailBar: { ...state.detailBar, collapsed: !state.detailBar.collapsed } };
    default:
      return state;
  }
}
