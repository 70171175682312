import React from 'react';
import { Form, Input, Button, Select, Row, Col, Divider } from 'antd';
import { size, store_industry, formItemLayout } from './utilities/initialStates';
import { isEmpty as _isEmpty, map as _map } from 'lodash';
import { currency_list } from './utilities/initialStates';

const { Option } = Select;
const StoreInformation = props => {
  const { getFieldDecorator } = props.form;
  const { currentStep, setCurrentStep, customerData, setCustomerData } = props;

  // ----- Submit Form Data
  const onSubmitFormData = e => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setCustomerData({ ...customerData, ...values });
        setCurrentStep(currentStep + 1);
      }
    });
  };

  const onSelectCurrency = async value => {
    if (!_isEmpty(value)) {
      const country = (await currency_list.find(country => country.code === value)) || [];
      const countrySymbol = (await country?.symbol) || '';
      setCustomerData({ ...customerData, currency_symbol: countrySymbol });
    }
  };

  return (
    <>
      <Form {...formItemLayout} className='dc_form'>
        <Row gutter={24}>
          <Col md={24}>
            <span className='c-heading bold sm uppercase'>User Information</span>
            <Divider dashed />
          </Col>
          <Col md={12}>
            <Form.Item label='Name'>
              {getFieldDecorator('user_name', {
                initialValue: customerData?.user_name,
                rules: [
                  {
                    required: true,
                    message: 'Please input your name!'
                  }
                ]
              })(<Input />)}
              <span className='c-heading color-light sm mt-1 mb-0'>
                <b>Name</b> - Name of user
              </span>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='User Email ID'>
              {getFieldDecorator('user_email', {
                initialValue: customerData?.user_email,
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your email!'
                  }
                ]
              })(<Input />)}
              <span className='c-heading color-light sm mt-1 mb-0'>
                <b>Valid Email ID</b> - where you will receive further steps
              </span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={24}>
            <span className='c-heading bold sm uppercase'>Store Information</span>
            <Divider dashed />
          </Col>
          <Col md={12}>
            <Form.Item label='Store Name'>
              {getFieldDecorator('store_name', {
                initialValue: customerData?.store_name,
                rules: [
                  {
                    required: true,
                    message: 'Please input store name!'
                  }
                ]
              })(<Input />)}
              <span className='c-heading color-light sm mt-1 mb-0'>
                <b>Store Name</b> - Which will reflect on dashboard
              </span>
            </Form.Item>
          </Col>

          <Col md={12}>
            <Form.Item label='Store Website URL'>
              {getFieldDecorator('store_url', {
                initialValue: customerData?.store_url,
                rules: [
                  {
                    required: true,
                    message: 'Please enter valid website URL!',
                    validator: (_, value) => {
                      const regExForUrl = value.match(
                        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                      );

                      if (regExForUrl !== null && value.slice(-1) !== '/') {
                        return Promise.resolve();
                      } else {
                        return Promise.reject('Invalid URL');
                      }
                    }
                  }
                ]
              })(<Input />)}
              <span className='c-heading color-light sm mt-1 mb-0'>
                <b>Store Website URL</b> - D2C Store website URL. please remove
                  <b> /</b> from end of URL
              </span>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='Store Size'>
              {getFieldDecorator('size', {
                initialValue: customerData?.size,
                rules: [
                  {
                    required: true,
                    message: 'Please select store size!'
                  }
                ]
              })(
                <Select style={{ width: '100%' }}>
                  {!_isEmpty(size) &&
                    _map(size, store => (
                      <Option value={store.value} key={store.value}>
                        {store.label}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='Store Industry'>
              {getFieldDecorator('store_industry', {
                initialValue: customerData?.store_industry,
                rules: [
                  {
                    required: true,
                    message: 'Please select store industry!'
                  }
                ]
              })(
                <Select style={{ width: '100%' }}>
                  {!_isEmpty(store_industry) &&
                    _map(store_industry, store => (
                      <Option value={store.value} key={store.value}>
                        {store.label}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label='Store Primary Currency'>
              {getFieldDecorator('store_primary_currency', {
                initialValue: customerData?.store_primary_currency,
                rules: [
                  {
                    required: true,
                    message: 'Please select primary currency!'
                  }
                ]
              })(
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={onSelectCurrency}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                  }
                >
                  {!_isEmpty(currency_list) &&
                    _map(currency_list, currency => (
                      <Option value={currency.code} key={currency.code}>
                        {currency?.name} -
                        <b>
                          {currency.code} ({currency.symbol})
                        </b>
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col md={24} className='text-right'>
            <Button type='primary' onClick={onSubmitFormData}>
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Form.create()(StoreInformation);
