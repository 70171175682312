import React from 'react';
import { Menu, Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import dashboard from 'assets/images/nav-icons/dashboard.svg';
import data_sources from 'assets/images/nav-icons/data_sources.svg';
import segments from 'assets/images/nav-icons/segments.svg';
import explore from 'assets/images/nav-icons/explore.svg';
import User_analysis from 'assets/icons/user-analysis.png';

import actions from 'redux/SideBar/actions';
import dashboardActions from 'redux/Dashboard/actions';
import { SIDEBAR_KEYS, routesMapping } from '../../../utilities/utilities_exports';
import graasLogo from 'assets/images/graas_logo_small.svg';
import AdvisoryImage from '../../../assets/icons/SVG/ga_link.svg';
import ReportIcon from '../../../assets/images/report/reportIcon.svg';
import RecommendationIcon from '../../../assets/images/nav-icons/recommendation.svg';
import AttributionIcon from '../../../assets/images/nav-icons/user.png';
import jwt_decode from 'jwt-decode';

var menuItemIconMap = {
  dashboards: dashboard,
  integrations: data_sources,
  user_analysis: User_analysis,
  'exploratory-analysis': explore,
  segments: segments
};

const mapStateToProps = ({ SideBar, TopBar, User }) => ({
  menu: SideBar.menuData,
  selectedMenuKeys: SideBar.selectedMenuKeys,
  detailBarCollapsed: SideBar.detailBar.collapsed,
  menuDataDetails: SideBar.menuDataDetails,
  user: User.userData
});

const mapDispatchToProps = dispatch => ({
  setList: detailBar =>
    dispatch({
      type: actions.SET_DETAILBAR_DATA,
      payload: {
        detailBar
      }
    }),
  setSelectedMenuItem: key =>
    dispatch({
      type: actions.SET_SELECTED_MENU,
      payload: {
        selectedMenuKeys: [key]
      }
    }),
  toggleDetailbar: () =>
    dispatch({
      type: actions.TOGGLE_DETAILBAR
    }),
  setDashModeToView: () =>
    dispatch({
      type: dashboardActions.SET_MODE,
      payload: {
        mode: 'view'
      }
    })
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.accessToken = localStorage.getItem('accessToken');
    this.decodedToken = this.accessToken ? jwt_decode(this.accessToken) : {};
    this.userId = this.decodedToken.user_id;
    this.userName = this.decodedToken.username;
    this.userEmail = this.decodedToken.email;
    this.signedUpAt = this.decodedToken.created_at;
    this.state = { visible: false };
  }

  onMenuItemClick(key) {
    if (!this.props.selectedMenuKeys.includes(key) && this.props.detailBarCollapsed) {
    } else {
      this.props.toggleDetailbar();
    }
    this.props.setDashModeToView();
    // if (this.props.detailBarCollapsed) {
    //  this.props.toggleDetailbar();
    // }
    this.props.setSelectedMenuItem(key);
    if (SIDEBAR_KEYS.includes(key)) {
      this.props.setList({
        items: [],
        title: this.getTitle(key),
        id: key,
        selectedDetailKeys: [],
        code: key.code
      });
    } else {
      this.props.setList({ ...this.populateList(key) });
    }

    // this.props.history.push(this.getMenuItemUrl(key));
    // }
  }

  getTitle = key => {
    const titles = {
      notification: 'NOTIFICATION',
      solutions: 'SOLUTIONS',
      userMenu: 'ACCOUNTS'
    };
    return titles[key];
  };

  populateList = key => {
    var items = [],
      title = '',
      id = null,
      code = '',
      [current] = this.props.menuDataDetails.filter(menu => menu.id === key);
    if (current) {
      if (current.children) {
        items = current.children;
        title = current.name;
        id = current.id;
        code = current.code;
      } else {
        title = current.name;
        id = current.id;
        code = current.code;
      }
    }
    return { items, title, id, selectedDetailKeys: [], code };
  };

  getMenuItemUrl = id => {
    var url = '';
    this.props.menu.forEach(item => {
      if (item.id === id) {
        url = '/' + item.id.toLowerCase();
      }
    });
    return url;
  };

  render() {
    let menu = this.props.menu;
    // -- close sidebar if open while click on GA menu
    const closeMenuBar = () => {
      if (this.props.detailBarCollapsed) {
        this.props.toggleDetailbar();
      }
    };

    if (menu.length) {
      const dash_idx = menu.findIndex(item => item.id === 'data_sources');
      menu = [...menu.slice(0, dash_idx), ...menu.slice(dash_idx + 1), menu[dash_idx]];
    }

    var mainActiveClass;
    if (
      this.props.location.pathname.includes('dashboard') ||
      this.props.location.pathname.includes('business-overview')
    ) {
      mainActiveClass = 'dashboard_active';
    } else if (this.props.location.pathname.includes('explore')) {
      mainActiveClass = 'exploration_active';
    } else if (this.props.location.pathname.includes('segments')) {
      mainActiveClass = 'segmentation_active';
    } else if (this.props.location.pathname.includes('data_sources')) {
      mainActiveClass = 'data_sources_active';
    }

    if (menu) {
      return (
        <>
          <Helmet>
            <script type='text/javascript'>
              {`(function (c, l, a, r, i, t, y) {
                c[a] =
                  c[a] ||
                  function () {
                    (c[a].q = c[a].q || []).push(arguments);
                  };
                t = l.createElement(r);
                t.async = 1;
                t.src = "https://www.clarity.ms/tag/" + i;
                y = l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t, y);
                console.log('Clarity Pixel fired 🔥');
              })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PIXEL}");`}
            </script>
            <script type='text/javascript'>
              {`
              window._mfq = window._mfq || [];
              (function(i) {
                var mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/"+i+".js";
                document.getElementsByTagName("head")[0].appendChild(mf);
                console.log('Mouseflow Pixel fired with Graas dashboard 🔥');
              })("${process.env.REACT_APP_MOUSEFLOW_KEY}");
            `}
            </script>
          </Helmet>
          <Row className='sidebar__wrapper'>
            <div className='sidebar__menu'>
              <div className='sidebar__top'>
                <div className='sidebar__logo'>
                  <Link to={routesMapping?.dashboard} className='sidebar_link'>
                    <img className='logo' src={graasLogo} alt='graas' />
                  </Link>
                </div>

                <div className='sidebar__mid noBorder'>
                  <Tooltip placement='right' title='Insights'>
                    <NavLink
                      activeclassname='active'
                      to={routesMapping?.focusedInsights}
                      className='linkNav is-pulsing'
                      onClick={() => closeMenuBar()}
                    >
                      <div className='hoverContainer custome-icon'>
                        <img src={AdvisoryImage} alt='Insights' />
                      </div>
                      <div className='linkTitle'>Insights</div>
                    </NavLink>
                  </Tooltip>
                  <Tooltip placement='right' title='Recommendations'>
                    <NavLink
                      activeclassname='active'
                      to={routesMapping?.recommendation}
                      className='linkNav'
                      onClick={() => closeMenuBar()}
                    >
                      <div className='hoverContainer'>
                        <img src={RecommendationIcon} alt='Recommendations' />
                      </div>
                      <div className='linkTitle'>Recos</div>
                    </NavLink>
                  </Tooltip>
                </div>

                <Menu
                  mode='inline'
                  selectedKeys={this.props.selectedMenuKeys}
                  onClick={({ key }) => {
                    this.onMenuItemClick(key);
                  }}
                  className={`sidebar__mid noBorder custom_menus ${mainActiveClass}`}
                >
                  {menu.map(MenuItem => {
                    return (
                      <Menu.Item title={MenuItem.name} key={MenuItem.id} id={MenuItem.id} className='linkNav'>
                        <div className='hoverContainer'>
                          <img src={menuItemIconMap[MenuItem.code]} alt='' />
                        </div>
                        <div className='linkTitle'>{MenuItem.name}</div>
                      </Menu.Item>
                    );
                  })}
                </Menu>

                <div className='sidebar__mid noBorder'>
                  <Tooltip placement='right' title='Report'>
                    <NavLink
                      activeclassname='active'
                      to={routesMapping?.reports}
                      className='linkNav'
                      onClick={() => closeMenuBar()}
                    >
                      <div className='hoverContainer'>
                        <img src={ReportIcon} alt='report page' />
                      </div>
                      <div className='linkTitle'>Report</div>
                    </NavLink>
                  </Tooltip>
                  <Tooltip placement='right' title='Attribution'>
                    <NavLink
                      activeclassname='active'
                      to={routesMapping?.attribution}
                      className='linkNav'
                      onClick={() => closeMenuBar()}
                    >
                      <div className='hoverContainer'>
                        <img src={AttributionIcon} alt='attribution page' />
                      </div>
                      <div className='linkTitle'>Attribution</div>
                    </NavLink>
                  </Tooltip>
                </div>
              </div>

              <div className='sidebar__menu__bottom'>
                <div className='sidebar__mid noBorder'>
                  <Tooltip placement='right' title='Help Section'>
                    <NavLink
                      activeclassname='active'
                      to={routesMapping?.help}
                      className='linkNav'
                      onClick={() => closeMenuBar()}
                    >
                      <div className='hoverContainer'>
                        <QuestionCircleOutlined />
                      </div>
                      <div className='linkTitle'>Help</div>
                    </NavLink>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Row>
        </>
      );
    }
    return null;
  }
}

export default SideBar;
